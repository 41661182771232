import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";
import locale from "../Date";
import {NotificationManager} from "react-notifications";
import Dropzone from 'react-dropzone';
import forensicLib from "../assets/js/forensicLib";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Gesture from "@material-ui/icons/Gesture";
import TextField from "@material-ui/core/TextField";
import Compare from "@material-ui/icons/Compare";
import {Alert} from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

let _this;

export class Upload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            type: 'turkcell',
            sampleSigns: [
                {groupName: '', groupDate: '', description: '', type: 'sample', id: null},
            ],
            referenceSign: [
                {groupName: '', groupDate: '', description: '', type: 'reference', id: null}
            ],
            hideButton: false,
            sampleType: [],
            canContinueToDashboard: false,
            showLastUploaded: false,
            showLastUploadedList: [],
            fileNames : []
        };
        this.caseUUID = sessionStorage.getItem('responseCaseId');
        _this = this;
        this.fileData = [];
        this.fileData['groups'] = [];
        this.fileData['reference'] = [];
        this.refUploded = false;
        this.sampleUploaded = false;
        this.createSampleGroup = this.createSampleGroup.bind(this);
        this.handleCloseHashList = this.handleCloseHashList.bind(this);

    }

    componentDidMount() {
        this.setState({sampleType: []});
    }

    handleCloseHashList() {
        this.setState({showLastUploaded: false})
    }

    addSignatureToGroup = async (files, caseId, groupId, type = 'groups', signatureType = 'techsign') => {

        this.setState({loading: true});
        const data = new FormData();
        data.append('signature', files.file);


        return await fetch(customConstants.apiUrl + "/cases/" + caseId + "/group/" + groupId + "/signature/upload/" + signatureType, {
            method: 'POST',
            headers: {
                'Authorization': forensicLib.getAuthHeader(),
                'Accept': 'application/json'
            },
            body: data
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                return result;
            }).catch(err => {
                this.setState({loading: false, hideButton: false});
                // this.props.history.push('/dashboard');
            });

    }

    createGroup = async (item, type) => {

        if (typeof item !== 'undefined') {

            try {


                let data = {
                    "groupName": item.groupName,
                    "description": item.description,
                }

                if (type === 'reference') {
                    data.groupName = 'Reference';
                    data.description = 'Reference Sign';
                    data.groupDate = moment(new Date()).toISOString().slice(0, -5) + "Z";
                } else {
                    data.groupDate = moment(item.groupDate).toISOString().slice(0, -5) + "Z";
                }


                if (type === 'groups') {
                    data.status = "sample";
                } else {
                    data.status = "reference";
                }


                //  data.groupDate = "2019-07-03T21:00:11Z";

                return await fetch(customConstants.apiUrl + "/cases/" + _this.caseUUID + "/group", {
                    method: 'POST',
                    headers: {
                        Authorization: forensicLib.getAuthHeader(),
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(result => {
                        return result;
                    })
                    .catch(err => {
                        //  return NotificationManager.error(err);
                    });

            } catch (e) {
                // console.log(e);
            }

        }


    }

    handleChangeType = (event) => {
        this.setState({type: event.target.value});
    }

    handleChangeTypeSample = (event, i) => {
        let curentTypes = this.state.sampleType;
        curentTypes[i] = event.target.value;

        this.setState({sampleType: curentTypes}, () => {
            console.log(_this.state.sampleType);
        });
    }

    createSampleGroup = async (type = 'groups') => {


        if (this.sampleUploaded === true) {

            let lastItem;
            if (type === 'groups') {
                lastItem = this.state.sampleSigns;
            } else {
                lastItem = this.state.referenceSign;
            }

            try {
                if (typeof lastItem !== 'undefined' && lastItem.length > 0) {
                    await Promise.all(lastItem.map(async function (inner, k) {

                        const groupId = await _this.createGroup(inner, type);

                        if (typeof groupId !== 'undefined') {
                            lastItem[k].id = groupId;
                            return await Promise.all(_this.fileData[type][k].map(async function (innersign, j) {
                                const incomingId = await _this.addSignatureToGroup(innersign, _this.caseUUID, lastItem[k].id, type, (type !== 'groups') ? _this.state.type : (typeof _this.state.sampleType[k] !== 'undefined') ? _this.state.sampleType[k] : 'turkcell');

                                if (typeof lastItem[k].signatures === 'undefined') {
                                    lastItem[k].signatures = [];
                                    lastItem[k].names = [];
                                }

                                if (typeof incomingId !== 'undefined' && typeof incomingId.id !== 'undefined' && incomingId.id !== null) {

                                    lastItem[k].signatures.push(incomingId);
                                    lastItem[k].names.push(innersign);

                                    console.log(lastItem);


                                    if (type !== 'groups') {
                                        _this.setState({canContinueToDashboard: true});
                                    }

                                } else {
                                    _this.setState({hideButton: false});
                                    _this.fileData[type][k].splice(j, 1);
                                    return NotificationManager.error(global.strings.sign_could_not_read + ' ' + lastItem[k].id, '', 15000);
                                    if (type !== 'groups') {
                                        _this.setState({canContinueToDashboard: false, hideButton: false});
                                    }
                                }


                            }));


                            if (type === 'groups') {
                                this.setState({sampleSigns: lastItem});
                            } else {
                                this.setState({regerenceSign: lastItem});
                            }

                        } else {
                            _this.setState({hideButton: false});
                        }


                    }));
                }
            } catch (e) {
                _this.setState({hideButton: false});
                console.log(e);
            }

        }


    }

    continueToReport = async (anyway = false) => {

        if (anyway === true) {
            this.setState({showLastUploaded: false}, () => {
                _this.submitFiles();
            });
            return;
        }

        this.setState({hideButton: true});

        let grpupRequiredMissing = false;
        let ref = _this.state.referenceSign;
        let refSample = _this.state.sampleSigns;

        if (this.sampleUploaded === true) {
            refSample.filter(function (value) {
                if (value.groupName === '' || value.groupDate === '') {
                    grpupRequiredMissing = true;
                }
            });
        }

        if (grpupRequiredMissing === true) {

            this.setState({hideButton: false}, () => {
                return NotificationManager.warning(global.strings.missingInformation);
            });
            return;

        } else {

            let already = false;

            if (_this.fileData['reference'].length > 0) {
                await Promise.all(_this.fileData['reference'][0].map(async function (files) {
                    console.log(files);
                    const data = new FormData();
                    data.append('signature', files.file);

                    await fetch(customConstants.apiUrl + "/cases/check-signature-present/" + _this.state.type, {
                        method: 'POST',
                        headers: {
                            'Authorization': forensicLib.getAuthHeader(),
                            'Accept': 'application/json'
                        },
                        body: data
                    })
                        .then(response => response.json())
                        .then(async result => {
                            console.log(result);
                            _this.setState({loading: false, hideButton: false});
                            try {
                                if (result.numberOfCases > 0) {
                                    already = true;

                                    _this.setState({showLastUploadedList: result.cases, showLastUploaded: true})
                                } else {
                                    _this.submitFiles();
                                }
                            } catch (e) {
                                console.log(e);
                            }
                            return result;
                        }).catch(err => {
                            _this.setState({loading: false, hideButton: false});
                            // this.props.history.push('/dashboard');
                        });

                }));
            } else {
                this.setState({hideButton: false}, () => {
                    return NotificationManager.warning(global.strings.atleastOneSign);
                });
            }

        }


    }

    async submitFiles() {
        if (_this.refUploded === true) {

            await _this.createSampleGroup('reference');
            sessionStorage.setItem('reference', JSON.stringify(_this.state.referenceSign));


            if (_this.sampleUploaded === true) {
                await _this.createSampleGroup();
                sessionStorage.setItem('samples', JSON.stringify(_this.state.sampleSigns));
            }

            _this.setState({loading: false});

            if (_this.state.canContinueToDashboard === true) {
                _this.props.history.push('/dashboard');
            }

        } else {
            _this.setState({hideButton: false}, () => {
                return NotificationManager.warning(global.strings.atleastOneSign);
            });
        }
    }

    addOtherSigns() {
        let currentSigns = this.state.sampleSigns;
        currentSigns.push({groupName: '', groupDate: '', description: '', data: []});
        this.setState({sampleSigns: currentSigns});
    }

    updateGroupName(v, isReference, i) {
        if (isReference) {
            let currentName = this.state.referenceSign;
            currentName[0].groupName = v.target.value;
            this.setState({referenceSign: currentName}, () => {
                // console.log(this.state.referenceSign);
            });
        } else {
            let currentName = this.state.sampleSigns;
            currentName[i].groupName = v.target.value;
            this.setState({sampleSigns: currentName}, () => {
                // console.log(this.state.sampleSigns);
            });
        }
    }

    updateDescription(v, isReference, i) {
        if (isReference) {
            let currentName = this.state.referenceSign;
            currentName[0].description = v.target.value;
            this.setState({referenceSign: currentName}, () => {
                // console.log(this.state.referenceSign);
            });
        } else {
            let currentName = this.state.sampleSigns;
            currentName[i].description = v.target.value;
            this.setState({sampleSigns: currentName}, () => {
                // console.log(this.state.sampleSigns);
            });
        }
    }

    updateDate(v, isReference, i) {
        //  console.log(v);
        if (isReference) {
            let currentName = this.state.referenceSign;
            currentName[0].groupDate = v;
            this.setState({referenceSign: currentName}, () => {
                // console.log(this.state.referenceSign);
            });
        } else {
            let currentName = this.state.sampleSigns;
            currentName[i].groupDate = v;
            this.setState({sampleSigns: currentName}, () => {
                // console.log(this.state.sampleSigns);
            });
        }
    }

    uploadSigFile = (file, isRefernce, i) => {

        let files = Array.from(file);
        _this.setState({loading: true});


        files.map(function (f) {

            // console.log(f);

            const data = new FormData();
            const allowedExtensions = /(\.sig)$/i;

            if (_this.state.sampleSigns.length >= 10) {
                _this.setState({loading: false});
                return NotificationManager.warning(global.strings.max_two_file);
            } else if (typeof f !== 'undefined' && !allowedExtensions.exec(f.name)) {
                _this.setState({loading: false});
                return NotificationManager.error(global.strings.upload_file_type_error);
            } else if (typeof f !== 'undefined' && allowedExtensions.exec(f.name)) {

                data.append('signature', f);
                _this.setState({uploading: true});

                if (isRefernce === true) {

                    if (typeof _this.fileData['reference'] !== 'undefined' && _this.fileData['reference'].length > 5) {
                        _this.setState({loading: false});
                        return NotificationManager.warning(global.strings.only_one_reference);
                    } else {

                        if (typeof _this.fileData['reference'][0] === 'undefined') {
                            _this.fileData['reference'][0] = [];
                        }

                        _this.fileData['reference'][0].push({filename: f.name, file: f});
                        _this.sampleUploaded = true;
                        _this.setState({loading: false});
                        _this.refUploded = true;
                        return NotificationManager.success(global.strings.upload_success_reference);
                    }

                } else {

                    if (typeof _this.fileData['groups'][i] === 'undefined') {
                        _this.fileData['groups'][i] = [];
                    }

                    _this.fileData['groups'][i].push({filename: f.name, file: f});
                    _this.sampleUploaded = true;
                    _this.setState({loading: false});
                    return NotificationManager.success(global.strings.upload_success_group);
                }

            }
        })


    }

    getCase = async (id) => {

        let samples = [];
        let reference = [];

        this.setState({loading: true});
        fetch(customConstants.apiUrl + "/cases/" + id, {
            method: 'GET',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(result => {

                sessionStorage.setItem('responseCaseId', result.uuid);
                sessionStorage.setItem('caseId', result.caseId);
                sessionStorage.setItem('tckn', result.tckn);
                sessionStorage.setItem('specialityNo', result.specialityNo);
                sessionStorage.setItem('requestAuthority', result.requestAuthority);
                sessionStorage.setItem('date', result.caseDate);


                // console.log(item);

                try {
                    result.groups.map(function (item) {
                        if (item.status === 'reference') {
                            reference.push(item);
                        } else {
                            samples.push(item);
                        }
                    });
                } catch (e) {
                    console.log(e);
                }


                sessionStorage.setItem('reference', JSON.stringify(reference));
                sessionStorage.setItem('samples', JSON.stringify(samples));

                this.props.history.push('/dashboard');

            })
            .catch(err => {
                //console.log(err);
                this.setState({loading: false});
                // return NotificationManager.error(err);
            });


    }

    render() {
        return (
            <div>
                <Loading loading={this.state.loading} background="rgba(255,255,255,.7)" loaderColor="#0D386B"/>
                <div className="limiter">
                    <Dialog
                        fullWidth={'md'}
                        open={this.state.showLastUploaded}
                        onClose={this.handleCloseHashList}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{global.strings.last_hash_list}</DialogTitle>
                        <DialogContent>

                            <Alert severity={'warning'}>{global.strings.last_hash_list_desc}</Alert>
                            <Grid container spacing={3}>

                                <table style={{padding: 0, margin: 20, marginTop: 30, width: '100%'}}>
                                    <thead>
                                    <th>{global.strings.caseId}</th>
                                    <th>{global.strings.expertId}</th>
                                    <th>{global.strings.created_at}</th>
                                    </thead>
                                    {this.state.showLastUploadedList.map(function (v, k) {
                                        return <tr onClick={() => _this.getCase(v.uuid)}
                                                   style={{
                                                       listStyle: 'none',
                                                       cursor: 'pointer',
                                                       padding: '7px 3px',
                                                       backgroundColor: (k % 2 == 1) ? 'rgb(221 221 221 / 26%)' : 'rgb(221 221 221 / 16%)'
                                                   }} key={k}>
                                            <td>{v.caseId}</td>
                                            <td>{v.specialityNo}</td>
                                            <td>{moment(v.createdAt).format('DD-MM-YYYY')}</td>
                                        </tr>
                                    })}
                                </table>

                                <div onClick={() => this.continueToReport(true)} lang="en"
                                     className="login100-form-btn" style={{display: 'initial'}}>
                                    {global.strings.continue_anyway}
                                    <svg x="0px" y="0px" width="16px" height="16px" style={{marginLeft: 10}}
                                         viewBox="0 0 31.49 31.49"
                                    >
                                        <path fill="#ffffff"
                                              d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
                                    </svg>
                                </div>
                            </Grid>

                        </DialogContent>
                        <DialogActions>

                        </DialogActions>
                    </Dialog>
                    <div className="container-login100">
                        <div className="upload-container">
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <div className={'upload-inner '}>
                                        <h1 className={'upload-document'}><span className={'upload-icon'}>
                            <svg className={'uploadIcn'} version="1.1" xmlns="http://www.w3.org/2000/svg"
                                 x="0px" y="0px"
                                 width="24px" height="24px" viewBox="0 0 512 512">
<path d="M510.187,238.063L439.749,432H53.124l72.75-194.031C132.061,221.5,151.53,208,169.124,208h320
	C506.718,208,516.218,221.531,510.187,238.063z M31.03,399.813L95.936,226.75c10.813-28.938,42.281-50.75,73.188-50.75h278.875
	c0-17.688-14.313-32-32-32h-224l-32-64h-128c-17.688,0-32,14.313-32,32v256C-0.001,385.344,13.811,399.25,31.03,399.813z"/>
</svg>

                        </span>{global.strings.uploadDocument}</h1>

                                        <h3 className={'reference-sign'}>{global.strings.referenceSign}</h3>
                                        <div className={'information'}>{global.strings.toBeReference}</div>
                                        <FormControl style={{width: '100%', marginTop: 15}}>
                                            <InputLabel
                                                id="demo-customized-select-label">{global.strings.upload_type}</InputLabel>
                                            <Select

                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                value={this.state.type}
                                                defaultValue={'turkcell'}
                                                onChange={this.handleChangeType}
                                            >
                                                <MenuItem value={'turkcell'}>Turkcell</MenuItem>
                                                <MenuItem value={'techsign'}>Techsign</MenuItem>
                                                <MenuItem value={'iso11'}>iso11</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div className={'uploadContainer'}>
                                            <Dropzone accept=".sig" multiple={true}
                                                      onDropAccepted={acceptedFiles => this.uploadSigFile(acceptedFiles, true)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className={'reference-container'}>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()}  />
                                                            <p>
                                                                <svg
                                                                    width="100"
                                                                    height="60"
                                                                    viewBox="0 0 50 43">
                                                                    <path fill="#ccc" d="M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266
			C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h6v-2H5.997
			C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7
			c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03
			c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4H20v2h6c3.312,0,6-2.693,6-6C32,15.735,30.13,13.407,27.586,12.712z"/>
                                                                    <polygon fill="#ccc"
                                                                             points="16,13.5 11,19.5 14,19.5 14,27.5 18,27.5 18,19.5 21,19.5 		"/>
                                                                </svg>
                                                                <br/><br/>
                                                                {global.strings.uploadContainer}
                                                            </p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <ul className={'uploaded-files'}>
                                                {_this.fileData['reference'].length > 0 && _this.fileData['reference'][0].length > 0 && _this.fileData['reference'][0].map((item, i) => {

                                                    if (_this.fileData['reference'].length > 0) {
                                                        return <li key={'signs-' + i}>
                                                            <svg version="1.1" x="0px" y="0px" width={24} height={24}
                                                                 viewBox="0 0 1200 1200">
                                                                <path fill="#7AC043"
                                                                      d="M50,662.7l187-134.2l149.6,237.6c0,0,303.6-475.2,763.4-624.8v37.4c0,0-466.4,198-748,880L50,662.7z"/>
                                                            </svg>
                                                            {item.filename}
                                                        </li>
                                                    }

                                                })}
                                            </ul>
                                        </div>
                                        <div className={'row'}>
                                            <div className="flex-row wrap-input100 validate-input col-12"
                                                 style={{'marginTop': 10}}>
                                                <label className="lf--label" style={{'width': '6em'}}
                                                       htmlFor="groupNameRef">
                                                    {global.strings.description}
                                                </label>
                                                <textarea id="groupNameRef" className="lf--input input100 lf--textarea"
                                                          value={this.state.referenceSign[0].description}
                                                          onChange={(v) => this.updateDescription(v, true, null)}
                                                          placeholder={global.strings.description}
                                                />
                                                <span className="focus-input100"></span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className={'upload-inner'}>
                                        {this.state.sampleSigns.map((item, i) => {

                                            return <div key={i}>
                                                <h3 className={'group-sign'}
                                                    style={{'marginTop': '18px'}}>{i + 2}. {global.strings.sampleSign}</h3>
                                                <div className={'information'}>{global.strings.toBeSample}</div>
                                                <FormControl style={{width: '100%', marginTop: 15}}>
                                                    <InputLabel
                                                        id="demo-customized-select-label">{global.strings.upload_type}</InputLabel>
                                                    <Select

                                                        labelId="demo-customized-select-label"
                                                        id="demo-customized-select"
                                                        defaultValue={'turkcell'}
                                                        value={(typeof this.state.sampleType[i] !== 'undefined') ? this.state.sampleType[i] : 'turkcell'}
                                                        onChange={(event) => this.handleChangeTypeSample(event, i)}
                                                    >
                                                        <MenuItem value={'turkcell'}>Turkcell</MenuItem>
                                                        <MenuItem value={'techsign'}>Techsign</MenuItem>
                                                        <MenuItem value={'iso11'}>iso11</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div className={'uploadContainer'}>
                                                    <Dropzone accept=".sig" multiple
                                                              onDropAccepted={acceptedFiles => this.uploadSigFile(acceptedFiles, false, i)}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <p>
                                                                        <svg
                                                                            width="100"
                                                                            height="60"
                                                                            viewBox="0 0 50 43">
                                                                            <path fill="#ccc" d="M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266
			C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h6v-2H5.997
			C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7
			c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03
			c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4H20v2h6c3.312,0,6-2.693,6-6C32,15.735,30.13,13.407,27.586,12.712z"/>
                                                                            <polygon fill="#ccc"
                                                                                     points="16,13.5 11,19.5 14,19.5 14,27.5 18,27.5 18,19.5 21,19.5 		"/>
                                                                        </svg>
                                                                        <br/><br/>
                                                                        {global.strings.uploadContainer}
                                                                    </p>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                    <ul className={'uploaded-files'}>
                                                        {typeof _this.fileData['groups'][i] !== 'undefined' && _this.fileData['groups'][i].length > 0 && _this.fileData['groups'][i].map((item, i) => {
                                                            return <li key={'sample-uploaded' + i}>
                                                                <svg version="1.1" x="0px" y="0px" width={24}
                                                                     height={24}
                                                                     viewBox="0 0 1200 1200"
                                                                >
                                                                    <path fill="#7AC043"
                                                                          d="M50,662.7l187-134.2l149.6,237.6c0,0,303.6-475.2,763.4-624.8v37.4c0,0-466.4,198-748,880L50,662.7z"/>
                                                                </svg>
                                                                {item.filename}
                                                            </li>
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className={'row'} style={{'marginTop': '15px'}}>
                                                    <div className="flex-row wrap-input100 validate-input col-6">
                                                        <label className="lf--label" style={{'width': '6em'}}
                                                               htmlFor="groupName">
                                                            {global.strings.groupName} *
                                                        </label>
                                                        <input id="groupName" className="lf--input input100"
                                                               maxLength={30}
                                                               value={this.state.email}
                                                               onChange={(v) => this.updateGroupName(v, false, i)}
                                                               placeholder={global.strings.groupNamePlaceholder}
                                                               type="text"/>
                                                        <span className="focus-input100"></span>
                                                    </div>
                                                    <div className="flex-row wrap-input100 validate-input col-6">
                                                        <label className="lf--label" htmlFor="date">
                                                            {global.strings.date} *
                                                        </label>
                                                        <DatePicker
                                                            locale={localStorage.getItem('_lang') === 'tr' ?  locale : null}
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={this.state.sampleSigns[i].groupDate}
                                                            onChange={(v) => this.updateDate(v, false, i)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="flex-row wrap-input100 validate-input col-12">
                                                        <label className="lf--label" style={{'width': '6em'}}
                                                               htmlFor="groupName">
                                                            {global.strings.description}
                                                        </label>
                                                        <textarea id="groupName"
                                                                  className="lf--input input100 lf--textarea"
                                                                  value={this.state.description}
                                                                  onChange={(v) => this.updateDescription(v, false, i)}
                                                                  placeholder={global.strings.description}
                                                        />
                                                        <span className="focus-input100"></span>
                                                    </div>

                                                </div>
                                            </div>
                                        })
                                        }

                                        <div className={'row'}>
                                            <div className={'col-8'}>
                                                <h5 onClick={() => this.addOtherSigns()} className={'add-other'}><span
                                                    className={'add-icon'}>
                            <svg className={'uploadIcn'} version="1.1" xmlns="http://www.w3.org/2000/svg"
                                 x="0px" y="0px"
                                 width="16px" height="16px" viewBox="0 0 24 24">
<path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>

                        </span>{global.strings.addOtherSigns}</h5>
                                            </div>
                                            <div className={'col-4'}>
                                                {(this.state.hideButton === false) ?
                                                    <div className="container-login100-form-btn">
                                                        <div onClick={() => this.continueToReport()} lang="en"
                                                             className="login100-form-btn">
                                                            <svg x="0px" y="0px" width="25px" height="23px"
                                                                 viewBox="0 0 31.49 31.49"
                                                            >
                                                                <path fill="#ffffff"
                                                                      d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
                                                            </svg>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}

export default withRouter(Upload);



