import customConstants from "./Globals";
import forensicLib from "./assets/js/forensicLib";
import {NotificationManager} from "react-notifications";

export function setLanguage(e) {

    if (e !== undefined && e !== null && e.target !== undefined && e.target !== null) {
        localStorage.setItem('_lang',e.target.value);

        if (e.target.value === null || e.target.value === undefined) {
            global.strings.setLanguage('tr');
        } else {
            global.strings.setLanguage(e.target.value);
        }
    }

}

export function _isContains(json, value) {
    let contains = false;
    Object.keys(json).some(key => {
        contains = typeof json[key] === 'object' ?
            _isContains(json[key], value) : json[key] === value;
        return contains;
    });
    return contains;
}

export function logout(container) {
    sessionStorage.clear();
    container.props.history.push('/');
}

export function hexToRGB(hex,isArray = false) {
    try {

        if (isArray === true) {

            let allColors = [];

            hex.map(function(v,k) {
                v = '0x' + v.replace('#','');
                let r = v >> 16 & 0xFF;
                let g = v >> 8 & 0xFF;
                let b = v & 0xFF;
                allColors.push({r: r, g: g, b: b});
            });

            return allColors;

        }
        else {
            hex = '0x' + hex.replace('#','');
            let r = hex >> 16 & 0xFF;
            let g = hex >> 8 & 0xFF;
            let b = hex & 0xFF;
            return {r: r, g: g, b: b};
        }

    } catch (e) {
        console.log(e);
    }

}

export function getVideoFromServer(context,incomingIds,colors = [{"r": 0, "g": 0, "b": 0}],bgColor= {"r": 255, "g": 255, "b": 255},dpi=480) {

    context.setState({loading: true});

    if (bgColor === null) {
        bgColor = {"r": 255, "g": 255, "b": 255};
    }

    const videos = {
        "signatures": incomingIds,
        "penColors": colors,
        "backgroundColor": bgColor,
        "dpi": dpi,
        "fps": 60,
        "isNormalized": false,
        "maxPenWidthPixel" : 2,
        "minPenWidthPixel" : 2,
        "shouldDrawGrid" : true,
        "paddingLeft" : 16,
        "paddingRight" : 16,
        "paddingTop" : 64,
        "paddingBottom" : 16

    }



    return fetch(customConstants.apiUrl + "/signature/video", {
        method: 'POST',
        headers: {
            Authorization: forensicLib.getAuthHeader(),
            Accept: 'video/mp4',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(videos)
    }).then(async response => {
        if(response.ok === true) {
            context.setState({loading: false});
            return URL.createObjectURL(await response.blob());
        } else {
            context.setState({loading: false});
            NotificationManager.error(global.strings.unable_to_load_video);
            return false;
        }

    }).catch(err => {
        context.setState({loading: false});
        console.log(err)
    });

}

export const shortCuts = [
    // Press number 1 to jump to the postion of 10%
    {
        keyCode: 49, // Number 1
        handle: (player, actions) => {
            const duration = player.duration;
            // jump to the postion of 10%
            actions.seek(duration * 0.1);
        }
    },
    {
        keyCode: 38, // Up arrow
        handle: () => {
        } // override it's default handle
    },
    // Ctrl/Cmd + Right arrow to go forward 30 seconds
    {
        keyCode: 39, // Right arrow
        ctrl: true, // Ctrl/Cmd
        handle: (player, actions) => {
            if (!player.hasStarted) {
                return;
            }

            // this operation param is option
            // helps to display a bezel
            const operation = {
                action: 'forward-30',
                source: 'shortcut'
            };
            actions.forward(30, operation); // Go forward 30 seconds
        }
    }
];

