import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    en: {
        "welcome": "Welcome",
        "list": "List",
        "document_id": "Document ID",
        "upload": "Upload File",
        "doc_placeholder": "Ex: DID-XXXXXX-XXX-XXX",
        "email": "Your email address",
        "password": "Your password",
        "login": "Login",
        "similarity": "Similarity",
        "user": "User",
        "signature": "Signature ID",
        "success": "Operation Successful",
        "error": "Error",
        "upload_success": "Upload completed successfully",
        "upload_file_type_error": "You can only upload *.sig files",
        "max_two_file": "You can compare up to ten files at the same time",
        "max_two_file_selected": "You can compare up to two files at the same time",
        "clear": "Clear",
        "review_file": "Include in review",
        "time": "Time (s)",
        "velocity": "Speed m/s",
        "velocityX": "Speed(X) m/s",
        "velocityY": "Speed(Y) m/s",
        "acceleration": "Acceleration m/s2",
        "accelerationX": "Acceleration(X) m/s2",
        "accelerationY": "Acceleration(Y) m/s2",
        "pressure": "Pressure (N - Newton)",
        "pendown": "Pen Position (Down / Up)",
        "azimuth": "Pen Angle(Horizontal) (Radians)",
        "elevation": "Pen Angle(Vertical) (Radians)",
        "time_label": "Time",
        "velocity_label": "Speed",
        "velocityX_label": "Speed(X)",
        "velocityY_label": "Speed(Y)",
        "acceleration_label": "Acceleration",
        "accelerationX_label": "Acceleration(X)",
        "accelerationY_label": "Acceleration(Y)",
        "pressure_label": "Pressure",
        "pendown_label": "Pen Position",
        "azimuth_label": "Pen Angle(Horizontal)",
        "elevation_label": "Pen Angle(Vertical)",
        "xradio": "X",
        "yradio": "Y",
        "forensic_service": "Compare",
        "min_doc_compare": "You need to include a minimum of four signatures in the group for comparison.",
        "login_error": "Invalid user",
        "play": "Play",
        "replay": "Replay",
        "totalTime": "Total signature time",
        "timoutSpeed": "Speed (1/ms)",
        "exportAll": "Save All Charts as Image",
        "total_time": "Total Time",
        "rmsSpeedV": "Root Mean Square (RMS)",
        "meanSpeedV": "Average Speed",
        "avgSpeedVx": "Average speed in X coordinate",
        "avgAccCent": "Average centrifugal acceleration",
        "avgAccTang": "Average tangential acceleration",
        "ltwRatio": "Length/Width ratio",
        "HSpanRatio": "Horizontal Span Ratio",
        "midOfX": "Middle X point",
        "midOfY": "Middle Y point",
        "speedCorr": "Speed correlation",
        "totalLength": "Total length",
        "meanSpeedX": "Average Positive Speed, X coordinate",
        "meanSpeedY": "Average Positive Speed, Y coordinate",
        "penDownTime": "Total contact time",
        "ltaRatio": "Length/Area ratio",
        "hmsRatio": "Width / Slip ratio",
        "numberOfStrokes": "Total number of signature parts",
        "meanAscenderHeight": "Avg. height of tall letters",
        "meanDescenderDepth": "Avg. depth of low letters",
        "meanPressure": "Average pressure",
        "stdPressure": "Pressure standard deviation",
        "loginDesc": "Biometric Signature Forensic Examination Software",
        "logout": "Logout",
        "dashboard": "Signature Under Review",
        "reports": "Reports",
        "createReport": "Prepare Report",
        "uploadFile": "Upload file",
        "uploadContainer": "To upload a file, please drag and drop a *.sig file here or click to select",
        "uploadContainerPDF": "To upload a file, please drag and drop a *.pdf file here or click to select",
        "newRecord": "NEW RECORD",
        "search": "SEARCH",
        "tckn": "ID Number",
        "resultId": "Decision No",
        "fullname": "Your Full Name",
        "uploadDocument": "UPLOAD DOCUMENT",
        "referenceSign": "Signature Under Review",
        "toBeReference": "Upload the Signature to be Queried",
        "toBeSample": "Upload Signatures to be Compared",
        "groupName": "GROUP NAME",
        "date": "DATE",
        "description": "Description",
        "sampleSign": "Comparison Signatures",
        "addOtherSigns": "Add Signature Group",
        "groupNamePlaceholder": "Max. 10 characters",
        "upload_success_reference": "The signature under review has been successfully selected",
        "upload_success_group": "Group signature has been successfully selected",
        "upload_success_sign": "The signature was successfully uploaded",
        "signExamination": "SIGNATURE REVIEW",
        "subject": "SUBJECT",
        "printGraph": "PRINT",
        "showReport": "VIEW",
        "options": "Options",
        "reviewScreens": "Review Screens",
        "signView": "SIGNATURE VIEW",
        "compareSigns": "Compare Signatures",
        "signGroups": "Signature Groups",
        "loading": "Loading",
        "signvValues": "Signature Values",
        "parameter": "Parameter",
        "values": "Values",
        "view": "View",
        "animation": "Animation",
        "aiScore": "Artificial Intelligence Matching Score",
        "signs": "Signatures",
        "reference": "Signature Under Review",
        "signatureId": "Signature ID",
        "signGroup": "SIGNATURE GROUP",
        "referenceMatchScore": "MATCHING SCORE WITH THE SIGNATURE UNDER REVIEW",
        "downloadSignGroup": "DOWNLOAD SIGNATURE GROUP",
        "groupDetail": "GROUP DETAIL",
        "review": "Review",
        "selectedSignView": "View of the Selected Comparison Signature",
        "removeSignTitle": "Do you want to delete the signature?",
        "removeGroupTitle": "Do you want to delete the signature group?",
        "removeSignDesc": "By removing the selected signature from the group, it will be excluded from comparison. Do you confirm?",
        "removeGroupDesc": "By removing the selected signature group, all its signatures will be excluded from comparison. Do you confirm?",
        "yes": "Yes",
        "no": "CANCEL",
        "delete": "DELETE",
        "addSignGroup": "ADD SIGNATURE GROUP",
        "addSignToGroup": "ADD SIGNATURE TO GROUP",
        "selectCompareSigns": "Select Comparison Signatures",
        "lowerPresure": "Low Pressure",
        "higherPresure": "High Pressure",
        "caseId": "Decision No",
        "requiredFields": "Please fill in all required fields",
        "name": "Name Surname",
        "caseDate": "File Date",
        "atleastOneSign": "Please define at least one signature under review",
        "getFile": "Retrieve File",
        "doSearch": "Search",
        "createNew": "Create New",
        "only_one_reference": "You can only upload one signature file under review",
        "noRecord": "No files were found that match the search criteria",
        "underCons": "This section is still under construction.",
        "missingInformation": "Please enter all fields marked with (*)",
        "similar": "similar",
        "showHands": "Show hand raise highlights",
        "showHandsHide": "Hide hand raise highlights",
        "reportFirst": "Opinion",
        "report": "Report",
        "afterReport": "Following the review...",
        "resultReport": "As a result...",
        "submitReport": "Create Report",
        "removeCaseTitle": "Do you want to delete the file?",
        "removeCaseDesc": "Deleting the file will lose all its content (signature groups and signature). Do you wish to proceed?",
        "compareWithRerence": "Compare Image",
        "referenceSigns": "Signature Under Review",
        "arcTanHist0": "Tangent histogram value (0-45°)",
        "arcTanHist1": "Tangent histogram value (45-90°)",
        "arcTanHist2": "Tangent histogram value (90-135°)",
        "arcTanHist3": "Tangent histogram value (135-180°)",
        "arcTanHist4": "Tangent histogram value (180-225°)",
        "arcTanHist5": "Tangent histogram value (225-270°)",
        "arcTanHist6": "Tangent histogram value (270-315°)",
        "arcTanHist7": "Tangent histogram value (315-360°)",
        "arcTanDiffHist0": "Tangent difference histogram value (0-90°)",
        "arcTanDiffHist1": "Tangent difference histogram value (90-180°)",
        "arcTanDiffHist2": "Tangent difference histogram value (180-270°)",
        "arcTanDiffHist3": "Tangent difference histogram value (270-360°)",
        "speedSignChange": "Number of speed changes",
        "stdSpeedY": "Standard deviation of Y speed",
        "stdSpeedX": "standard deviation of speed X",
        "meanSpeedNegX": "Mean Negative Speed, X coordinate",
        "meanSpeedNegY": "Mean Negative Speed, Y coordinate",
        "minSpeedX": "Minimum X speed",
        "maxSpeedYToMean": "Maximum speed Y away from the mean",
        "maxSpeedYToMin": "Maximum - Minimum Y speed difference",
        "maxSpeedXToMean": "Maximum speed X away from the mean",
        "maxSpeedXToMin": "Maximum to Minimum X speed difference",
        "mvtmvyRatio": "Average Y speed/Max Y speed ratio",
        "drsRatio": "X-Y change / Drifts ratio",
        "maxXtoMinX": "X max - min difference",
        "wthRatio": "aspect ratio",
        "stpRatio": "Signature fragment/pen stroke ratio",
        "wtpRatio": "Width/pen move ratio",
        "overMeanXRatio": "Ratio of the number of X points above the mean",
        "overMeanYRatio": "Ratio of the number of Y points above the average",
        "wltplRatio": "Ratio of letter lengths to total length",
        "ascDescDiff": "High case - low case difference",
        "WTTRatio": "Average x difference in units of time",
        "minSpeedY": "Minimum Y speed",
        "caseDeleted": "File removed",
        "noReferenceDefined": "No defined review subject signature found",
        "addSignToGroupFirst": "Please add a signature to the group first",
        "playAnimation": "Play Animation",
        "layeredMode": "Overlay signatures",
        "gridMode": "Play signatures individually",
        "layeredModeTitle": "Layer Display Mode",
        "unable_to_load_video": "Error loading video",
        "signatureIds": "signature numbers",
        "totalTimeUp": "Number of manual lifts",
        "totalTimeUpTime": "Time the pen is not touching",
        "searchMenu": "Search Document",
        "registerNew": "New Comparison",
        "unit": "unit",
        "unitSecond": "sn",
        "unitMeterSecond": "m/sec",
        "unitMeterSecondSq": "m/sec",
        "count": "pieces",
        "supRatio": "floor/ratio",
        "meter": "m",
        "newton": "N (newton)",
        "expertId": "Expert No (2020/12345)",
        "expertIdWithoutPlaceholder": "Expert No",
        "caseMargin": "Request Authority",
        "requestDate": "Request Letter Date",
        "caseNo": "Number",
        "continue": "Continue",
        "cancel": "Cancel",
        "want_to_delete_file": "Do you want to delete the comparison file?",
        "want_to_delete_file_body": "Removing the file will make it inaccessible to you and the people you share it with. Do you want to continue?",
        "share_title": "Share with Users",
        "share_body": "The document will be shared with all experts you select from the user list",
        "share": "share",
        "search_expert": "Users to share",
        "no_option_text": "User not found",
        "users": "Users",
        "username": "User Name",
        "created_at": "Create",
        "role": "Authorization",
        "want_to_delete_user": "The user will be completely removed from the system",
        "want_to_delete_user_body": "If you remove the user you selected, their privileges and access will also be removed. Do you want to continue?",
        "user_removed": "User removed",
        "add": "Add",
        "user_password": "Password",
        "add_new_user_title": "Add New User",
        "edit": "Edit",
        "edit_user_title_user": "Edit User",
        "admin": "admin",
        "viewer": "expert",
        "speciality_no": "Specialty No",
        "request_authority": "Requested Authority",
        "shared_with_users": "Shared with selected users",
        "shared_users": "Shared Users",
        "start_date": "start",
        "end_date": "end",
        "result": "Decision",
        "specialist": "specialist",
        "date_between_validation": "Please select the start date less than the end date",
        "ok": "OK",
        "enable_colored_mode": "Hand lifts colored",
        "total_time_desc": "The time from the first point to the last point.",
        "total_time_up": "The number of times the hand was out of contact with the device.",
        "total_time_up_time": "The amount of time the hand was in the air, when the hand was out of contact with the device.",
        "rms_speed": "The root mean square (RMS) of the average of the squares of the speed information at each point.",
        "avg_speed_vx": "The average of the x-coordinate component of the velocity information at each point, i.e. the horizontal movement speed.",
        "avg_acc_cent": "The average of the centrifugal acceleration values at each point.",
        "avg_acc_tang": "The average of the tangent acceleration values at each point.",
        "ltw_ratio": "The ratio of the total length of the signature to the width of the signature, i.e. the horizontal distance between the leftmost point and the rightmost point.",
        "hsspan_ratio": "The ratio of the width of the signature to the span of the signature, i.e. the diagonal of the width and height vectors.",
        "mid_of_x": "The midpoint of the signature on the horizontal (x) axis.",
        "mid_of_y": "The midpoint of the signature on the vertical (y) axis.",
        "speed_coor": "The correlation (sum of the products) of the horizontal and vertical velocity components at each point.",
        "mean_pressure": "The average of the pressure information at each point.",
        "std_pressure": "The standard deviation of the pressure information at each point.",
        "total_len": "The total length of the signature.",
        "mean_speed_v": "The average of the speed information at each point.",
        "mean_speed_x": "The average of the positive horizontal velocity information at each point. It can be thought of as the average of the speeds to the right.",
        "mean_speed_neg_x": "The average of the negative horizontal velocity data at each point. It can be thought of as the average of the velocities to the left.",
        "mean_speed_y": "The average of the positive vertical velocity information at each point. It can be thought of as the average of the upward speeds.",
        "mean_speed_neg_y": "The average of the negative vertical velocity information at each point. Can be thought of as the average of the downward speeds.",
        "lta_ratio": "The ratio of the total length of the signature to the total area of the signature.",
        "number_of_strokes": "The total number of signature snippets. The term signature particle should not be confused with hand lift particle, as it is calculated in terms of speed changes, each speed change creates one signature particle.",
        "mean_asc_height": "The average length of signature fragments that are long upwards (can be thought of as letters with an upward extension, such as h, l, etc.).",
        "mean_desc_depth": "The average length of the downward long signature segments (which can be thought of as letters with downward extension, such as g, p).",
        "arc_tan_hist0": "Ratio of points with arctanjant value between 0-45 degrees to total points",
        "arc_tan_hist1": "Ratio of points with arctanjant value between 45-90 degrees to total points",
        "arc_tan_hist2": "Ratio of points with arctanjant value between 90-135 degrees to total points",
        "arc_tan_hist3": "Ratio of points with arctanjant value between 135-180 degrees to total points",
        "arc_tan_hist4": "Ratio of points with arctanjant value between 180-225 degrees to total points",
        "arc_tan_hist5": "Ratio of points with arctanjant value between 225-270 degrees to total points",
        "arc_tan_hist6": "Ratio of points with arctanjant value between 270-315 degrees to total points",
        "arc_tan_hist7": "Ratio of points with arctanjant value between 315-360 degrees to total points",
        "arc_tan_diff_hist0": "Ratio of points where the arctanjant difference between two points is between 0-90 degrees to the total points",
        "arc_tan_diff_hist1": "Ratio of points where the arctanjant difference between two points is between 90-180 degrees to the total points",
        "arc_tan_diff_hist2": "Ratio of points where the arctanjant difference between two points is between 180-270 degrees to the total points",
        "arc_tan_diff_hist3": "Ratio of points where the arctanjant difference between two points is between 270-360 degrees to the total points",
        "speed_sign_change": "The total number of positive to negative transitions between vertical and horizontal speeds. That is, the total number of points where the signature turns left when moving to the right and turns down when moving up.",
        "std_speed_y": "The standard deviation of the speeds on the vertical axis.",
        "std_speed_x": "The standard deviation of the speeds on the horizontal axis.",
        "min_speed_y": "The minimum speed measured on the vertical axis.",
        "max_speed_y_to_mean": "The difference between the highest and average vertical speed.",
        "max_speed_y_to_min": "The difference between the highest and lowest vertical speed.",
        "max_speed_x_to_mean": "The difference between the highest and average horizontal speed.",
        "max_speed_x_to_min": "The difference between the highest and the lowest horizontal speed.",
        "mvtmvy_ratio": "The ratio of the average speed to the highest vertical speed.",
        "drs_ratio": "The ratio of the total area of motion in the X-Y axes to the total mobility. It is calculated by multiplying the total difference on the X-axis by the mobility on the Y-axis (total difference between points) and dividing by the total difference on the Y-axis and the mobility on the X-axis.",
        "max_x_to_min_x": "The difference between the highest X value and the lowest X value. Gives the width of the signature.",
        "wth_ratio": "The ratio between width and height.",
        "stp_ratio": "The number of signature particles per pen stroke (each pen lift starts a new pen stroke).",
        "wtp_ratio": "The ratio of width to the number of pen moves.",
        "over_mean_x_ratio": "On the x-axis, the ratio of points that are above (to the right of) the mean.",
        "over_mean_y_ratio": "Returns the ratio of points on the y-axis that are above (above) the mean.",
        "wltp_ratio": "The ratio of the distance between the start and end points to the total length.",
        "asc_desc_diff": "The difference between the highest point of high letters and the lowest point of low letters.",
        "wtt_ratio": "The ratio of the difference between the highest and lowest X values to the total time. It tells how long the horizontal movement takes.",
        "reportDesc": "Please select the signature groups and comparison parameters you want to be included in the report",
        "report_title": "Generate Report",
        "create": "create",
        "no_sign_left": "No signature to select",
        "no_oarameter_left": "No parameters to select",
        "compare_signs": "Compared Signatures",
        "features": "Parameters",
        "pdfDownloadError": "Error downloading report",
        "select_sign_at_least_for_report": "Please select at least one reference and comparison signature",
        "cannot_add_more_sign": "You can select a maximum of 6 signatures.",
        "if_blank_all": "If you leave it blank, all parameters will be returned.",
        "cannot_add_more_reference": "You cannot select more than one reference",
        "reportResult": "Review Report",
        "need_four_sign_to_calvculate": "You need to load four signatures to generate a score",
        "signs_are_not_equal_for": "Signatures are not compatible (similar) for comparison",
        "upload_type": "Reference Signature Type",
        "export_data": "Export data",
        "mmeter": "mm",
        "mmeter2": "mm2",
        "dig4": "X positive, Y positive, X negative, Y negative",
        "ms": "ms",
        "micrometer": "micrometer",
        "microsecond": "microsecond",
        "microangle": "microdegree",
        "startSignLine": "Distance of the signature line to the start point",
        "startSignLine_desc": "Value of the distance of the signing line from the start point of the signature",
        "positiveXMoveLength": "Distance of movements in positive direction on the horizontal (x) axis",
        "positiveXMoveLength_desc": "",
        "positiveYMoveLength": "Distance of movements in the positive direction on the vertical (y) axis",
        "positiveYMoveLength_desc": "",
        "negativeXMoveLength": "The distance of their movement in the negative direction on the horizontal (x) axis",
        "negativeXMoveLength_desc": "",
        "negativeYMoveLength": "The distance of their movement in the negative direction on the vertical (y) axis",
        "negativeYMoveLength_desc": "",
        "startEndLength": "The distance as the crow flies between the start and end of the signature",
        "startEndLength_desc": "",
        "rectArea": "Signature area",
        "rectArea_desc": "Registered value of bounding memories occupied by the signature according to vertical(y) and Extreme(x) storage",
        "numberOfXTurns": "Number of turns made in the opposite direction on flow X",
        "numberOfXTurns_desc": "",
        "numberOfYTurns": "Number of turns made in the reverse direction on the Y flow",
        "numberOfYTurns_desc": "",
        "lengthXFeature": "Horizontal axis(x) width, including hand raises",
        "lengthXFeature_desc": "The width of the signature on the Extreme axis(x) is displayed in micro meters. For hand-rotation signatures, distances between hand-rotation movements are included.",
        "lengthYFeature": "Vertical axis(y) raises, including hand raises",
        "lengthYFeature_desc": "Toys on the vertical axis(y) of the signature are displayed in micrometers. In hand-raised signatures, distances between hand-raised movements are included.",
        "lengthOfPenLifts": "Distance between the start and end of hand raising movements",
        "lengthOfPenLifts_desc": "",
        "centerXOfGravity": "X center of gravity",
        "centerXOfGravity_desc": "",
        "centerYOfGravity": "Y weight center of gravity",
        "centerYOfGravity_desc": "",
        "startPenUpDistance": "Sum of the starting distances of the pen movement to the signature and the path distances of the pen",
        "startPenUpDistance_desc": "The value of the sum of the distance of the starting stroke of the hand raising movement to the beginning of the signature and the distances of the pen's path in a signature made with a raised hand",
        "endPenUpDistance": "Sum of the initial strokes of the hand raising movement, the signature ending distances and the total vertical path distances of the pen",
        "endPenUpDistance_desc": "In the signature made with a raised hand, the distance of the starting stroke of the hand-raised movement to the end of the signature and the value of the sum of the horizontal path distances of the pen",
        "positiveNegativeArea": "Negative and positive backup area of the signature",
        "positiveNegativeArea_desc": "The net area value of the vertical (y) and Extreme (x) parts of the signature covered by the negative and positive records. If it is in the negative area consisting of the area covered by the signature, a negative value is given.",
        "timeOfPenLifts": "Time between the initial movement of the signature and the hand raising movements",
        "timeOfPenLifts_desc": "The durability value of hand raising movements in signatures made between the hand raising movements and the initial movement of the signature",
        "lengthXWithoutPenLifts": "Horizontal axis(x) width",
        "lengthXWithoutPenLifts_desc": "The width of the signature on the Extreme axis (x) is displayed in micro meters. In signatures with raised hands, distances between hand stops are not included.",
        "reverseTimeOfPenLifts": "Time between the signature's hand raising movements and the ending movement",
        "reverseTimeOfPenLifts_desc": "The durability value between the hand raising gestures and the ending movement of the signature in signatures made with hand raising gestures",
        "lengthYWithoutPenLifts": "Vertical axis(y) clearing",
        "lengthYWithoutPenLifts_desc": "Temperatures on the vertical axis (y) of the signature are shown in micrometers. In signatures with raised hands, distances between hand lifts are not included.",
        "baseCenterOfGravity": "Base value of the position of the center of gravity",
        "baseCenterOfGravity_desc": "",
        "downLineLength": "Total length of the lines created by the downward contact of the pen",
        "downLineLength_desc": "The value of the total length of the lines created by the downward contact of the pen in micro meters",
        "distanceOfMaxY": "Max is 0 distance from point Y",
        "distanceOfMaxY_desc": "Distance from 0 to the maximum point in the vertical(y) span of the signature",
        "avgSpeed": "Average acceleration value",
        "avgSpeed_desc": "Specification of the average speed of the pen when signing, micrometers / microseconds",
        "extraevidence": "Additional Evidence",
        "key": "Key",
        "send": "Send",
        "bandWidth": "Signature bandwidth",
        "bandWidth_desc": "The band gap value that the signatures occupy according to the vertical (y) and Extreme (x) axis",
        "missingFile": "Please select *.pdf file for additional evidence ",
        "missingKey": "Please paste the publicKey of the file",
        "selectedFile": "Selected File",
        "file": "File (*.PDF)",
        "sign_could_not_read": "The signature could not be read, please try again in the correct format and with the file of the signature information",
        "sign_info_corrupted": "Signature information is incorrect",
        "totalAngleTurn": "Sum of angle values created by rotation movements",
        "totalAngleTurn_desc": "Specifies the sum of the angle values created by the rotation movements within the signature. It is stated as a positive value in signatures that change up or right turns, and as a negative value in signatures that turn down or left.",
        "angleVerticalTip": "Angle value between vertical tips",
        "angleVerticalTip_desc": "",
        "baTurnAngle": "The angle value of the signature of the turning movements towards the top line",
        "baTurnAngle_desc": "Specifies the angle values of the signature of the rotation movements to the upper line. The rotation signature with the upward movements is specified as a negative value, and the working signature with the downward movement is specified as the value.",
        "wthRatio_1": "X width width / Y width length",
        "wthRatio_1_desc": "The ratio of the width of the signature in the Extreme(x) range to its height in the vertical axis (y)",
        "features_graph": "Parameter Graphs",
        "last_hash_list": "Reviews for the same signature",
        "last_hash_list_desc": "If the signature subject to examination has not been uploaded, it has already been uploaded. If you wish, you can examine one of the previous files or continue examining.",
        "continue_anyway": "Continue",
        "exportDate": "Report Creation",
        "investigationDate": "Software Installation",
        "extraEvidence": "Additinal Evidence",
        "submit": "Submit",
        "appTitle" : "Techsign Biometric Signature Analysis Software",
        "turkish" : 'Turkish',
        "english" : 'English',
    },
    tr: {
        "turkish" : 'Türkçe',
        "english" : 'İngilizce',
        "appTitle" : "Techsign Biyometrik İmza Analiz Yazılımı",
        "extraevidence" : "Ek Kanıt",
        "welcome": "Hoşgeldiniz",
        "list": "Listele",
        "document_id": "Döküman ID",
        "upload": "Dosya Yükle",
        "doc_placeholder": "Ör : DID-XXXXXX-XXX-XXX",
        "email": "E-posta adresiniz",
        "password": "Şifreniz",
        "login": "Giriş Yap",
        "similarity": "Benzerlik",
        "user": "Kullanıcı",
        "signature": "İmza Kimliği",
        "success": "İşlem Başarılı",
        "error": "Hata",
        "upload_success": "Yükleme başarıyla tamamlandı",
        "upload_file_type_error": "Yalnızca *.sig dosyaları yükleyebilirsiniz",
        "max_two_file": "En fazla on adet dosyayı aynı anda karşılaştırabilirsiniz",
        "max_two_file_selected": "Aynı anda en fazla iki dosyayı karşılaştırabilirsiniz",
        "clear": "Temizle",
        "review_file": "İncelemeye dahil et",
        "time": "Zaman (sn)",
        "velocity": "Hız m/sn",
        "velocityX": "Hız(X) m/sn",
        "velocityY": "Hız(Y) m/sn",
        "acceleration": "İvme m/sn2",
        "accelerationX": "İvme(X) m/sn2",
        "accelerationY": "İvme(Y) m/sn2",
        "pressure": "Basınç (N - Newton)",
        "pendown": "Kalem Konumu (Aşağı / Yukarı)",
        "azimuth": "Kalem Açısı(Yatay) (Radyan)",
        "elevation": "Kalem Açısı(Dikey) (Radyan)",
        "time_label": "Zaman",
        "velocity_label": "Hız",
        "velocityX_label": "Hız(X)",
        "velocityY_label": "Hız(Y)",
        "acceleration_label": "İvme ",
        "accelerationX_label": "İvme(X) ",
        "accelerationY_label": "İvme(Y)",
        "pressure_label": "Basınç",
        "pendown_label": "Kalem Konumu",
        "azimuth_label": "Kalem Açısı(Yatay)",
        "elevation_label": "Kalem Açısı(Dikey)",
        "xradio": "X",
        "yradio": "Y",
        "forensic_service": "Karşılaştır",
        "min_doc_compare": "Karşılaştırmak için minimum dört adet imzayı gruba dahil etmeniz gerekmektedir.",
        "login_error": "Hatalı kullanıcı",
        "play": "Oynat",
        "replay": "Tekrar Oynat",
        "totalTime": "İmza toplam süresi",
        "timoutSpeed": "Hız (1/ms) ",
        "exportAll": "Tüm Grafikleri İmaj Olarak Kaydet",
        "total_time": "Toplam Zaman",
        "rmsSpeedV": "Ort. Kare Ort. Karekök (RMS)",
        "meanSpeedV": "Ortalama Hız",
        "avgSpeedVx": "Ortalama X koordinatında hız",
        "avgAccCent": "Ortalama merkezkaç ivmelenmesi",
        "avgAccTang": "Ortalama tanjant ivmelenmesi",
        "ltwRatio": "Uzunluk/En oranı",
        "HSpanRatio": "Yatay Span Oranı",
        "midOfX": "Orta X noktası",
        "midOfY": "Orta Y noktası",
        "speedCorr": "Hız korreleasyonu",
        "totalLength": "Toplam uzunluk ",
        "meanSpeedX": "Ortalama Pozitif Hız, X koordinatı",
        "meanSpeedY": "Ortalama Pozitif Hız, Y koordinatı",
        "penDownTime": "Toplam temas süresi",
        "ltaRatio": "Uzunluk/Alan oranı",
        "hmsRatio": "En / Kayma oranı",
        "numberOfStrokes": "Toplam imza parçası",
        "meanAscenderHeight": "Dik uzun harflerin ort. yüksekliği ",
        "meanDescenderDepth": "Dik alçak harflerin ort. alçaklığı",
        "meanPressure": "Ortalama basınç",
        "stdPressure": "Basınç standart sapması",
        //"loginDesc": "JKDB Biyometrik İmza Adli İnceleme Yazılımı",
        "loginDesc": "Biyometrik İmza Adli İnceleme Yazılımı",
        "logout": "Çıkış",
        "dashboard": "İnceleme Konusu İmza",
        "reports": "Raporlar",
        "createReport": "Rapor Hazırla",
        "uploadFile": "Dosya yükle",
        "uploadContainer": "Dosya yüklemek için lütfen *.sig dosyasını bu alana sürükleyiniz veya tıklayıp seçiniz",
        "uploadContainerPDF": "Dosya yüklemek için lütfen *.pdf dosyasını bu alana sürükleyiniz veya tıklayıp seçiniz",
        "newRecord": "YENİ KAYIT",
        "search": "ARAMA",
        "tckn": "TCKN",
        "resultId": "Karar No",
        "fullname": "Adınız Soyadınız",
        "uploadDocument": "BELGE YÜKLE",
        "referenceSign": "İnceleme Konusu İmza",
        "toBeReference": "Sorgulanacak İmzayı Yükleyiniz",
        "toBeSample": "Kıyaslama İçin Alınacak İmzaları Yükleyiniz",
        "groupName": "GRUP ADI",
        "date": "TARİH",
        "description": "Açıklama",
        "sampleSign": "Mukayese İmzalar",
        "addOtherSigns": "İmza Grubu Ekle",
        "groupNamePlaceholder": "Maks. 10 karakter",
        "upload_success_reference": "İnceleme konusu imza başarıyla seçildi",
        "upload_success_group": "Grup imza başarıyla seçildi",
        "upload_success_sign": "İmza başarıyla yüklendi",
        "signExamination": "İMZA İNCELEME",
        "subject": "KONU",
        "printGraph": "YAZDIR",
        "showReport": "GÖRÜNTÜLE",
        "options": "Seçenekler",
        "reviewScreens": "İnceleme Ekranları",
        "signView": "İMZA GÖRÜNTÜSÜ",
        "compareSigns": "İmza Kıyaslama",
        "signGroups": "İmza Grupları",
        "loading": "Yükleniyor",
        "signvValues": "İmza Değerleri",
        "parameter": "Parametre",
        "values": "Değer",
        "view": "Görüntü",
        "animation": "Animasyon",
        "aiScore": "Yapay Zeka Eşleşme Skoru",
        "signs": "İmzalar",
        "reference": "İnceleme Konusu İmza",
        "signatureId": "İmza ID",
        "signGroup": "İMZA GRUBU",
        "referenceMatchScore": "İNCELEME KONUSU İMZA İLE EŞLEŞME SKORU",
        "downloadSignGroup": "İMZA GRUBUNU İNDİR",
        "groupDetail": "GRUP DETAY",
        "review": "İncele",
        "selectedSignView": "Seçilen Mukayese İmzaya Ait Görüntü",
        "removeSignTitle": "İmzayı Silmek İstiyor Musunuz ?",
        "removeGroupTitle": "İmza Grubunu Silmek İstiyor Musunuz ?",
        "removeSignDesc": "Seçtiğiniz imza gruptan kaldırarak karşılaştırma dışında kalacaktır. Onaylıyor musunuz ?",
        "removeGroupDesc": "Seçtiğiniz imza grubunu kaldırarak sahip olduğu tüm imzalar karşılaştırma dışında kalacaktır. Onaylıyor musunuz ?",
        "yes": "Evet",
        "no": "VAZGEÇ",
        "delete": "SİL",
        "addSignGroup": "İMZA GRUBU EKLE",
        "addSignToGroup": " GRUBUNA İMZA EKLE",
        "selectCompareSigns": "Karşılaştırma İmzaları",
        "lowerPresure": "Düşük Basınç",
        "higherPresure": "Yüksek Basınç",
        "caseId": "Karar No",
        "requiredFields": "Lütfen tüm zorunlu alanları doldurunuz",
        "name": "Adı Soyadı",
        "caseDate": "Dosya Tarihi",
        "atleastOneSign": "Lütfen bir inceleme konusu imza tanımlayınız",
        "getFile": "Dosyayı Getir",
        "doSearch": "Arama Yap",
        "createNew": "Yeni Oluştur",
        "only_one_reference": "Yalnızca bir inceleme konusu imza dosyası yükleyebilirsiniz",
        "noRecord": "Aranan kriterlere uygun dosya bulunamamıştır",
        "underCons": "Bu bölüm henüz yapım aşamasındadır.",
        "missingInformation": "Lütfen (*) ile işratli tüm alanların tümünü giriniz",
        "similar": "benzer",
        "showHands": "El kaldırmaları vurgularını göster",
        "showHandsHide": "El kaldırmaları vurgularını gizle",
        "reportFirst": "Mütalaa",
        "report": "Rapor",
        "afterReport": "Yapılan incelemede...",
        "resultReport": "Sonuç olarak...",
        "submitReport": "Raporu Oluştur",
        "removeCaseTitle": "Dosyayı silmek istiyor musunuz?",
        "removeCaseDesc": "Dosyayı silerek tüm içeriğini (imza grupları ve imza) bilgilerini kaybedeceksiniz. Devam etmek istiyor musunuz?",
        "compareWithRerence": "Görüntü Kıyasla",
        "referenceSigns": "İnceleme Konusu İmza",
        "arcTanHist0": "Tanjant histogram değeri (0-45°)",
        "arcTanHist1": "Tanjant histogram değeri (45-90°)",
        "arcTanHist2": "Tanjant histogram değeri (90-135°)",
        "arcTanHist3": "Tanjant histogram değeri (135-180°)",
        "arcTanHist4": "Tanjant histogram değeri (180-225°)",
        "arcTanHist5": "Tanjant histogram değeri (225-270°)",
        "arcTanHist6": "Tanjant histogram değeri (270-315°)",
        "arcTanHist7": "Tanjant histogram değeri (315-360°)",
        "arcTanDiffHist0": "Tanjant fark histogram değeri (0-90°)",
        "arcTanDiffHist1": "Tanjant fark histogram değeri (90-180°)",
        "arcTanDiffHist2": "Tanjant fark histogram değeri (180-270°)",
        "arcTanDiffHist3": "Tanjant fark histogram değeri (270-360°)",
        "speedSignChange": "Hız değişim sayısı",
        "stdSpeedY": "Y hızı standart sapması",
        "stdSpeedX": "X hızı standart sapması",
        "meanSpeedNegX": "Ortalama Negatif Hız, X koordinatı",
        "meanSpeedNegY": "Ortalama Negatif Hız, Y koordinatı",
        "minSpeedX": "Minimum X hızı",
        "maxSpeedYToMean": "Maximum Y hızının ortalamadan uzaklığı",
        "maxSpeedYToMin": "Maximum - Minimum Y hızı farkı",
        "maxSpeedXToMean": "Maximum X hızının ortalamadan uzaklığı",
        "maxSpeedXToMin": "Maximum - Minimum X hızı farkı",
        "mvtmvyRatio": "Ortalama Y hızı/Max Y hızı oranı",
        "drsRatio": "X-Y değişiklik / Kaymalar oranı",
        "maxXtoMinX": "X max - min farkı",
        "wthRatio": "En/boy oranı",
        "stpRatio": "İmza parçası / kalem hamlesi oranı",
        "wtpRatio": "En / kalem hamlesi oranı",
        "overMeanXRatio": "Ortalama üstündeki X noktası sayısının oranı",
        "overMeanYRatio": "Ortalama üstündeki Y noktası sayısının oranı",
        "wltplRatio": "Harf uzunluklarının toplam uzunluğa oranı",
        "ascDescDiff": "Yüksek harf - alçak harf farkı",
        "WTTRatio": "Zaman biriminde oluşan ortalama x farkı",
        "minSpeedY": "Minimum Y hızı",
        "caseDeleted": "Dosya kaldırıldı",
        "noReferenceDefined": "Hiç bir tanımlı inceleme konusu imza bulunamadı",
        "addSignToGroupFirst": "Lütfen öncelikle gruba bir imza ekleyiniz",
        "playAnimation": "Animasyonu Oynat",
        "layeredMode": "İmzaları üst üste bindir",
        "gridMode": "İmzaları ayrı ayrı oynat",
        "layeredModeTitle": "Katman Gösterim Modu",
        "unable_to_load_video": "Video yüklenirken hata oluştu",
        "signatureIds": "İmza numaraları",
        "totalTimeUp": "El kaldırma sayısı",
        "totalTimeUpTime": "Kalemin Temas Etmediği Süre",
        "searchMenu": "Belge Ara",
        "registerNew": "Yeni Karşılaştırma",
        "unit": "Birim",
        "unitSecond": "sn",
        "unitMeterSecond": "m/sn",
        "unitMeterSecondSq": "m/sn",
        "count": "adet",
        "supRatio": "kat / oran",
        "meter": "m",
        "newton": "N (newton)",
        "expertId": "Uzmanlık No (2020/12345)",
        "expertIdWithoutPlaceholder": "Uzmanlık No",
        "caseMargin": "Talep Makamı",
        "requestDate": "Talep Yazısı Tarihi",
        "caseNo": "Sayı",
        "continue": "Devam Et",
        "cancel": "Vazgeç",
        "want_to_delete_file": "Karşılaştırma dosyasını silmek istiyor musunuz?",
        "want_to_delete_file_body": "Dosyayı kaldırdığınız taktirde siz ve paylaştığınız kişiler erişemez olacaktır. Devam etmek istiyor musunuz?",
        "share_title": "Kullanıcılar İle Paylaş",
        "share_body": "Kullanıcı listesinden seçtiğiniz tüm uzmanlar ile belge paylaşılacaktır ",
        "share": "Paylaş",
        "search_expert": "Paylaşılacak Kullanıcılar",
        "no_option_text": "Kullanıcı bulunamadı",
        "users": "Kullanıcılar",
        "username": "Kullanıcı Adı",
        "created_at": "Oluşturma",
        "role": "Yetki",
        "want_to_delete_user": "Kullanıcı sistemden tamamen kaldırılacaktır",
        "want_to_delete_user_body": "Seçmiş olduğunuz kullanıcıyı kaldırdığınız taktirde yetki ve erişimleri de kaldırılacaktır. Devam etmek istiyor musunuz?",
        "user_removed": "Kullanıcı kaldırıldı",
        "add": "Ekle",
        "user_password": "Şifre",
        "add_new_user_title": "Yeni Kullanıcı Ekle",
        "edit": "Düzenle",
        "edit_user_title_user": "Kullanıcıyı Düzenle",
        "admin": "Yönetici",
        "viewer": "Uzman",
        "speciality_no": "Uzman No",
        "request_authority": "Talep Edilen Makam",
        "shared_with_users": "Seçilen kullanıcılar ile paylaşım yapıldı",
        "shared_users": "Paylaşılan Kullanıcılar",
        "start_date": "Başlangıç",
        "end_date": "Bitiş",
        "result": "Karar",
        "specialist": "Uzman",
        "date_between_validation": "Lütfen başlangıç tarihini bitiş tarihinden küçük seçiniz",
        "ok": "Tamam",
        "enable_colored_mode": " El kaldırmalar renkli",
        "total_time_desc": "İlk noktanın atılmasından son noktanın atılmasına kadar geçen süre bilgisidir.",
        "total_time_up": "Kaç defa elin cihaz ile temasının kesildiği bilgisidir.",
        "total_time_up_time": "Elin cihaz ile temasının kesik olduğu, elin havada olduğu süre bilgisidir.",
        "rms_speed": "Her noktadaki hız bilgisinin karelerinin ortalamasının karekökü (RMS) bilgisidir.",
        "avg_speed_vx": "Her noktadaki hız bilgisinin, x koordinatı bileşeninin, yani yatay hareket hızının ortalamasıdır.",
        "avg_acc_cent": "Her noktadaki merkezkaç ivmesi değerlerinin ortalamasıdır.",
        "avg_acc_tang": "Her noktadaki tanjant ivmesi değerlerinin ortalamasıdır.",
        "ltw_ratio": "İmzanın toplam uzunluğunun, imzanın enine, yani en soldaki nokta ile en sağdaki nokta arasındaki yatay mesafeye, oranıdır.",
        "hsspan_ratio": "İmzanın eninin, imzanın span bilgisine, yani en ve boy vektörlerinin diyagonaline oranıdır.",
        "mid_of_x": "İmzanın yatay (x) eksendeki orta noktasıdır.",
        "mid_of_y": "İmzanın dikey (y) eksendeki orta noktasıdır.",
        "speed_coor": "Her noktadaki yatay ve dikey hız bileşenlerinin korreleasyonudur (çarpımlarının toplamıdır).",
        "mean_pressure": "Her noktadaki basınç bilgisinin ortalamasıdır.",
        "std_pressure": "Her noktadaki basınç bilgisinin standart sapmasıdır.",
        "total_len": "İmzanın toplam uzunluğudur.",
        "mean_speed_v": "Her noktadaki hız bilgisinin ortalamasıdır.",
        "mean_speed_x": "Her noktadaki yatay hız bilisinin, pozitif olanlarının ortalamasıdır. Sağa doğru olan hızların ortalaması gibi düşünülebilir.",
        "mean_speed_neg_x": "Her noktadaki yatay hız bilisinin, negatif olanlarının ortalamasıdır. Sola doğru olan hızların ortalaması gibi düşünülebilir.",
        "mean_speed_y": "Her noktadaki dikey hız bilgisinin, pozitif olanlarının ortalamasıdır. Yukarı doğru olan hızların ortalaması gibi düşünülebilir.",
        "mean_speed_neg_y": "Her noktadaki dikey hız bilgisinin, negatif olanlarının ortalamasıdır. Aşağı doğru olan hızların ortalaması gibi düşünülebilir.",
        "lta_ratio": "İmzanın toplam uzunluğunun, imzanın toplam alanına oranıdır.",
        "number_of_strokes": "İmza parçacıklarının toplam sayısıdır. İmza parçacığı terimi hız değişiklikleri ile hesaplandığından, el kaldırma parçasıyla karıştırılmamalıdır, her hız değişikliği bir imza parçası oluşturur.",
        "mean_asc_height": "Yukarıya doğru uzun imza parçalarının (yukarı uzantısı olan h, l gibi harfler olarak düşünülebilir) ortalama uzunluğudur.",
        "mean_desc_depth": "Aşağıya doğru uzun imza parçalarının (aşağı uzantısı olan g,p gibi harfler olarak düşünülebilir) ortalama uzunluğudur.",
        "arc_tan_hist0": "Arctanjant değeri 0-45 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_hist1": "Arctanjant değeri 45-90 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_hist2": "Arctanjant değeri 90-135 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_hist3": "Arctanjant değeri 135-180 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_hist4": "Arctanjant değeri 180-225 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_hist5": "Arctanjant değeri 225-270 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_hist6": "Arctanjant değeri 270-315 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_hist7": "Arctanjant değeri 315-360 derece arasında olan noktaların toplam noktalara oranı",
        "arc_tan_diff_hist0": "İki nokta arasındaki arctanjant farkının 0-90 derece arasında olduğu noktaların toplam noktalara oranı",
        "arc_tan_diff_hist1": "İki nokta arasındaki arctanjant farkının 90-180 derece arasında olduğu noktaların toplam noktalara oranı",
        "arc_tan_diff_hist2": "İki nokta arasındaki arctanjant farkının 180-270 derece arasında olduğu noktaların toplam noktalara oranı",
        "arc_tan_diff_hist3": "İki nokta arasındaki arctanjant farkının 270-360 derece arasında olduğu noktaların toplam noktalara oranı",
        "speed_sign_change": "Dikey ve yatay hızların pozitif negatif arasındaki geçişlerinin toplam sayısıdır. Yani imza sağa doğru atılırken sola dönüş, yukarı doğru atılırken aşağı dönüş yaptığı noktaların toplam sayısıdır.",
        "std_speed_y": "Dikey eksendeki hızların standart sapmasıdır.",
        "std_speed_x": "Yatay eksendeki hızların standart sapmasıdır.",
        "min_speed_y": "Dikey eksende ölçülen en düşük hız bilgisidir.",
        "max_speed_y_to_mean": "En yüksek ve ortalama dikey hız arasındaki farktır.",
        "max_speed_y_to_min": "En yüksek ve en düşük dikey hız arasındaki farktır.",
        "max_speed_x_to_mean": "En yüksek ve ortalama yatay hız arasındaki farktır.",
        "max_speed_x_to_min": "En yüksek ve en düşük yatay hız arasındaki farktır.",
        "mvtmvy_ratio": "Ortalama hızın en yüksek dikey hıza oranıdır.",
        "drs_ratio": "X-Y eksenlerindeki toplam hareket alanının, toplam hareketliliğe oranını verir. X eksenindeki toplam farkın, Y eksenindeki hareketlilikle (noktalar arasındaki toplam fark) çarpılıp, Y eksenindeki toplam farka ve X eksenindeki hareketliliğe bölünmesiyle bulunur.",
        "max_x_to_min_x": "En yüksek X değeri ile en düşük X değeri arasındaki farktır. İmzanın enini verir.",
        "wth_ratio": "En ile boy arasındaki orandır.",
        "stp_ratio": "Kalem hareketi (her kalem kaldırmada yeni bir kalem hareketi başlar) başına düşen imza parçacık sayısıdır.",
        "wtp_ratio": "Enin kalem hareketi sayısına oranıdır.",
        "over_mean_x_ratio": "X ekseninde, ortalamanın üstünde (sağında) olan noktaların oranını verir.",
        "over_mean_y_ratio": "Y ekseninde, ortalamanın üstünde (üzerinde) olan noktaların oranını verir.",
        "wltp_ratio": "Başlangıç ve bitiş noktaları arasındaki mesafenin, toplam uzunluğa oranıdır.",
        "asc_desc_diff": "Yüksek harflerin en yüksek noktası ile alçak harflerin en alçak noktası arasındaki farkı verir.",
        "wtt_ratio": "En yüksek ve en düşük X değerleri arasındaki farkın, toplam süreye oranıdır. Yatay hareketin ne kadar sürede gerçekleştiğini verir.",
        "reportDesc": "Lütfen raporda yer almasını istediğiniz imza gruplarını ve karılaştırma parametrelerini seçiniz",
        "report_title": "Rapor Oluştur",
        "create": "Oluştur",
        "no_sign_left": "Seçebilecek imza yok",
        "no_oarameter_left": "Seçebilecek parametre yok",
        "compare_signs": "Kıyaslanan İmzalar",
        "features": "Parametreler",
        "pdfDownloadError": "Rapor  indirilirken hata oluştu",
        "select_sign_at_least_for_report": "Lütfen en az bir referans ve karşılaştırma imzası seçiniz",
        "cannot_add_more_sign": "Maksimum 6 adet imza seçebilirsiniz.",
        "if_blank_all": "Boş bıraktığınız taktirde tüm parametreler gelecektir.",
        "cannot_add_more_reference": "Birden fazla referans seçemezsiniz",
        "reportResult": "İnceleme Raporu",
        "need_four_sign_to_calvculate": "Skor üretebilmeniz için dört imza yüklemelisiniz",
        "signs_are_not_equal_for": "İmzalar karşılaştırma için uyumlu (benzer) nitelikte değil",
        "upload_type": "Referans İmza Tipi",
        "export_data": "Veriyi dışarı aktar",
        "mmeter": "mm",
        "mmeter2": "mm2",
        "dig4": "X positive, Y positive, X negative, Y negative",
        "ms": "ms",
        "micrometer": "mikrometre",
        "microsecond": "mikrosaniye",
        "microangle": "mikroderece",

        "startSignLine": "İmza hattının başlangıç noktasına mesafesi",
        "startSignLine_desc": "İmzalama hattının imzanın başlangıç noktasına olan mesafesinin değeri",
        "positiveXMoveLength": "Yatay (x) eksen üzerinde pozitif yöndeki hareketlerinin mesafesi",
        "positiveXMoveLength_desc": "",
        "positiveYMoveLength": "Dikey (y) eksen üzerinde pozitif yöndeki hareketlerinin mesafesi",
        "positiveYMoveLength_desc": "",
        "negativeXMoveLength": "Yatay (x) eksen üzerinde negatif yöndeki hareketlerinin mesafesi",
        "negativeXMoveLength_desc": "",
        "negativeYMoveLength": "Dikey (y) eksen üzerinde negatif yöndeki hareketlerinin mesafesi",
        "negativeYMoveLength_desc": "",
        "startEndLength": "İmzanın başlangıç ve bitimi arasındaki kuş uçuşu mesafe",
        "startEndLength_desc": "",
        "rectArea": "İmza alanı",
        "rectArea_desc": "İmzanın dikey(y) ve yatay(x) eksenine göre kapladığı sınırlayıcı dikdörtgenin alanının değeri",
        "numberOfXTurns": "X ekseni üzerinde ters yönde yapılan dönüş sayısı",
        "numberOfXTurns_desc": "",
        "numberOfYTurns": "Y ekseni üzerinde ters yönde yapılan dönüş sayısı",
        "numberOfYTurns_desc": "",
        "lengthXFeature": "Yatay eksen(x) genişliği, el kaldırmalar dahil",
        "lengthXFeature_desc": "İmzanın yatay eksen(x) üzerindeki genişliği mikro metre cinsinden gösterilir. El kaldırmalı imzalarda, el kaldırma hareketleri arasındaki mesafeler dahil edilir.",
        "lengthYFeature": "Dikey eksen(y) yüksekliği, el kaldırmalar dahil",
        "lengthYFeature_desc": "İmzanın dikey eksen(y) üzerindeki yüksekliği mikro metre cinsinden gösterilir. El kaldırmalı imzalarda, el kaldırma hareketleri arasındaki mesafeler dahil edilir.",
        "lengthOfPenLifts": "El kaldırma hareketlerinin başlangıç ve bitimi arasındaki mesafe",
        "lengthOfPenLifts_desc": "",
        "centerXOfGravity": "X ekseni ağırlık merkezi",
        "centerXOfGravity_desc": "",
        "centerYOfGravity": "Y ekseni ağırlık merkezi",
        "centerYOfGravity_desc": "",
        "startPenUpDistance": "El kaldırma hareketinin imza başlangıcına mesafelerinin ve kalemin izlediği yol mesafeleri toplamı",
        "startPenUpDistance_desc": "El kaldırmalı olarak atılan imzada el kaldırma hareketinin başlangıç vuruşunun imzanın başlangıcına olan mesafesi ve kalemin izlediği yol mesafelerinin toplamının değeri",
        "endPenUpDistance": "El kaldırma hareketi başlangıç vuruşlarının imza bitimine mesafeleri ve kalemin izlediği toplam düşey yol mesafeleri toplamı",
        "endPenUpDistance_desc": "El kaldırmalı olarak atılan imzada el kaldırma hareketinin başlangıç vuruşunun imzanın bitimine olan mesafesi ve kalemin izlediği düşey yol mesafelerinin toplamının değeri",
        "positiveNegativeArea": "İmzanın negatif ve pozitif bölgelerdeki alanı",
        "positiveNegativeArea_desc": "İmzanın dikey(y) ve yatay(x) ekseninin negatif ve pozitif bölgelerdeki kapladığı net alanın değeri. İmzanın kapladığı alanın çoğunluğu negatif alanda olması durumunda negatif değer verilmektedir.",
        "timeOfPenLifts": "İmzanın başlangıç hareketi ile el kaldırma hareketleri arasındaki zaman",
        "timeOfPenLifts_desc": "El kaldırma hareketleri yapılarak atılan imzalarda el kaldırma hareketleri ile imzanın başlangıç hareketi arasındaki zamanın değeri",
        "lengthXWithoutPenLifts": "Yatay eksen(x) genişliği",
        "lengthXWithoutPenLifts_desc": "İmzanın yatay eksen(x) üzerindeki genişliği mikro metre cinsinden gösterilir. El kaldırmalı imzalarda, el kaldırma hareketleri arasındaki mesafeler dahil değildir.",
        "reverseTimeOfPenLifts": "İmzanın el kaldırma hareketleri ile bitim hareketi arasındaki zaman",
        "reverseTimeOfPenLifts_desc": "El kaldırma hareketleri yapılarak atılan imzalarda el kaldırma hareketleri ile imzanın bitim hareketi arasındaki zamanın değeri",
        "lengthYWithoutPenLifts": "Dikey eksen(y) yüksekliği",
        "lengthYWithoutPenLifts_desc": "İmzanın dikey eksen(y) üzerindeki yüksekliği mikro metre cinsinden gösterilir. El kaldırmalı imzalarda, el kaldırma hareketleri arasındaki mesafeler dahil değildir.",
        "baseCenterOfGravity": "Ağırlık merkezinin temel pozisyon değeri",
        "baseCenterOfGravity_desc": "",
        "downLineLength": "Kalemin aşağı yönde temas ederek oluşturduğu çizgilerin toplam uzunluğu",
        "downLineLength_desc": "Kalemin aşağı yönde temas ederek oluşturduğu çizgilerin toplam uzunluğunun mikro metre cinsinden değeri",
        "distanceOfMaxY": "Max Y değerinin 0 noktasına mesafesi",
        "distanceOfMaxY_desc": "İmzanın dikey(y) ekseninde maksimum yaptığı değerinin 0 noktasına olan mesafesi",
        "avgSpeed": "Ortalama hızlanma değeri",
        "avgSpeed_desc": "İmza atma esnasında kalemin ortlama hızlanma değeri, mikro metre / mikro saniye",
        "extraEvidence": "Ek Kanıt",
        "key": "Anahtar",
        "submit": "Gönder",
        "bandWidth": "İmza bant aralığı",
        "bandWidth_desc": "İmzaların dikey(y) ve yatay(x) eksene göre kapladığı bant aralığı değeri",
        "missingFile": "Lütfen ek kanıt gereksinimi için *.pdf dosyası seçiniz ",
        "missingKey": "Lütfen dosyaya ait publicKey'i yapıştırınız",
        "selectedFile": "Seçilen Dosya",
        "file": "Dosya (*.PDF)",
        "sign_could_not_read": "İmza okunamadı lütfen doğru format ve imza bilgisine ait dosya ile tekrar deneyiz",
        "sign_info_corrupted": "İmza bilgisi hatalı",
        "totalAngleTurn": "Dönüş hareketlerinin oluşturduğu açı değerlerinin toplamı",
        "totalAngleTurn_desc": "İmza içerisindeki dönüş hareketlerinin oluşturduğu açı değerleri toplamını belirtmektedir. Yukarı veya sağa dönüşler yapılarak atılan imzalarda artı değer, aşağı veya sola dönüş yapılarak atılan imzalarda eksi değer olarak belirtilmektedir. ",
        "angleVerticalTip": "Dikey uçlar arasındaki açı değeri",
        "angleVerticalTip_desc": "",
        "baTurnAngle": "Dönüş hareketlerinin imzanın üst çizgisine olan açı değeri",
        "baTurnAngle_desc": "Dönüş hareketlerinin imzanın üst çizgisine olan açı değerlerini belirtmektedir. Yukarı yönlü hareketler ile oluşturulan imza ise eksi değer, aşağı yönlü hareket ile oluşturulan imza ise artı değer olarak belirtilmektedir.",
        "wthRatio_1": "X ekseni genişliği / Y ekseni boyu",
        "wthRatio_1_desc": "İmzanın yatay(x) eksenindeki genişliğinin, dikey eksendeki (y) boyuna oranı",
        "features_graph": "Parametre Grafikleri",
        "last_hash_list": "Aynı imza için yapılan incelemeler",
        "last_hash_list_desc": "Yüklemeye çalıştığınız inceleme konusu imza daha önceden yüklenmiştir dilerseniz, önceki dosyalardan birini inceleyebilir veya incelemeye devam edebilirsiniz.",
        "continue_anyway": "Devam Et",
        "exportDate": "Rapor Oluşturma",
        "investigationDate": "Yazılıma Yükleme"
    }
});
export default strings;

