import {_isContains} from "../../Helpers";
import $ from 'jquery';

window.animation = null;
window.requestAnimFrame = null;
window.jQuery = $;
window.k = 100;
window.$ = $;
global.jQuery = $;
require('../js/jquery.flot');
global.colorIndex = 0;
let _this;
let stoped = 0;
let maxPoint = 0;
let plot;
let angle = 0;
export default class forensicLib {

    constructor() {
        this.job = null;
        _this = this;
        this.created = false;

    }

    static getAuthHeader() {
        return localStorage.getItem('_token_type') + " " + localStorage.getItem('_atoken');
    }

    static drawSignFromData(data, option, color, group, signs, hasReference, references, newId = undefined, currentIndex, activeSelections = null, context) {

        function clone(obj) {
            if (null == obj || "object" != typeof obj) return obj;
            var copy = obj.constructor();
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
            }
            return copy;
        }

        let graphName = "#xydata";

        if (newId !== undefined) {
            graphName = newId;
        }

        this.allSelected = signs;
        this.activeSelections = clone(signs);

        if (option !== null && option.length > 0) {
            if (newId === undefined) {
                option.map((o, i) => {
                    this.plotData(data, data, "#vardata-" + o, o, color, group, signs, hasReference, references, currentIndex, [], context);
                });
            } else {
                this.plotData(data, data, graphName, option, color, group, signs, hasReference, references, currentIndex, [], context);
            }
        } else {
            this.plotData(data, data, graphName, 'sign', color, group, signs, hasReference, references, currentIndex, [], context);
        }

    }

    static getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    static drawGraph(graphName, points, xmin, ymin, xmax, ymax, colors, option = 'sign') {

        // console.log(colors);

        this.points = points;

        var previousPoint = null;

        function showTooltip(lx, ly, contents, z, x, y) {
            $('<div id="tooltip">' + lx + ', ' + ly + '</div>').css({
                position: 'absolute',
                display: 'none',
                top: y - 30,
                left: x - 110,
                'font-weight': 'bold',
                border: '1px solid rgb(255, 221, 221)',
                padding: '2px',
                zIndex: 99999,
                'background-color': z,
                opacity: '0.8'
            }).appendTo("body").show();
        };

        function moveIdToTop(jsonarray, label) {
            jsonarray.push(jsonarray.splice(jsonarray.findIndex(v => v.label == label), 1)[0])
        }

        $(function () {
            var previousPoint = null;
            $(graphName).bind("plothover", function (event, pos, item) {


                if (item) {

                    if (previousPoint != item.datapoint) {
                        previousPoint = item.datapoint;

                        $("#tooltip").remove();
                        var x = item.datapoint[0],
                            y = item.datapoint[1] - item.datapoint[2];

                        //  console.log(item);
                        if (option === 'sign') {
                            showTooltip((item.datapoint[0] * window.k).toFixed(2), (item.datapoint[1] * window.k).toFixed(2), item.series.label, item.series.color, item.pageX, item.pageY);
                        } else {
                            showTooltip(item.datapoint[0], item.datapoint[1], item.series.label, item.series.color, item.pageX, item.pageY);
                        }
                        // showTooltip(item.pageX, item.pageY, item.series.label, item.series.color);
                    }
                } else {
                    $("#tooltip").remove();
                    previousPoint = null;
                }
            });

            $(graphName).bind("plotclick", function (event, pos, item) {

                if (item) {
                    item.seriesIndex = 100;

                    moveIdToTop(points, item.series.label)

                    // console.log(points);

                    plot = $.plot($(graphName), points, {
                        grow: {growings: [{stepMode: "maximum"}]},
                        yaxis: {max: ymax + ymax / 10, min: ymin - ymax / 10},
                        xaxis: {max: xmax + xmax / 10, min: xmin - xmax / 10, tickLength: 0},
                        grid: {
                            hoverable: true,
                            clickable: true,
                            borderWidth: 0,
                            aboveData: false,
                        },
                        colors: colors,
                        legend: {
                            hideable: true
                        }
                    });

                }

            });

            plot = $.plot($(graphName), points, {
                grow: {growings: [{stepMode: "maximum"}]},
                xaxis: {
                    autoscaleMargin: 0.02 * angle
                },
                grid: {
                    hoverable: true,
                    clickable: true,
                    borderWidth: 0,
                    aboveData: false,
                },
                colors: colors,
                legend: {
                    hideable: true
                },
                option: option
            });

        });


    }

    static heatMapColorforValue(value) {
        var h = (1.0 - value) * 240
        return "hsl(" + h + ", 100%, 50%)";
    }

    static rotate(x, y, xm, ym, a) {
        var cos = Math.cos,
            sin = Math.sin,

            a = a * Math.PI / 180,

            xr = (x - xm) * cos(a) - (y - ym) * sin(a)   + xm,
            yr = (x - xm) * sin(a) + (y - ym) * cos(a)   + ym;

        return [xr, yr];
    }

    static plotData(xdata, ydata, graphName, option, colors, group, signs, hasReference, references, currentIndex=0, penStatus = [], context) {

        // console.log(colors);

        if (option === null || (option !== 'show-view' && option !== 'show-value' && option !== 'show-animation' && option !== 'show-parameter')) {


            let points = [];
            let xmax = 0;
            let ymax = 0;
            let xmin = 100000;
            let ymin = 100000;
            let vmax = 0;
            let xAxis;
            let yAxis;
            let k = 100;
            _this = this;

            if (option === 'velocity') {
                xAxis = 't';
                yAxis = 'v';
            } else if (option === 'velocityX') {
                xAxis = 't';
                yAxis = 'vx';
            } else if (option === 'velocityY') {
                xAxis = 't';
                yAxis = 'vy';
            } else if (option === 'acceleration') {
                xAxis = 't';
                yAxis = 'acc';
            } else if (option === 'accelerationX') {
                xAxis = 't';
                yAxis = 'accx';
            } else if (option === 'accelerationY') {
                xAxis = 't';
                yAxis = 'accy';
            } else if (option === 'pressure') {
                xAxis = 't';
                yAxis = 'p';
            } else if (option === 'pendown') {
                xAxis = 't';
                yAxis = 'pd';
            } else if (option === 'xradio') {
                xAxis = 't';
                yAxis = 'x';
            } else if (option === 'yradio') {
                xAxis = 't';
                yAxis = 'y';
            } else if (option === 'azimuth') {
                xAxis = 't';
                yAxis = 'az';
            } else if (option === 'elevation') {
                xAxis = 't';
                yAxis = 'el';
            } else if (option === 'sign') {
                xAxis = 'x';
                yAxis = 'y';
            } else {
                xAxis = 't';
                yAxis = 'v';
            }

            let hasSign = false;
            let signInfo;


            // console.log(currentIndex);

            // console.log(xdata[0].data[currentIndex]);


            for (let i = 0; i < xdata.length; i++) {
                let subpoints = [];
                let penStatus = [];

                if (xdata[i].type === 'reference' && typeof xdata[i].data !== 'undefined' && hasReference === true) {
                    if (references !== null) {
                        signInfo = references.filter(function (item) {
                            return item.signId == xdata[i].signatureId
                        });
                    }

                    angle = 0;
                    let centerx = 0;
                    let centery = 0;

                    try {
                        for (let j = 0; j < xdata[i].data[currentIndex].detail[xAxis].length; j++) {

                            let lastX = xdata[i].data[currentIndex].detail[xAxis][j];
                            let lastY = ydata[i].data[currentIndex].detail[yAxis][j];

                            let rotated = forensicLib.rotate(lastX, lastY, 0, 0, angle);


                            if (rotated[0] > xmax) {
                                xmax = rotated[0];
                            }
                            if (rotated[1] > ymax) {
                                ymax = rotated[1];
                            }
                            if (rotated[0] < xmin) {
                                xmin = rotated[0];
                            }
                            if (rotated[1] < ymin) {
                                ymin = rotated[1];
                            }


                            penStatus.push(xdata[i].data[currentIndex].detail['pd'][j]);
                            subpoints.push([rotated[0], rotated[1]]);


                        }


                        var temp = {
                            data: subpoints,
                            pd: penStatus,
                            detail: xdata[i].data[currentIndex].detail,
                            colors: colors
                        };

                        if  (typeof xdata[i].data[currentIndex].hashValue !== 'undefined' && option === 'sign') {
                            temp['label'] = xdata[i].data[currentIndex].hashValue
                        } else {
                            temp['label'] = (k+1)*i
                        }

                        points.push(temp);

                    } catch (e) {
                        console.log(e)
                    }



                } else if (xdata[i].type === 'sample' && typeof xdata[i].data !== 'undefined') {

                    //     console.log('sample a girdi');

                    try {

                        for (let k = 0; k < xdata[i].data.length; k++) {

                            // console.log(xdata[i].data[k].signatureId);
                            let hasSign = false;
                            let signInfo;
                            if (signs !== null) {
                                signInfo = signs.filter(function (item) {
                                    return item.signId == xdata[i].data[k].signatureId
                                });
                            }
                            subpoints = [];
                            // console.log(xdata[i].data[k]);

                            if ((group === null && signs === null) || (group !== null && signs !== null && _isContains(signs, xdata[i].data[k].signatureId) === true && group.includes(signInfo[0].group))) {

                                for (let j = 0; j < xdata[i].data[k].detail[xAxis].length; j++) {




                                    if (xdata[i].data[k].detail[xAxis][j] > xmax) {
                                        xmax = xdata[i].data[k].detail[xAxis][j];
                                    }
                                    if (ydata[i].data[k].detail[yAxis][j] > ymax) {
                                        ymax = ydata[i].data[k].detail[yAxis][j];
                                    }
                                    if (xdata[i].data[k].detail[xAxis][j] < xmin) {
                                        xmin = xdata[i].data[k].detail[xAxis][j];
                                    }
                                    if (ydata[i].data[k].detail[yAxis][j] < ymin) {
                                        ymin = ydata[i].data[k].detail[yAxis][j];
                                    }

                                    penStatus.push(xdata[i].data[k].detail['pd'][j]);
                                    subpoints.push([xdata[i].data[k].detail[xAxis][j], ydata[i].data[k].detail[yAxis][j]]);

                                }

                                var temp = {
                                    data: subpoints,
                                    pd: penStatus,
                                    detail: xdata[i].data[currentIndex].detail,
                                    color: (typeof signInfo !== 'undefined' && signInfo.length > 0) ? signInfo[0].color : null,
                                    colors: colors,
                                };

                                if (typeof signInfo !== 'undefined' && signInfo.length > 0 && option === 'sign') {
                                    temp['label'] =  signInfo[0].hashValue
                                } else {
                                    temp['label'] = (k+1)*i
                                }

                                points.push(temp);


                            }

                        }
                    } catch (e) {
                        console.log(e)
                    }

                }



            }

            this.drawGraph(graphName, points, xmin, ymin, xmax, ymax, colors, option);

        }

    }

}
