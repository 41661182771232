import React, {Component} from 'react';
import customConstants from "../Globals";
import LeftMenu from "../components/LeftMenu";
import forensicLib from "../assets/js/forensicLib";
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-bootstrap/Modal';
import {FaPlay, FaStop, FaRedo, FaPause, FaCopy} from 'react-icons/fa';
import {saveAs} from 'file-saver';
import {Progress} from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Moment from 'react-moment';
import moment from 'moment';
import {_isContains, getVideoFromServer, hexToRGB, logout, shortCuts} from "../Helpers";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dropzone from 'react-dropzone';
import DatePicker from "react-datepicker";
import Loading from 'react-fullscreen-loading';
import {BigPlayButton, ControlBar, LoadingSpinner, PlaybackRateMenuButton, Player, Shortcut} from "video-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPauseCircle, faPlayCircle, faRedo, faSquareFull, faStepBackward} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

let _this;

export class Groups extends Component {

    constructor(props) {
        super(props);
        this.videoOuter = React.createRef();
        this.state = {
            sampleJson: [],
            show: false,
            currentSignGroupName: '',
            currentSignGroupIndex: '',
            optionType: [],
            currentAdded: [],
            showAddGroupModal: false,
            showAddSignModal: false,
            referenceJson: null,
            hideButton: false,
            tempSignsSingle: [],
            tempSigns: [
                {groupName: '', groupDate: '', description: '', data: [], type: 'sample'},
            ],
            currentHeight: 630,
            showType: customConstants.showTypes.VIEW,
            colors: customConstants.COLORS,
            currentVideo: [],
            showSinglePlayer: false,
            showCompareWindow: false,
            isPlayingAll: true,
            playedAll: false,
            sampleType: [],
            sampleSingleType: []
        };
        window.continueToDraw = true;
        window.playTimeout = 20;
        window.stoped = true;
        window.break = false;
        this.fileData = [];
        this.fileData['groups'] = [];
        this.expandOrCollapse = this.expandOrCollapse.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseGroup = this.handleCloseGroup.bind(this);
        this.handleCloseSign = this.handleCloseSign.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showModalGroup = this.showModalGroup.bind(this);
        this.showModalSign = this.showModalSign.bind(this);
        _this = this;
        this.player = [];
        this.completedVideo = 0;
        this.caseUUID = sessionStorage.getItem('responseCaseId');
    }

    componentDidMount() {
        this.doCommon();
    }

    handleChangeTypeSample = (event, i) => {
        let curentTypes = this.state.sampleType;
        curentTypes[i] = event.target.value;

        this.setState({sampleType: curentTypes}, () => {
            console.log(_this.state.sampleType);
        });
    }

    handleChangeTypeSampleSingle = (event, i) => {
        let curentTypes = this.state.sampleSingleType;
        curentTypes[i] = event.target.value;

        this.setState({sampleSingleType: curentTypes}, () => {
            console.log(_this.state.sampleType);
        });
    }

    getEvaluation = async (signatureId, groupId) => {

        return await fetch(customConstants.apiUrl + "/signature/" + signatureId.id + "/evaluate/" + groupId, {
            method: 'GET',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => response.json())
            .then(result => {
                return result;
            })
            .catch(err => {
                return -1;
            });
    }

    handleCloseGroup() {
        this.setState({showAddGroupModal: false});
    }

    handleCloseSign() {
        this.setState({showAddSignModal: false});
    }

    updateGroupName(v, isReference, i) {

        let currentName = this.state.tempSigns;
        currentName[i].groupName = v.target.value;
        this.setState({tempSigns: currentName}, () => {
            // console.log(this.state.sampleSigns);
        });

    }

    updateDescription(v, isReference, i) {

        let currentName = this.state.tempSigns;
        currentName[i].description = v.target.value;
        this.setState({tempSigns: currentName}, () => {
            // console.log(this.state.sampleSigns);
        });

    }

    updateDate(v, isReference, i) {

        let currentName = this.state.tempSigns;
        currentName[i].groupDate = v;
        this.setState({tempSigns: currentName}, () => {
            // console.log(this.state.sampleSigns);
        });

    }

    uploadSigFile = (file, i) => {

        this.setState({loading: true, uploading: true});
        let files = Array.from(file);
        _this.setState({loading: true});
        files.map(function (f) {

            const data = new FormData();
            const allowedExtensions = /(\.sig)$/i;

            if (_this.state.tempSigns.length >= 10) {
                _this.setState({loading: false, uploading: false});
                return NotificationManager.warning(global.strings.max_two_file);
            } else if (typeof f !== 'undefined' && !allowedExtensions.exec(f.name)) {
                _this.setState({loading: false, uploading: false});
                return NotificationManager.error(global.strings.upload_file_type_error);
            } else if (typeof f !== 'undefined' && allowedExtensions.exec(f.name)) {

                _this.setState({loading: true});
                data.append('signature', f);
                if (typeof _this.fileData['groups'][i] === 'undefined') {
                    _this.fileData['groups'][i] = [];
                }

                _this.fileData['groups'][i].push({filename: f.name, file: f});
                _this.sampleUploaded = true;
                _this.setState({loading: false, uploading: false});
                return NotificationManager.success(global.strings.upload_success_group);
            }
        });

    }

    uploadSigFileSingle = (file) => {

        this.setState({loading: true, uploading: true});
        let files = Array.from(file);
        this.setState({loading: true});

        files.map(function (f) {
            const data = new FormData();
            const allowedExtensions = /(\.sig)$/i;
            if (_this.state.tempSignsSingle.length >= 10) {
                return NotificationManager.warning(global.strings.max_two_file);
            } else if (typeof f !== 'undefined' && !allowedExtensions.exec(f.name)) {
                return NotificationManager.error(global.strings.upload_file_type_error);
            } else if (typeof f !== 'undefined' && allowedExtensions.exec(f.name)) {

                data.append('signature', f);
                _this.setState({uploading: true, loading: false});

                if (typeof _this.fileData['groups'][_this.state.currentSignGroupIndex] === 'undefined') {
                    _this.fileData['groups'][_this.state.currentSignGroupIndex] = [];
                }

                _this.fileData['groups'][_this.state.currentSignGroupIndex].push({filename: f.name, file: f});

                let currentAdded = _this.state.currentAdded;

                currentAdded.push({filename: f.name, file: f});
                _this.setState({currentAdded: currentAdded}, () => {
                    console.log(_this.state.currentAdded);
                });
                return NotificationManager.success(global.strings.upload_success_sign);

            }
        });
    }

    showModalGroup() {

        _this.fileData['groups'] = [];
        _this.currentGroupAddIndex = 0;

        const temp = [];
        temp.push({groupName: '', groupDate: '', description: '', type: 'sample'});

        this.setState({
            showAddGroupModal: true,
            tempSigns: temp
        });
    }

    showModalSign(k) {

        this.setState({
            showAddSignModal: true,
            currentSignGroupIndex: k,
            tempSignsSingle: [],
            currentSignGroupId: this.state.sampleJson[k].id,
            currentSignGroupName: this.state.sampleJson[k].groupName,
            currentAdded: []
        });
        _this.fileData['groups'] = [];
    }

    showModal = async (val, type, grp, groupId, index) => {

        let samples = sessionStorage.getItem('samples');
        samples = JSON.parse(samples);

        const currentGroup = samples.filter(function (item) {
            return item.id === groupId
        });

        //console.log(currentGroup[0]);

        if (typeof currentGroup[0].data === 'undefined' || currentGroup[0].data.length !== currentGroup[0].signatures.length) {
            await this.getSampleValues(currentGroup[0], index, true);
        }


        const updatedSample = JSON.parse(sessionStorage.getItem('samples'));
        let currentSign;

        try {

            currentSign = updatedSample[index].data.filter(function (item) {
                return item.signatureId === val
            });

            currentSign[0].data = [];
            currentSign[0].type = 'sample';
            currentSign[0].data.push({
                detail: currentSign[0].detail,
                signatureId: currentSign[0].signatureId,
                hashValue: currentSign[0].hashValue
            });

            this.setState({
                show: (type !== customConstants.showTypes.ANIMATION) ? true : false,
                currentSignId: currentSign[0].signatureId,
                currentHashValue: currentSign[0].hashValue,
                currentSignGroup: grp,
                showType: type,
                loading: false
            }, () => {

                let widthShow;
                let heightShow;

                switch (type) {
                    case customConstants.showTypes.VIEW :

                        widthShow = _this.xydata.clientWidth;
                        heightShow = (widthShow / 5) * 3;

                        _this.setState({currentHeight: heightShow}, () => {
                            forensicLib.drawSignFromData(currentSign, null, this.state.colors, null, null);
                        });


                        break;
                    case customConstants.showTypes.ANIMATION :

                        _this.showSinglePlayerModal(currentSign);

                        break;
                    case customConstants.showTypes.PARAMETER :

                        this.setState({currentSigns: currentSign}, () => {
                            forensicLib.drawSignFromData(this.state.currentSigns, this.state.optionType, this.state.colors, null, null);
                        });

                        break;
                    case customConstants.showTypes.VALUE :

                        // console.log(currentSign[0]);

                        this.setState({currentSignsValue: currentSign[0]}, () => {
                            //  console.log(this.state.currentSigns);
                        });

                        break;

                    case customConstants.showTypes.COMPARE :

                        widthShow = _this.xydataRef.clientWidth;
                        heightShow = (widthShow / 5) * 3;

                        // document.getElementsByClassName('flot-data').style.height = heightShow;

                        _this.setState({currentHeight: heightShow}, () => {
                            forensicLib.drawSignFromData(_this.state.referenceJson, null, [_this.state.colors[0]], null, null, true, null, '#xydataRef', _this.state.currentReferenceIndex);
                            forensicLib.drawSignFromData(currentSign, null, [_this.state.colors[1]], null, null, null, null, '#xydataCompare', _this.state.currentReferenceIndex);
                        });

                        break;

                }

            });

        } catch (e) {
            return NotificationManager.error(global.strings.sign_info_corrupted);
            this.setState({loading: false});
            console.log(e);
        }

    }

    commonPlay() {

        if (this.state.currentDoc.length === 2 && this.state.compare === true) {
            let maxPoint;
            if (this.state.currentDoc[0].detail.t.length > this.state.currentDoc[1].detail.t.length) {
                maxPoint = this.state.currentDoc[0].detail.t.length;
            } else {
                maxPoint = this.state.currentDoc[1].detail.t.length;
            }
            // console.log(maxPoint);
            forensicLib.playGraphFaster([this.state.currentDoc[0]], this, 'xydataplay0', maxPoint, this.videoOuter.current.offsetWidth, this.state.currentHeight);
            forensicLib.playGraphFaster([this.state.currentDoc[1]], this, 'xydataplay1', maxPoint, this.videoOuter.current.offsetWidth, this.state.currentHeight);
        } else {
            // console.log(this.videoOuter.current.offsetWidth);
            forensicLib.playGraphFaster([this.state.currentDoc], this, 'xydataplay0', null, this.videoOuter.current.offsetWidth, this.state.currentHeight);
        }

    }

    doCommon = async () => {

        if (sessionStorage.getItem('refIndex') !== null && typeof sessionStorage.getItem('refIndex') != 'undefined') {
            this.setState({currentReferenceIndex: parseInt(sessionStorage.getItem('refIndex')) || 0});
        } else {
            this.setState({currentReferenceIndex: 0});
            sessionStorage.setItem('refIndex', 0);
        }


        let sampleJson;
        let referenceJson;
        let sampleFile = sessionStorage.getItem('samples');
        let referenceFile = sessionStorage.getItem('reference');

        if (sampleFile !== null) {
            sampleJson = JSON.parse(sampleFile);
        }

        if (referenceFile !== null) {
            referenceJson = JSON.parse(referenceFile);
        }

        if (typeof sampleJson === 'undefined') {
            sampleJson = [];
        }

        this.setState({referenceJson: referenceJson, currentReferenceIndex: sessionStorage.getItem('refIndex')}, () => {
            // console.log(this.state.referenceJson);
        });

        this.setState({sampleJson: sampleJson}, async () => {
            // console.log(this.state.sampleJson);
            await this.updateEvaluation();
        });

        this.setState({inputValue: window.playTimeout});

        let currentOptions = this.state.optionType;
        this.setState({optionType: currentOptions});

    }

    updateEvaluation = async () => {
        let sampleJson = this.state.sampleJson;
        // console.log(sampleJson);
        // console.log(_this.state.referenceJson);
        if (sampleJson !== null && sampleJson.length > 0) {
            return await Promise.all(sampleJson.map(async function (item, k) {

                if (typeof item.signatures !== 'undefined' && item.signatures.length > 0) {

                    if (typeof _this.state.referenceJson[0].signatures !== 'undefined' && _this.state.referenceJson[0].signatures.length > 0) {
                        const ev = await _this.getEvaluation(_this.state.referenceJson[0].signatures[0], item.id);

                        if (typeof ev !== 'undefined' && typeof ev.probability !== 'undefined') {
                            sampleJson[k].evaluate = parseFloat(ev.probability.toFixed(2));
                        } else {
                            if (typeof ev.message !== 'undefined' && ev.message.includes('yeterli sayıda imza')) {
                                sampleJson[k].evaluate = -1;
                            } else {
                                sampleJson[k].evaluate = -2;
                            }
                        }


                        _this.setState({sampleJson: sampleJson}, () => {
                            // console.log(_this.state.sampleJson);
                            sessionStorage.setItem('samples', JSON.stringify(sampleJson));
                        });
                    }

                }
            }));
        } else {
            return false;
        }

    }

    updateTimeout = (e) => {
        this.setState({inputValue: e.target.value}, () => {
            window.playTimeout = this.state.inputValue;
        });
    };

    stopAnimation() {
        cancelAnimationFrame(window.animation);
        clearTimeout(window.drawTimeout);
    }

    pauseAnimation(toWhat) {
        this.setState({isPlaying: toWhat}, () => {
            window.continueToDraw = toWhat;
        });
    }

    handleClose() {
        this.setState({show: false, stoped: true}, () => {
            window.break = true;
            clearTimeout(forensicLib.job);
            cancelAnimationFrame(window.animation);
        });
    }

    expandOrCollapse(incoming) {

        let currentClasses = this.state.sampleJson;

        let current = currentClasses[incoming].expanded;

        currentClasses = currentClasses.map(function (v, k) {
            v.expanded = false;
            return v;
        });


        if ((typeof current === 'undefined' || current === false)) {
            currentClasses[incoming].expanded = true;
        } else {
            currentClasses[incoming].expanded = false;
        }

        // console.log(currentClasses);

        setTimeout(function () {
            _this.setState({sampleJson: currentClasses}, () => {
            });
        }, 100);

    }

    onChangeDetailType = label => {


        let currentOptions = this.state.optionType;
        let selectedSignInfo = null;
        if (currentOptions.indexOf(label) !== -1) {
            currentOptions.splice(currentOptions.indexOf(label), 1);
        } else {
            currentOptions.push(label);
        }
        this.setState({optionType: currentOptions}, () => {
            forensicLib.drawSignFromData(this.state.currentSigns, this.state.optionType, this.state.colors, null, null);
        });
    }

    addSignatureToGroup = async (files, caseId, groupId, signatureType = 'techsign') => {

        this.setState({loading: true});
        const data = new FormData();
        data.append('signature', files.file);


        return await fetch(customConstants.apiUrl + "/cases/" + caseId + "/group/" + groupId + "/signature/upload/" + signatureType, {
            method: 'POST',
            headers: {
                'Authorization': forensicLib.getAuthHeader(),
                'Accept': 'application/json'
            },
            body: data
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                return result;
            }).catch(err => {
                this.setState({loading: false, hideButton: false});
                // this.props.history.push('/dashboard');
            });

    }

    completeAddingSign = async () => {

        let currentSign = this.state.sampleJson;
        // console.log(_this.fileData['groups']);

        if (typeof this.fileData['groups'][this.state.currentSignGroupIndex] !== 'undefined' && this.fileData['groups'][this.state.currentSignGroupIndex].length > 0) {
            this.setState({loading: true});
            return this.fileData['groups'][this.state.currentSignGroupIndex].map(async function (item, j) {

                    const newSignId = await _this.addSignatureToGroup(item, _this.caseUUID, _this.state.currentSignGroupId, (typeof _this.state.sampleSingleType[_this.state.currentSignGroupIndex] !== 'undefined') ? _this.state.sampleSingleType[_this.state.currentSignGroupIndex] : 'techsign');

                    if (typeof newSignId !== 'undefined' && typeof newSignId.id !== 'undefined' && newSignId.id !== null) {

                        currentSign[_this.state.currentSignGroupIndex].signatures.push(newSignId);

                        _this.setState({showAddSignModal: false, loading: false, sampleJson: currentSign}, () => {
                            sessionStorage.setItem('samples', JSON.stringify(_this.state.sampleJson));
                            _this.updateEvaluation();
                        });

                    } else {
                        let currentAdded = _this.state.currentAdded;
                        currentAdded.splice(j, 1);
                        _this.fileData['groups'][_this.state.currentSignGroupIndex].splice(j, 1);
                        _this.setState({loading: false,currentAdded:currentAdded});
                        return NotificationManager.error(global.strings.sign_could_not_read + ' ' + _this.state.currentSignGroupId,'',15000);
                    }

                }
            );
        } else {
            return NotificationManager.warning(global.strings.addSignToGroupFirst);
        }


    }

    removeSign(val, k, key, groupId) {

        fetch(customConstants.apiUrl + "/cases/" + this.caseUUID + "/group/" + groupId + "/signature/" + val, {
            method: 'DELETE',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(result => {

                let currentSign = _this.state.sampleJson;
                currentSign[k].signatures = currentSign[k].signatures.filter(function (v, j) {
                    if (j !== key) {
                        return v;
                    }
                });

                _this.setState({sampleJson: currentSign}, () => {
                    sessionStorage.setItem('samples', JSON.stringify(_this.state.sampleJson));
                    _this.updateEvaluation();
                });

            })
            .catch(err => {
                //this.props.history.push('/');
            });

    }

    removeGroup(k, groupId) {

        fetch(customConstants.apiUrl + "/cases/" + this.caseUUID + "/group/" + groupId, {
            method: 'DELETE',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(result => {

                let currentSign = this.state.sampleJson;

                currentSign = currentSign.filter(function (v, j) {
                    if (j !== k) {
                        return v;
                    }
                });

                // console.log(currentSign);

                this.setState({sampleJson: currentSign}, () => {
                    sessionStorage.setItem('samples', JSON.stringify(this.state.sampleJson));
                });

            })
            .catch(err => {
                this.props.history.push('/');
            });


    }

    removeConfirmation(val, k, key, groupId) {
        confirmAlert({
            title: global.strings.removeSignTitle,
            message: global.strings.removeSignDesc,
            buttons: [
                {
                    label: global.strings.delete,
                    onClick: () => this.removeSign(val, k, key, groupId)
                },
                {
                    label: global.strings.no,
                    onClick: () => {
                    }
                }
            ]
        });
    }

    removeGroupConfirmation(k, groupId) {
        confirmAlert({
            title: global.strings.removeGroupTitle,
            message: global.strings.removeGroupDesc,
            buttons: [
                {
                    label: global.strings.delete,
                    onClick: () => this.removeGroup(k, groupId)
                },
                {
                    label: global.strings.no,
                    onClick: () => {
                    }
                }
            ]
        });
    }

    getSampleValues = async (group, i, force) => {

        if (typeof group.data === 'undefined' || force == true) {

            let groups = this.state.sampleJson;
            let lastRequestJson = {};
            lastRequestJson.signatures = [];

            group.signatures.map(function (v, k) {
                lastRequestJson.signatures.push(v.id);
            });

            await fetch(customConstants.apiUrl + "/signature", {
                method: 'POST',
                headers: {
                    Authorization: forensicLib.getAuthHeader(),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(lastRequestJson)
            })
                .then(response => response.json())
                .then(result => {
                    //  console.log(result);

                    groups[i].data = result;
                    groups[i].type = 'sample';
                    sessionStorage.setItem('samples', JSON.stringify(groups));

                })
                .catch(err => {
                    console.log(err);
                    this.setState({loading: false});
                    // return NotificationManager.error(err);
                });
        }

    }

    createGroup = async (item) => {

        if (typeof item.groupDate !== 'undefined' || item.groupDate !== null || item.groupDate !== '') {

            item.groupDate = new Date();
        }

        const data = {
            "groupName": item.groupName,
            "groupDate": moment(item.groupDate).toISOString().slice(0, -5) + "Z",
            "description": item.description,
            "status": "sample"
        };


        return await fetch(customConstants.apiUrl + "/cases/" + _this.caseUUID + "/group", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch(err => {
                //  return NotificationManager.error(err);
            });

    }

    createSampleGroup = async (lastItem) => {

        await Promise.all(lastItem.map(async function (inner, k) {

            const groupId = await _this.createGroup(inner);

            lastItem[k].id = groupId;
            // console.log(_this.fileData['groups']);
            return await Promise.all(_this.fileData['groups'][k].map(async function (innersign, j) {
                const incomingId = await _this.addSignatureToGroup(innersign, _this.caseUUID, lastItem[k].id, (typeof _this.state.sampleType[k] !== 'undefined') ? _this.state.sampleType[k] : 'techsign');

                if (typeof lastItem[k].signatures === 'undefined') {
                    lastItem[k].signatures = [];
                }

                if (typeof incomingId !== 'undefined' && typeof incomingId.id !== 'undefined'  && incomingId.id !== null){

                    lastItem[k].signatures.push(incomingId);

                } else {
                    _this.fileData['groups'][k].splice(j, 1);
                    return NotificationManager.error(global.strings.sign_could_not_read + ' ' + lastItem[k].id,'',15000);
                }

            }));

            this.setState({tempSigns: lastItem});

        }));

    }

    completeAdding = async () => {

        let tmp = this.state.tempSigns;

        if (tmp !== 'undefined' && tmp.length > 0 && _this.fileData['groups'].length > 0) {
            this.setState({hideButton: true});
            await _this.createSampleGroup(tmp);

            tmp = this.state.tempSigns;

            const lastTmp = this.state.sampleJson.concat(tmp);
            this.setState({
                sampleJson: lastTmp,
                showAddGroupModal: false,
                hideButton: false,
                loading: false
            }, () => {
                _this.updateEvaluation();
                sessionStorage.setItem('samples', JSON.stringify(lastTmp));
            });
        } else {
            return NotificationManager.warning(global.strings.addSignToGroupFirst);
        }

    }

    addOtherSigns() {
        let currentSigns = this.state.tempSigns;
        currentSigns.push({groupName: '', groupDate: '', description: '', sampleSingleType: []});
        this.setState({tempSigns: currentSigns});
    }

    showSinglePlayerModal = async (item) => {
        let currentVideo = this.state.currentVideo;

        currentVideo[item[0].signatureId] = await getVideoFromServer(this, [item[0].signatureId], (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? [hexToRGB(customConstants.COLORS, true)] : [[hexToRGB(this.state.colors[0])]]);
        if (currentVideo[item[0].signatureId]) {
            this.setState({show: true, currentVideo: currentVideo});
        }
    }

    checkIfAllVideoEnded(must, current) {
        if (must === current) {
            this.setState({isPlayingAll: false});
        }
    }

    showCompareAnimation = async (item) => {


        this.setState({show: false, loading: true});
        let currentVideo = this.state.currentVideo;

        currentVideo[_this.state.referenceJson[0]['signatures'][0]] = await getVideoFromServer(this, [_this.state.referenceJson[0]['signatures'][0].id], (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? [hexToRGB(customConstants.COLORS, true)] : [[hexToRGB(this.state.colors[0])]]);
        currentVideo[this.state.currentSignId] = await getVideoFromServer(this, [this.state.currentSignId], (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? [hexToRGB(customConstants.COLORS, true)] : [[hexToRGB(this.state.colors[1])]]);
        if (currentVideo[this.state.currentSignId]) {
            this.setState({showCompareWindow: true, currentVideo: currentVideo, loading: false}, () => {
                $("video").bind("ended", function () {
                    _this.checkIfAllVideoEnded(2, ++_this.completedVideo);
                });
            });
        }

    }

    handleCloseComparePlayer = () => {
        this.setState({showCompareWindow: false});
    }

    playAllVideos() {
        _this.completedVideo = 0;
        this.setState({isPlayingAll: true, playedAll: true}, () => {

        });
        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    v.play();
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }

    stopAllVideos() {
        this.setState({isPlayingAll: false});
        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    v.pause();
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }

    loadAllVideos() {
        this.setState({isPlayingAll: false, playedAll: false});
        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    const {player} = v.getState();
                    v.seek(0);
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }

    forwardVideo(forward) {

        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    const {player} = v.getState();
                    v.seek(player.currentTime + forward);
                } catch (e) {
                    console.log(e);
                }
            }
        })

    }

    render() {

        const currentOptions = this.state.optionType;

        return (
            <div>
                <Loading loading={this.state.loading} background="rgba(255,255,255,.7)" loaderColor="#0D386B"/>
                <div className="navbar navbar-expand navbar-light bg-white topbar  static-top shadow" id={'topBarMenu'}>

                    <div onClick={() => logout(this)} lang="en" className="logout-form-btn">
                        {global.strings.logout}
                    </div>

                </div>
                <div id="wrapper">

                    <LeftMenu enableAdminMenu={true}/>

                    <div className={'main-container'}>
                        <div className={'row'}>

                            <div style={{'backgroundColor': '#fff', 'margin': 0, 'width': '100%'}}>
                                <div className={'col-12'} style={{'display': 'flow-root'}}>
                                <span
                                    className={'dashboard-header'}>{global.strings.signGroups.toUpperCase()}</span>
                                    {/*<span className={'print-graph'}> <img width={16} height={16}*/}
                                    {/*src={'./assets/images/print.svg'}/> {global.strings.printGraph}</span>*/}
                                </div>
                            </div>
                        </div>

                        <Modal size={'lg'} show={this.state.showCompareWindow} onHide={this.handleCloseComparePlayer}>
                            <Modal.Header closeButton>
                                <Modal.Title class={'sign-animation-modal-header'}>
                                    <FontAwesomeIcon size={'1x'}
                                                     color={this.state.colors[0]}
                                                     icon={faSquareFull}/> {(this.state.referenceJson !== null && this.state.referenceJson[0]) ? this.state.referenceJson[0].id : ''} &nbsp;
                                    <FontAwesomeIcon
                                        size={'1x'}
                                        color={this.state.colors[1]} icon={faSquareFull}/> {this.state.currentHashValue}
                                    <br/>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className={'row'}>
                                    <div className={'col-6'}>
                                        <div style={{
                                            border: '3px solid ' + this.state.colors[0]
                                        }}>
                                            <Player
                                                ref={player => {
                                                    this.player[0] = player;
                                                }}
                                                autoPlay={true}
                                                playsInline
                                                muted={true}

                                            >
                                                <source
                                                    src={(this.state.currentVideo !== null && this.state.referenceJson !== null && typeof this.state.currentVideo[this.state.referenceJson[0]['signatures'][0]] !== 'undefined') ? this.state.currentVideo[this.state.referenceJson[0]['signatures'][0]] : null}
                                                    type={'video/mp4'}/>
                                                <BigPlayButton
                                                    position="center"/>
                                                <LoadingSpinner/>
                                                <ControlBar autoHide={false}
                                                            autoHideTime={3}>
                                                    <PlaybackRateMenuButton
                                                        rates={[5, 2, 1, 0.5, 0.1]}/>
                                                </ControlBar>
                                                <Shortcut clickable={false}
                                                          shortcuts={shortCuts}/>
                                            </Player>
                                        </div>
                                    </div>
                                    <div className={'col-6'}>
                                        <div style={{
                                            border: '3px solid ' + this.state.colors[1]
                                        }}>
                                            <Player
                                                ref={player => {
                                                    this.player[1] = player;
                                                }}
                                                autoPlay={true}
                                                playsInline
                                                muted={true}

                                            >
                                                <source
                                                    src={(this.state.currentVideo !== null && typeof this.state.currentVideo[this.state.currentSignId] !== 'undefined') ? this.state.currentVideo[this.state.currentSignId] : null}
                                                    type={'video/mp4'}/>
                                                <BigPlayButton
                                                    position="center"/>
                                                <LoadingSpinner/>
                                                <ControlBar autoHide={false}
                                                            autoHideTime={3}>
                                                    <PlaybackRateMenuButton
                                                        rates={[5, 2, 1, 0.5, 0.1]}/>
                                                </ControlBar>
                                                <Shortcut clickable={false}
                                                          shortcuts={shortCuts}/>
                                            </Player>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div div className={'control-all-outer'} style={{marginTop: 15}}>
                                        <div className={'control-all'}>
                                            {(this.state.isPlayingAll === false) ?
                                                <FontAwesomeIcon
                                                    size={'2x'}
                                                    onClick={() => this.playAllVideos()}
                                                    icon={faPlayCircle}/> :
                                                <FontAwesomeIcon
                                                    size={'2x'}
                                                    onClick={() => this.stopAllVideos()}
                                                    icon={faPauseCircle}/>
                                            }
                                            {(this.state.playedAll === true) ?
                                                <FontAwesomeIcon
                                                    size={'2x'}
                                                    onClick={() => this.loadAllVideos()}
                                                    icon={faStepBackward}/> :
                                                null
                                            }
                                            {(this.state.playedAll === true) ?
                                                <div style={{
                                                    display: 'inline-table',
                                                    position: 'relative'
                                                }}>
                                                    <div
                                                        className={'control-all-button-badge'}>5sn
                                                    </div>
                                                    <FontAwesomeIcon
                                                        size={'2x'}
                                                        style={{transform: 'scale(-1,1)'}}
                                                        onClick={() => this.forwardVideo(-5)}
                                                        icon={faRedo}/></div> :
                                                null
                                            }
                                            {(this.state.playedAll === true) ?
                                                <div style={{
                                                    display: 'inline-table',
                                                    position: 'relative'
                                                }}>
                                                    <div
                                                        className={'control-all-button-badge'}>5sn
                                                    </div>
                                                    <FontAwesomeIcon
                                                        size={'2x'}
                                                        onClick={() => this.forwardVideo(5)}
                                                        icon={faRedo}/></div> :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>

                            </Modal.Body>

                        </Modal>

                        <div className={''}>
                            <div className={'col-12 '}>
                                <div
                                    onClick={this.showModalGroup}
                                    className={'add-sign-group'}>
                                    <span>
                                        {global.strings.addSignGroup} <img
                                        width={16} src={'../assets/images/plus.svg'}/>
                                    </span>
                                </div>
                            </div>
                            <div className={'col-12'}>

                                {(this.state.sampleJson && this.state.sampleJson !== null && this.state.sampleJson.length > 0 && this.state.sampleJson[0].id !== null && this.state.sampleJson[0].id !== '') ?

                                    this.state.sampleJson.map(function (v, k) {

                                        return <div className={'standard-box'} key={'sign-groups' + k}>
                                            <div className={'expand-group'}>
                                                <img onClick={() => _this.expandOrCollapse(k)} width={14}
                                                     src={(typeof v.expanded === 'undefined' || v.expanded !== true) ? '../assets/images/expand-button.svg' : '../assets/images/expand-arrow.svg'}/>
                                            </div>
                                            <div className={'row sign-groups'}>
                                                <div className={'col-3'}>
                                                    <div>{global.strings.signGroup + ' ' + (k + 1)} </div>
                                                    <div className={'group-name'}>{v.groupName} </div>
                                                    <div className={'date'}><Moment
                                                        format="DD.MM.YYYY">{v.groupDate}</Moment>
                                                    </div>
                                                </div>
                                                <div className={'col-2'}>{v.description}</div>
                                                <div className={'col-4'}>
                                                    <div className={'row'}>
                                                        <div className={'col-3'}>
                                                            <div className={'similar-percent'}>
                                                                <Progress
                                                                    type="circle"
                                                                    width={65}
                                                                    percent={(v.evaluate === -1) ? 0 : v.evaluate}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={'col-9'}>
                                                            <div>
                                                                {global.strings.referenceMatchScore}
                                                            </div>
                                                            <div className={'group-name'}>
                                                                {(v.evaluate === -1) ?
                                                                    <div
                                                                        style={{fontSize: 10}}>{global.strings.need_four_sign_to_calvculate}</div> :
                                                                    (v.evaluate === -2) ?
                                                                        <div
                                                                            style={{fontSize: 10}}>{global.strings.signs_are_not_equal_for}</div>
                                                                        :
                                                                        v.evaluate + '%'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-3'}>
                                                    <div className={'row'}>
                                                        <div className={'col-4'}>
                                                            <div className={'similar-percent'}>
                                                                <img src={'../assets/images/download.svg'}/>
                                                            </div>
                                                        </div>
                                                        <div className={'col-8'}>
                                                            <div className={'removeGroup'}>
                                                                <img
                                                                    onClick={() => _this.removeGroupConfirmation(k, v.id)}
                                                                    style={{'cursor': 'pointer'}}
                                                                    width={16} src={'../assets/images/trash.svg'}/>
                                                            </div>
                                                            <div>
                                                                {global.strings.downloadSignGroup}
                                                            </div>
                                                            <div className={'group-name'}>
                                                                {v.groupName + '.zip'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {(v.expanded === true) ?
                                                <div className={'signDetail'}>
                                                    <div className={'row'}>

                                                        <div style={{
                                                            'backgroundColor': '#fff',
                                                            'margin': 0,
                                                            'width': '100%'
                                                        }}>
                                                            <div className={'col-12'} style={{'display': 'flow-root'}}>
                                <span
                                    className={'dashboard-header'}
                                    style={{'color': '#5b5b5b'}}>{global.strings.groupDetail.toUpperCase()} ({(v.signatures) ? v.signatures.length : 0})</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className={'table table-groups'}>
                                                        <thead>
                                                        <tr>

                                                            <th scope="col"> {global.strings.signatureId}</th>
                                                            <th scope="col"> {global.strings.review}</th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"
                                                                style={{'width': '20px', 'cursor': 'pointer'}}>
                                                                <img width={16}
                                                                     onClick={() => _this.showModalSign(k)}
                                                                     src={'../assets/images/plus.svg'}/>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {(v.signatures && v.signatures.length > 0) ?

                                                            v.signatures.map(function (val, key) {

                                                                return <tr key={'grp-elements' + key}
                                                                           className={'grp-elements'}>
                                                                    <td style={{'width': '350px'}}>{val.hashValue}</td>
                                                                    <td><span
                                                                        onClick={() => _this.showModal(val.id, customConstants.showTypes.VIEW, v.groupName, v.id, k)}
                                                                        className="badge badge-table view badge-primary">{global.strings.view}</span>
                                                                    </td>
                                                                    <td><span
                                                                        onClick={() => _this.showModal(val.id, customConstants.showTypes.ANIMATION, v.groupName, v.id, k)}
                                                                        className="badge badge-table animation badge-primary">{global.strings.animation}</span>
                                                                    </td>
                                                                    <td><span
                                                                        onClick={() => _this.showModal(val.id, customConstants.showTypes.PARAMETER, v.groupName, v.id, k)}
                                                                        className="badge badge-table parameter badge-primary">{global.strings.parameter}</span>
                                                                    </td>
                                                                    <td><span
                                                                        onClick={() => _this.showModal(val.id, customConstants.showTypes.VALUE, v.groupName, v.id, k)}
                                                                        className="badge badge-table values badge-primary">{global.strings.values}</span>
                                                                    </td>
                                                                    <td><span
                                                                        onClick={() => _this.showModal(val.id, customConstants.showTypes.COMPARE, v.groupName, v.id, k)}
                                                                        className="badge badge-table compare badge-primary">{global.strings.compareWithRerence}</span>
                                                                    </td>
                                                                    <td><img
                                                                        onClick={() => _this.removeConfirmation(val.id, k, key, v.id)}
                                                                        style={{'cursor': 'pointer'}}
                                                                        width={16} src={'../assets/images/trash.svg'}/>
                                                                    </td>
                                                                </tr>
                                                            })
                                                            : null}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                : null}
                                        </div>

                                    }) : null}

                            </div>
                        </div>
                        <Modal size={'lg'} show={this.state.show} onHide={this.handleClose}>

                            <Modal.Body>

                                {(this.state.showType !== customConstants.showTypes.ANIMATION && this.state.showType !== customConstants.showTypes.COMPARE) ?
                                    <div>
                                        <div style={{'fontSize': '14px'}}>
                                                    <span style={{
                                                        'fontWeight': 'bold',
                                                        'color': '#353535',
                                                        'paddingLeft': '10px'
                                                    }}>{global.strings.signatureId.toUpperCase()}:</span>{this.state.currentHashValue}
                                        </div>
                                        <div style={{'fontSize': '14px'}}>
                                                    <span style={{
                                                        'fontWeight': 'bold',
                                                        'color': '#353535',
                                                        'paddingLeft': '10px'
                                                    }}>{global.strings.signGroup}:</span> {this.state.currentSignGroup}
                                        </div>
                                    </div> : null}

                                {(this.state.showType === customConstants.showTypes.VIEW) ?
                                    <div className={'analyze'}>
                                        <div className="row">

                                            <div className="col-12">
                                                <h6 className={'graph-title'}
                                                    style={{'fontSize': '18px'}}>{global.strings.signView}</h6>
                                                <div className={'graph-desc'}>{global.strings.selectedSignView}</div>
                                                {(this.state.sampleJson.length > 0) ?
                                                    <div>
                                                        <div className={'results-graph'}>
                                                            <div id="xydata"
                                                                 ref={(divElement) => this.xydata = divElement}
                                                                 style={{height: this.state.currentHeight}}></div>
                                                            <div id="xPos">X (cm)</div>
                                                            <div id="yPos">Y (cm)</div>
                                                        </div>

                                                    </div>
                                                    : null}

                                            </div>

                                        </div>

                                    </div>
                                    : null}

                                {(this.state.showType === customConstants.showTypes.COMPARE) ?
                                    <div className={'analyze'}>
                                        <div className="row">

                                            <div className="col-12">
                                                <h6 className={'graph-title'}
                                                    style={{'fontSize': '18px'}}>{global.strings.signView} | &nbsp;
                                                    <FontAwesomeIcon size={'1x'}
                                                                     color={'#0d386b'}
                                                                     icon={faPlayCircle}/> &nbsp;<a
                                                        className={'playButton'}
                                                        onClick={() => this.showCompareAnimation()}>{global.strings.playAnimation}</a>
                                                </h6>
                                                <div className={'graph-desc'}>{global.strings.compareSigns}</div>
                                                {(this.state.sampleJson.length > 0) ?
                                                    <div className={'row'}>
                                                        <div className={'col-6'}>
                                                            <div className={'results-graph'}>
                                                                <div id="xydataRef"
                                                                     ref={(divElement) => this.xydataRef = divElement}
                                                                     style={{height: this.state.currentHeight}}></div>
                                                                <div id="xPos">X (cm)</div>
                                                                <div id="yPos">Y (cm)</div>
                                                            </div>

                                                        </div>
                                                        <div className={'col-6'}>
                                                            <div className={'results-graph'}>
                                                                <div id="xydataCompare"
                                                                     ref={(divElement) => this.xydata = divElement}
                                                                     style={{height: this.state.currentHeight}}></div>
                                                                <div id="xPos">X (cm)</div>
                                                                <div id="yPos">Y (cm)</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    : null}

                                            </div>

                                        </div>

                                    </div>
                                    : null}

                                {(this.state.showType === customConstants.showTypes.ANIMATION) ?
                                    <div className={'analyze'}>
                                        <div className="row">
                                            <div
                                                className={'multi-view-video-title'}>
                                                <FontAwesomeIcon
                                                    size={'1x'}
                                                    title={global.strings.layeredMode}
                                                    color={_this.state.colors[0]}
                                                    icon={faSquareFull}/> {this.state.currentHashValue}
                                            </div>
                                            {(typeof this.state.currentVideo[this.state.currentSignId] !== 'undefined') ?
                                                <Player
                                                    autoPlay={true}
                                                    playsInline
                                                >
                                                    <source
                                                        src={(this.state.currentVideo !== null !== 'undefined' && typeof this.state.currentVideo[this.state.currentSignId]) ? this.state.currentVideo[this.state.currentSignId] : null}
                                                        type={'video/mp4'}/>
                                                    <BigPlayButton position="center"/>
                                                    <LoadingSpinner/>
                                                    <ControlBar autoHide={false}>
                                                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]}/>
                                                    </ControlBar>
                                                    <Shortcut clickable={false} shortcuts={shortCuts}/>
                                                </Player> : null}
                                        </div>
                                    </div>
                                    : null}
                                {(this.state.showType === customConstants.showTypes.VALUE) ?

                                    <div className={'analyze'}>
                                        <div className="row">
                                            <div className={'col-12'} style={{'display': 'flow-root'}}>

                                                <div>
                                                    <h6 className={'graph-title'}>{global.strings.signvValues.toUpperCase()}</h6>

                                                    {(typeof this.state.currentSignsValue !== 'undefined' && this.state.currentSignsValue !== null && typeof this.state.currentSignsValue.features !== 'undefined') ?

                                                        <table className="table-single table">
                                                            <thead>
                                                            <tr>

                                                                <th scope="col"> {global.strings.parameter}</th>
                                                                <th scope="col">  {global.strings.values} ({global.strings.reference})</th>
                                                                <th scope="col"
                                                                    style={{width: 110}}>{global.strings.unit}</th>
                                                            </tr>

                                                            </thead>
                                                            <tbody>
                                                            <tr>

                                                                <td scope="col"> {global.strings.totalTime}</td>
                                                                <td scope="col"> {this.state.currentSignsValue.features.totalTime}</td>
                                                                <td>{global.strings.unitSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col"> {global.strings.totalTimeUp}</td>
                                                                <td scope="col"> {(this.state.currentSignsValue.detail.pd.filter(x => x == 0).length - 1 === -1) ? 0 : this.state.currentSignsValue.detail.pd.filter(x => x == 0).length - 1}</td>
                                                                <td>{global.strings.unitSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col"> {global.strings.totalTimeUpTime}</td>
                                                                <td scope="col"> {((this.state.currentSignsValue.features.totalTime) - (this.state.currentSignsValue.features.penDownTime)).toFixed(4)}</td>
                                                                <td>{global.strings.unitSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.rmsSpeedV}</td>
                                                                <td scope="col"> {this.state.currentSignsValue.features.rmsSpeedV}</td>
                                                                <td>{global.strings.unitMeterSecond}</td>
                                                            </tr>

                                                            <tr>

                                                                <td scope="col">{global.strings.avgSpeedVx}</td>
                                                                <td scope="col"> {this.state.currentSignsValue.features.avgSpeedVx}</td>
                                                                <td>{global.strings.unitMeterSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.avgAccCent}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.avgAccCent}</td>
                                                                <td>{global.strings.unitMeterSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.avgAccTang}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.avgAccTang}</td>
                                                                <td>{global.strings.unitMeterSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.ltwRatio}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.ltwRatio}</td>
                                                                <td>{global.strings.supRatio}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.HSpanRatio}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.HSpanRatio}</td>
                                                                <td>{global.strings.supRatio}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.midOfX}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.midOfX}</td>
                                                                <td>{global.strings.meter}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.midOfY}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.midOfY}</td>
                                                                <td>{global.strings.meter}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.speedCorr}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.speedCorr}</td>
                                                                <td>{global.strings.meter}<sup>2</sup> / s<sup>2</sup>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.meanPressure}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.meanPressure}</td>
                                                                <td>{global.strings.newton}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.totalLength}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.totalLength}</td>
                                                                <td>{global.strings.meter}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.meanSpeedV}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.meanSpeedV}</td>
                                                                <td>{global.strings.unitMeterSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.meanSpeedX}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.meanSpeedX}</td>
                                                                <td>{global.strings.unitMeterSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.meanSpeedY}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.meanSpeedY}</td>
                                                                <td>{global.strings.unitMeterSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.penDownTime}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.penDownTime}</td>
                                                                <td>{global.strings.unitSecond}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.ltaRatio}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.ltaRatio}</td>
                                                                <td>{global.strings.supRatio}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.hmsRatio}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.hmsRatio}</td>
                                                                <td>{global.strings.supRatio}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.numberOfStrokes}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.numberOfStrokes}</td>
                                                                <td>{global.strings.count}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.meanAscenderHeight}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.meanAscenderHeight}</td>
                                                                <td>{global.strings.meter}</td>
                                                            </tr>
                                                            <tr>

                                                                <td scope="col">{global.strings.meanDescenderDepth}</td>
                                                                <td scope="col">{this.state.currentSignsValue.features.meanDescenderDepth}</td>
                                                                <td>{global.strings.meter}</td>
                                                            </tr>

                                                            </tbody>
                                                        </table>

                                                        : null}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    : null}
                                {(this.state.showType === customConstants.showTypes.PARAMETER) ?
                                    <div>
                                        <div className={'standard-box'}>

                                            <div className={'print-graph'}>

                                                <label>{global.strings.reviewScreens}</label>
                                                <div
                                                    className={'graph-selection'}>
                                                    {global.strings.options}
                                                    <div className={'dropdown-filters-options'}>
                                                        <div
                                                            className={'results-graph'}>
                                                            <div className={'row'}>

                                                                <div className="col-6 parameters">
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="checkbox" id="velocity"
                                                                                   onChange={(value) => this.onChangeDetailType('velocity')}
                                                                                   checked={(currentOptions.indexOf('velocity') !== -1) ? true : false}
                                                                                   name="variables"/>
                                                                            {global.strings.velocity_label}</label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="checkbox" id="velocityX"
                                                                                   onChange={(value) => this.onChangeDetailType('velocityX')}
                                                                                   checked={(currentOptions.indexOf('velocityX') !== -1) ? true : false}
                                                                                   name="variables"/>
                                                                            {global.strings.velocityX_label}</label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="checkbox" id="velocityY"
                                                                                   onChange={(value) => this.onChangeDetailType('velocityY')}
                                                                                   checked={(currentOptions.indexOf('velocityY') !== -1) ? true : false}

                                                                                   value="velocityY"
                                                                                   name="variables"/>
                                                                            {global.strings.velocityY_label}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 parameters">
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="checkbox" id="acceleration"
                                                                                   onChange={(value) => this.onChangeDetailType('acceleration')}
                                                                                   checked={(currentOptions.indexOf('acceleration') !== -1) ? true : false}

                                                                                   value="acceleration"
                                                                                   name="variables"/>
                                                                            {global.strings.acceleration_label}</label>
                                                                    </div>
                                                                    <div className="radio">

                                                                        <label>
                                                                            <input type="checkbox"
                                                                                   id="accelerationX"
                                                                                   onChange={(value) => this.onChangeDetailType('accelerationX')}
                                                                                   checked={(currentOptions.indexOf('accelerationX') !== -1) ? true : false}

                                                                                   value="accelerationX"
                                                                                   name="variables"/>
                                                                            {global.strings.accelerationX_label}</label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="checkbox"
                                                                                   id="accelerationY"
                                                                                   onChange={(value) => this.onChangeDetailType('accelerationY')}
                                                                                   checked={(currentOptions.indexOf('accelerationY') !== -1) ? true : false}

                                                                                   value="accelerationY"
                                                                                   name="variables"/>
                                                                            {global.strings.accelerationY_label}</label>
                                                                    </div>

                                                                </div>
                                                                <div className="col-6 parameters">
                                                                    <div className="radio">

                                                                        <label>
                                                                            <input type="checkbox" id="pressure"
                                                                                   onChange={(value) => this.onChangeDetailType('pressure')}
                                                                                   checked={(currentOptions.indexOf('pressure') !== -1) ? true : false}

                                                                                   value="pressure"
                                                                                   name="variables"/>
                                                                            {global.strings.pressure_label}</label>
                                                                    </div>

                                                                </div>
                                                                <div className="col-12 parameters">
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="checkbox" id="xradio"
                                                                                   onChange={(value) => this.onChangeDetailType('xradio')}
                                                                                   checked={(currentOptions.indexOf('xradio') !== -1) ? true : false}

                                                                                   value="xradio"
                                                                                   name="variables"/>
                                                                            X</label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input type="checkbox" id="yradio"
                                                                                   onChange={(value) => this.onChangeDetailType('yradio')}
                                                                                   checked={(currentOptions.indexOf('yradio') !== -1) ? true : false}

                                                                                   value="yradio"
                                                                                   name="variables"/>
                                                                            Y</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 parameters">
                                                                    <div className="radio">

                                                                        <label>
                                                                            <input type="checkbox" id="azimuth"
                                                                                   onChange={(value) => this.onChangeDetailType('azimuth')}
                                                                                   checked={(currentOptions.indexOf('azimuth') !== -1) ? true : false}

                                                                                   value="azimuth"
                                                                                   name="variables"/>
                                                                            {global.strings.azimuth_label}</label>
                                                                    </div>
                                                                    <div className="radio">

                                                                        <label>
                                                                            <input type="checkbox" id="elevation"
                                                                                   onChange={(value) => this.onChangeDetailType('elevation')}
                                                                                   checked={(currentOptions.indexOf('elevation') !== -1) ? true : false}

                                                                                   value="elevation"
                                                                                   name="variables"/>
                                                                            {global.strings.elevation_label}</label>
                                                                    </div>
                                                                    <div className="radio">

                                                                        <label>
                                                                            <input type="checkbox" id="pendown"
                                                                                   onChange={(value) => this.onChangeDetailType('pendown')}
                                                                                   checked={(currentOptions.indexOf('pendown') !== -1) ? true : false}

                                                                                   value="pendown"
                                                                                   name="variables"/>
                                                                            {global.strings.pendown_label}</label>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        {this.state.optionType.map((type, i) => {

                                            // console.log(type);
                                            return (
                                                <div className={'standard-box'}>
                                                    <div className={'results-detail'} style={{height:'100vh'}} key={i}>
                                                        <div className="lbTime"
                                                             id="yTime"> {global.strings.time}</div>
                                                        <div id="xChange"> {global.strings[type]}</div>
                                                        <div className="detailGraph"
                                                             id={'vardata-' + type}></div>
                                                    </div>
                                                </div>)
                                        })}

                                    </div> : null}

                            </Modal.Body>

                        </Modal>

                    </div>

                </div>
                <Modal size={'lg'} show={this.state.showAddGroupModal} onHide={this.handleCloseGroup}>

                    <Modal.Body>
                        <div className={'upload-inner'}>

                            {this.state.tempSigns.map((item, i) => {

                                return <div key={i}>
                                    <h3 className={'reference-sign'}
                                        style={{'marginTop': '18px'}}>{i + 1}. {global.strings.sampleSign}</h3>
                                    <div className={'information'}>{global.strings.toBeSample}</div>
                                    <FormControl style={{width: '100%', marginTop: 15}}>
                                        <InputLabel
                                            id="demo-customized-select-label">{global.strings.upload_type}</InputLabel>
                                        <Select

                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={(typeof this.state.sampleType[i] !== 'undefined') ? this.state.sampleType[i] : 'techsign'}
                                            defaultValue={'turkcell'}
                                            onChange={(event) => this.handleChangeTypeSample(event, i)}
                                        >
                                            <MenuItem value={'techsign'}>Techsign</MenuItem>
                                            <MenuItem value={'turkcell'}>Turkcell</MenuItem>
                                            <MenuItem value={'iso11'}>iso11</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className={'uploadContainer'}>
                                        <Dropzone
                                            accept=".sig" multiple
                                            onDropAccepted={acceptedFiles => this.uploadSigFile(acceptedFiles, i)}>
                                            {({getRootProps, getInputProps}) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>
                                                            <svg
                                                                width="100"
                                                                height="60"
                                                                viewBox="0 0 50 43">
                                                                <path fill="#ccc" d="M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266
			C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h6v-2H5.997
			C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7
			c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03
			c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4H20v2h6c3.312,0,6-2.693,6-6C32,15.735,30.13,13.407,27.586,12.712z"/>
                                                                <polygon fill="#ccc"
                                                                         points="16,13.5 11,19.5 14,19.5 14,27.5 18,27.5 18,19.5 21,19.5 		"/>
                                                            </svg>
                                                            <br/><br/>
                                                            {global.strings.uploadContainer}
                                                        </p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <ul className={'uploaded-files'}>
                                            {typeof _this.fileData['groups'][i] !== 'undefined' && _this.fileData['groups'][i].length > 0 && _this.fileData['groups'][i].map((item, i) => {
                                                return <li key={'addGroup-' + i}>
                                                    <svg version="1.1" x="0px" y="0px" width={24}
                                                         height={24}
                                                         viewBox="0 0 1200 1200"
                                                    >
                                                        <path fill="#7AC043"
                                                              d="M50,662.7l187-134.2l149.6,237.6c0,0,303.6-475.2,763.4-624.8v37.4c0,0-466.4,198-748,880L50,662.7z"/>
                                                    </svg>
                                                    {item.filename}
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className={'row'} style={{'marginTop': '15px'}}>
                                        <div className="flex-row wrap-input100 validate-input col-7">
                                            <label className="lf--label" style={{'width': '6em'}}
                                                   htmlFor="groupName">
                                                {global.strings.groupName}
                                            </label>
                                            <input id="groupName" className="lf--input input100"
                                                   value={this.state.email}
                                                   maxLength={10}
                                                   onChange={(v) => this.updateGroupName(v, false, i)}
                                                   placeholder={global.strings.groupNamePlaceholder}
                                                   type="text"/>
                                            <span className="focus-input100"></span>
                                        </div>
                                        <div className="flex-row wrap-input100 validate-input col-5">
                                            <label className="lf--label" htmlFor="date">
                                                {global.strings.date}
                                            </label>
                                            <DatePicker

                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.tempSigns[i].groupDate}
                                                onChange={(v) => this.updateDate(v, false, i)}
                                            />
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className="flex-row wrap-input100 validate-input col-12">
                                            <label className="lf--label" style={{'width': '6em'}}
                                                   htmlFor="groupName">
                                                {global.strings.description}
                                            </label>
                                            <textarea id="groupName"
                                                      className="lf--input input100 lf--textarea"
                                                      value={this.state.description}
                                                      onChange={(v) => this.updateDescription(v, false, i)}
                                                      placeholder={global.strings.description}
                                            />
                                            <span className="focus-input100"></span>
                                        </div>

                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-8'}>
                                            <h5 onClick={() => this.addOtherSigns()} className={'add-other'}><span
                                                className={'add-icon'}>
                            <svg className={'uploadIcn'} version="1.1" xmlns="http://www.w3.org/2000/svg"
                                 x="0px" y="0px"
                                 width="16px" height="16px" viewBox="0 0 24 24">
<path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>

                        </span>{global.strings.addOtherSigns}</h5>
                                        </div>
                                        <div className={'col-4'}>
                                            <div className="container-login100-form-btn">
                                                {(this.state.hideButton === false) ?
                                                    <div onClick={() => this.completeAdding()} lang="en"
                                                         className="login100-form-btn">
                                                        <svg x="0px" y="0px" width="25px" height="23px"
                                                             viewBox="0 0 31.49 31.49"
                                                        >
                                                            <path fill="#ffffff"
                                                                  d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
                                                        </svg>
                                                    </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            }

                        </div>
                    </Modal.Body>
                </Modal>
                <Modal size={'lg'} show={this.state.showAddSignModal} onHide={this.handleCloseSign}>

                    <Modal.Body>
                        <div className={'upload-inner'}>


                            <div>
                                <h3 className={'reference-sign'}
                                    style={{'marginTop': '18px'}}>{this.state.currentSignGroupName} {global.strings.addSignToGroup}</h3>
                                <div className={'information'}>{global.strings.toBeSample}</div>
                                <FormControl style={{width: '100%', marginTop: 15}}>
                                    <InputLabel
                                        id="demo-customized-select-label">{global.strings.upload_type}</InputLabel>
                                    <Select

                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={(typeof this.state.sampleSingleType[this.state.currentSignGroupIndex] !== 'undefined') ? this.state.sampleSingleType[this.state.currentSignGroupIndex] : 'techsign'}
                                        onChange={(event) => this.handleChangeTypeSampleSingle(event, this.state.currentSignGroupIndex)}
                                    >
                                        <MenuItem value={'techsign'}>Techsign</MenuItem>
                                        <MenuItem value={'turkcell'}>Turkcell</MenuItem>
                                    </Select>
                                </FormControl>
                                <div className={'uploadContainer'}>
                                    <Dropzone
                                        accept=".sig" multiple
                                        onDropAccepted={acceptedFiles => this.uploadSigFileSingle(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>
                                                        <svg
                                                            width="100"
                                                            height="60"
                                                            viewBox="0 0 50 43">
                                                            <path fill="#ccc" d="M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266
			C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h6v-2H5.997
			C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7
			c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03
			c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4H20v2h6c3.312,0,6-2.693,6-6C32,15.735,30.13,13.407,27.586,12.712z"/>
                                                            <polygon fill="#ccc"
                                                                     points="16,13.5 11,19.5 14,19.5 14,27.5 18,27.5 18,19.5 21,19.5 		"/>
                                                        </svg>
                                                        <br/><br/>
                                                        {global.strings.uploadContainer}
                                                    </p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <ul className={'uploaded-files'}>
                                        {this.state.currentAdded.length > 0 && this.state.currentAdded.map((item, i) => {
                                            return <li key={'curernt' + i}>
                                                <svg version="1.1" x="0px" y="0px" width={24}
                                                     height={24}
                                                     viewBox="0 0 1200 1200"
                                                >
                                                    <path fill="#7AC043"
                                                          d="M50,662.7l187-134.2l149.6,237.6c0,0,303.6-475.2,763.4-624.8v37.4c0,0-466.4,198-748,880L50,662.7z"/>
                                                </svg>
                                                {item.filename}
                                            </li>
                                        })}
                                    </ul>
                                </div>

                                <div className={'row'}>

                                    <div className={'col-12'}>
                                        <div className="container-login100-form-btn">
                                            <div onClick={() => this.completeAddingSign()} lang="en"
                                                 className="login100-form-btn">
                                                <svg x="0px" y="0px" width="25px" height="23px"
                                                     viewBox="0 0 31.49 31.49"
                                                >
                                                    <path fill="#ffffff"
                                                          d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }

}

export default Groups;



