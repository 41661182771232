import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";
import {NotificationManager} from "react-notifications";
import forensicLib from "../assets/js/forensicLib";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";

export class Test extends Component {

    constructor(props) {
        super(props);
        this.state = {
           /* groupName: null,
            groupDate: null,
            description: null,
            status: null*/
            /*groupId: null,
            loading:false,
            caseId: null,*/
            signatureId: null

        };
        /*this.updateGroupName = this.updateGroupName.bind(this);
        this.updateGroupDate = this.updateGroupDate.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.updateCaseId = this.updateCaseId.bind(this);
        this.updateTCKN = this.updateTCKN.bind(this);
        this.updateCaseId = this.updateCaseId.bind(this);
        this.updateFullname = this.updateFullname.bind(this);
        this.updateDateVal = this.updateDateVal.bind(this);*/
        this.updateGroupId = this.updateGroupId.bind(this);
        this.updateSignatureId = this.updateSignatureId.bind(this);
    }

    componentDidMount() {

    }

    updateGroupName(v) {
        const groupNameValue = v.target.value;
        this.setState({
            groupName:  groupNameValue
        }, () => {
        });
    }

    updateGroupDate(v) {
        this.setState({
            groupDate: v}, () => {
           // console.log(v);
        });
    }

    updateDescription(v) {
        const descriptionValue = v.target.value;
        this.setState({
            description: descriptionValue}, () => {
        });
    }

    updateStatus(v) {
        const statusValue = v.target.value;
        this.setState({
            status: statusValue}, () => {
        });
    }

    updateCaseId(v) {
        const caseIdValue = v.target.value;
        this.setState({
            caseId: caseIdValue
        }, () => {
        });
    }

    updateGroupId(v) {
        const groupIdValue = v.target.value;
        this.setState({
            groupId: groupIdValue
        }, () => {
        });
    }

    updateTCKN(v) {
        const tcknValue = v.target.value;
        this.setState({
            tckn:  tcknValue
        }, () => {
        });
    }

    updateCaseId(v) {
        const caseIdValue = v.target.value;
        this.setState({
            caseId: caseIdValue
        }, () => {
        });
    }

    updateFullname(v) {
        const fullnameValue = v.target.value;
        this.setState({
            fullname: fullnameValue}, () => {
        });
    }

    updateDateVal(v) {
        this.setState({
            date: v}, () => {
          //  console.log(v);
        });
    }

    updateSignatureId(v) {
        const signatureIdValue = v.target.value;
        this.setState({
            signatureId: signatureIdValue
        }, () => {
        });
    }

    createCase(e, caseObj) {

        e.stopPropagation();
        caseObj.date = "2019-07-03T21:00:11Z";
        const data = {
            "caseId": caseObj.caseId,
            "tckn": caseObj.tckn,
            "nameSurname" : caseObj.nameSurname,
            "date": caseObj.date
        };


        fetch(customConstants.apiUrl + "/cases", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
                this.setState({
                    responseCaseId: result}, () => {
                });
                this.continueToReport();
            })
            .catch(err => {
                this.props.history.push('/');
            });
    }   // /cases

    searchCase(e, caseId, tckn) {

        e.stopPropagation();

        const data = {
            "tckn": tckn === "" ? null : tckn,
            "caseId": caseId === "" ? null : caseId

        };

        fetch(customConstants.apiUrl + "/cases/search", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
                this.setState({
                    searchResult: result}, () => {
                });
                this.continueToReport();
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /cases/search

    createGroup(e, caseObj, caseId){
        e.stopPropagation();
        caseObj.groupDate = "2019-07-03T21:00:11Z";
        const data = {
            "groupName": caseObj.groupName,
            "groupDate": caseObj.groupDate,
            "description": caseObj.description,
            "status": caseObj.status
        };

        fetch(customConstants.apiUrl + "/cases/" + caseId + "/group", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /cases/{caseId}/group

    updateGroup(e, caseId, groupId, groupBody){
        e.stopPropagation();
        groupBody.groupDate = "2019-07-03T21:00:11Z";
        const data = {
            "groupName": groupBody.groupName,
            "groupDate": groupBody.groupDate,
            "description": groupBody.description,
            "status": groupBody.status
        };

        fetch(customConstants.apiUrl + "/cases/" + caseId + "/group" + groupId, {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /cases/{caseId}/group/{groupId}


    addSignatureToGroup = (file, caseId, groupId) => {

        const files = Array.from(file);
        const data = new FormData();
        const allowedExtensions = /(\.sig)$/i;
        if (typeof files[0] !== 'undefined' && !allowedExtensions.exec(files[0].name)) {
            return NotificationManager.error(global.strings.upload_file_type_error);
        } else if (typeof files[0] !== 'undefined' && allowedExtensions.exec(files[0].name)) {
            this.setState({loading: true});
            data.append('signature', files[0]);
            //data.append('caseId', this.state.caseId);
            // data.append('groupId', this.state.groupId);
            //this.setState({uploading: true});
            fetch(customConstants.apiUrl + "/cases/" + caseId + "/group/" + groupId + "/signature", {
                method: 'POST',
                headers: {
                    'Authorization': forensicLib.getAuthHeader(),
                    'Accept': 'application/json'
                },
                body: data
            })
                .then(response => response.json())
                .then(result => {
                    this.setState({loading: false});
                    alert(result.json);
                })
                .catch(err => {
                    this.setState({loading: false});
                    this.props.history.push('/dashboard');
                });
        }
    } // /cases/{caseId}/group/{groupId}/signature

    uploadSigFile = (file) => {
        const files = Array.from(file);
        const data = new FormData();
        const allowedExtensions = /(\.sig)$/i;
        if (typeof files[0] !== 'undefined' && !allowedExtensions.exec(files[0].name)) {
            return NotificationManager.error(global.strings.upload_file_type_error);
        } else if (typeof files[0] !== 'undefined' && allowedExtensions.exec(files[0].name)) {
            this.state.file = file;
        }
    }

    getSignature(e, signatureId) {
        e.stopPropagation();
        let signatures = signatureId.split(",")
        const data = {
            "signatures": signatures
        }
        fetch(customConstants.apiUrl + "/signature", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /signature

    getEvaluation(e, signatureId, groupId) {
        e.stopPropagation();
        fetch(customConstants.apiUrl + "/signature/" + signatureId + "/evaluate/" + groupId, {
            method: 'GET',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },

        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /signature/{signatureId}/evaluate/{groupId}

    getCase(e, caseId) {
        e.stopPropagation();
        fetch(customConstants.apiUrl + "/cases/" + caseId, {
            method: 'GET',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },

        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /cases/{caseId}

    deleteGroup(e, caseId, groupId){
        e.stopPropagation();

        fetch(customConstants.apiUrl + "/cases/" + caseId + "/group/" + groupId, {
            method: 'DELETE',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /cases/{caseId}/group/{groupId}

    deleteSignature(e, caseId, groupId, signatureId){
        e.stopPropagation();

        fetch(customConstants.apiUrl + "/cases/" + caseId + "/group/" + groupId + "/signature/" + signatureId, {
            method: 'DELETE',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(result => {
                alert(JSON.stringify(result));
            })
            .catch(err => {
                this.props.history.push('/');
            });
    } // /cases/{caseId}/group/{groupId}/signature/{signatureId}





    render() {
        return (

            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">

                        <form className="login100-form validate-form">
					<span className="login100-form-title">
                        {customConstants.appTitle.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
					</span>
                            <span className="login100-form-desc">{global.strings.loginDesc}</span>


                            <div className="flex-row wrap-input100 validate-input">
                                <label className="lf--label" htmlFor="groupId">
                                    <svg x="0px" y="0px" width="12px" height="13px">
                                        <path fill="#B1B7C4"
                                              d="M8.9,7.2C9,6.9,9,6.7,9,6.5v-4C9,1.1,7.9,0,6.5,0h-1C4.1,0,3,1.1,3,2.5v4c0,0.2,0,0.4,0.1,0.7 C1.3,7.8,0,9.5,0,11.5V13h12v-1.5C12,9.5,10.7,7.8,8.9,7.2z M4,2.5C4,1.7,4.7,1,5.5,1h1C7.3,1,8,1.7,8,2.5v4c0,0.2,0,0.4-0.1,0.6 l0.1,0L7.9,7.3C7.6,7.8,7.1,8.2,6.5,8.2h-1c-0.6,0-1.1-0.4-1.4-0.9L4.1,7.1l0.1,0C4,6.9,4,6.7,4,6.5V2.5z M11,12H1v-0.5 c0-1.6,1-2.9,2.4-3.4c0.5,0.7,1.2,1.1,2.1,1.1h1c0.8,0,1.6-0.4,2.1-1.1C10,8.5,11,9.9,11,11.5V12z"></path>
                                    </svg>
                                </label>
                                <input id="signatureId" className="lf--input input100"
                                       value={this.state.signatureId}
                                       onChange={this.updateSignatureId}
                                       type="text"/>
                                <span className="focus-input100"></span>
                            </div>

                            <div className="flex-row wrap-input100 validate-input">
                                <label className="lf--label" htmlFor="groupId">
                                    <svg x="0px" y="0px" width="12px" height="13px">
                                        <path fill="#B1B7C4"
                                              d="M8.9,7.2C9,6.9,9,6.7,9,6.5v-4C9,1.1,7.9,0,6.5,0h-1C4.1,0,3,1.1,3,2.5v4c0,0.2,0,0.4,0.1,0.7 C1.3,7.8,0,9.5,0,11.5V13h12v-1.5C12,9.5,10.7,7.8,8.9,7.2z M4,2.5C4,1.7,4.7,1,5.5,1h1C7.3,1,8,1.7,8,2.5v4c0,0.2,0,0.4-0.1,0.6 l0.1,0L7.9,7.3C7.6,7.8,7.1,8.2,6.5,8.2h-1c-0.6,0-1.1-0.4-1.4-0.9L4.1,7.1l0.1,0C4,6.9,4,6.7,4,6.5V2.5z M11,12H1v-0.5 c0-1.6,1-2.9,2.4-3.4c0.5,0.7,1.2,1.1,2.1,1.1h1c0.8,0,1.6-0.4,2.1-1.1C10,8.5,11,9.9,11,11.5V12z"></path>
                                    </svg>
                                </label>
                                <input id="groupId" className="lf--input input100"
                                       value={this.state.groupId}
                                       onChange={this.updateGroupId}
                                       type="text"/>
                                <span className="focus-input100"></span>
                            </div>

                            <div className="container-login100-form-btn">
                                <div onClick={(e) => this.getEvaluation(e, this.state.signatureId, this.state.groupId)}
                                     lang="en" className="login100-form-btn">
                                    <svg x="0px" y="0px" width="25px" height="23px"
                                         viewBox="0 0 31.49 31.49"
                                    >
                                        <path fill="#ffffff"
                                              d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
                                    </svg>
                                </div>
                            </div>
                        </form>
                        <div className="login100-pic js-tilt" data-tilt>
                            <img src={'./assets/images/logo-' + customConstants.appName + '.png'} alt="IMG"/>
                        </div>

                    </div>
                </div>
            </div>

        );
    }

}

export default withRouter(Selection);



