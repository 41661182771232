import React, {Component} from 'react';
import customConstants from "../Globals";
import font from "../assets/fonts/RobotoMedium-normal";

import {
    Player,
    Shortcut,
    BigPlayButton,
    LoadingSpinner,
    PlayToggle,
    ControlBar,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlayCircle,
    faStopCircle,
    faPauseCircle,
    faStepBackward,
    faThLarge,
    faSquareFull,
    faLayerGroup,
    faRedo
} from '@fortawesome/free-solid-svg-icons'
import {_isContains, logout, hexToRGB, getVideoFromServer, shortCuts} from "../Helpers";
import LeftMenu from "../components/LeftMenu";
import forensicLib from "../assets/js/forensicLib";
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-bootstrap/Modal';
import {FaPlay, FaStop, FaRedo, FaPause, FaCopy, FaBackward} from 'react-icons/fa';
import {saveAs} from 'file-saver';
import Dropzone from 'react-dropzone';
import {Progress} from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import "video-react/dist/video-react.css";
import Loading from 'react-fullscreen-loading';
import {Switch} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Gesture from '@material-ui/icons/Gesture';
import Compare from '@material-ui/icons/Compare';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import $ from 'jquery';
import _ from "lodash";
import moment from 'moment';
import tr from 'moment/locale/tr';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from '@material-ui/lab';
import strings from "../language/config";

window.$ = $;
global.jQuery = $;

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


let JSZip = require("jszip");
let _this;
let currentCursorY = 70;
const graphHeight = 60;
const graphWidth = 90;
let currentCursorX = 15;
let currentReferenceIndexForReport = 0;
let currentSignIndexForReport = 0;
let pdf = new jsPDF();
var lastVal = 0;

export class DashBoard extends Component {

    constructor(props) {
        super(props);
        this.videoOuter = React.createRef();
        this.state = {
            uploading: false,
            dataSource: [],
            currentColors: [],
            currentColorsForTable: [],
            toShowDetail: [],
            hideGraph: false,
            selected: 0,
            selectedFirst: false,
            blue: false,
            orange: false,
            selectedSecond: false,
            optionType: [],
            currentGroups: [],
            currentSigns: [],
            labelY: global.strings.acceleration,
            checkedDocs: [],
            result: null,
            resultIndex: null,
            show: false,
            currentDoc: null,
            inputValue: 20,
            isPlaying: true,
            compare: false,
            score: 88,
            sampleJson: null,
            referenceJson: null,
            currentColorsTable: [],
            currentHeight: 630,
            colors: customConstants.COLORS,
            hasReference: true,
            currentReferenceIndex: 0,
            currentScreenWidth: 0,
            parameterOpened: false,
            showReferencePlayer: false,
            isPlayingAll: false,
            playedAll: false,
            compareVideoType: 'grid',
            currentVideo: [],
            coloredMode: false,
            currentReferences: [],
            selectedReferences: [],
            currentCompares: [],
            selectedCompares: [],
            selectedFeatures: [],
            selectedType: 'pdf',
            lastBandwidthValues: null,
            open: false,
            fullScreenGraph: false,
            features_graph: [
                {key: "xradio", label: global.strings.xradio},
                {key: "yradio", label: global.strings.yradio},
                {key: "pressure", label: global.strings.pressure_label},
                {key: "azimuth", label: global.strings.azimuth_label},
                {key: "elevation", label: global.strings.elevation_label},
                {key: "pendown", label: global.strings.pendown},
                {key: "velocity", label: global.strings.velocity_label},
                {key: "velocityX", label: global.strings.velocityX_label},
                {key: "velocityY", label: global.strings.velocityY_label},
                {key: "acceleration", label: global.strings.acceleration_label},
                {key: "accelerationX", label: global.strings.accelerationX_label},
                {key: "accelerationY", label: global.strings.accelerationY_label},
            ],
            selectedFeaturesGraph: [],
            features: [
                {key: "HSpanRatio", label: global.strings.HSpanRatio},
                {key: "WTTRatio", label: global.strings.WTTRatio},
                {key: "arcTanDiffHist0", label: global.strings.arcTanDiffHist0},
                {key: "arcTanDiffHist1", label: global.strings.arcTanDiffHist1},
                {key: "arcTanDiffHist2", label: global.strings.arcTanDiffHist2},
                {key: "arcTanDiffHist3", label: global.strings.arcTanDiffHist3},
                {key: "arcTanHist0", label: global.strings.arcTanHist0},
                {key: "arcTanHist1", label: global.strings.arcTanHist1},
                {key: "arcTanHist2", label: global.strings.arcTanHist2},
                {key: "arcTanHist3", label: global.strings.arcTanHist3},
                {key: "arcTanHist4", label: global.strings.arcTanHist4},
                {key: "arcTanHist5", label: global.strings.arcTanHist5},
                {key: "arcTanHist6", label: global.strings.arcTanHist6},
                {key: "arcTanHist7", label: global.strings.arcTanHist7},
                {key: "ascDescDiff", label: global.strings.ascDescDiff},
                {key: "avgAccCent", label: global.strings.avgAccCent},
                {key: "avgAccTang", label: global.strings.avgAccTang},
                {key: "avgSpeedVx", label: global.strings.avgSpeedVx},
                {key: "drsRatio", label: global.strings.drsRatio},
                {key: "hmsRatio", label: global.strings.hmsRatio},
                {key: "ltaRatio", label: global.strings.ltaRatio},
                {key: "ltwRatio", label: global.strings.ltwRatio},
                {key: "maxSpeedXToMean", label: global.strings.maxSpeedXToMean},
                {key: "maxSpeedXToMin", label: global.strings.maxSpeedXToMin},
                {key: "maxSpeedYToMean", label: global.strings.maxSpeedYToMean},
                {key: "maxSpeedYToMin", label: global.strings.maxSpeedYToMin},
                {key: "maxXtoMinX", label: global.strings.maxXtoMinX},
                {key: "meanAscenderHeight", label: global.strings.meanAscenderHeight},
                {key: "meanDescenderDepth", label: global.strings.meanDescenderDepth},
                {key: "meanPressure", label: global.strings.meanPressure},
                {key: "meanSpeedNegX", label: global.strings.meanSpeedNegX},
                {key: "meanSpeedNegY", label: global.strings.meanSpeedNegY},
                {key: "meanSpeedV", label: global.strings.meanSpeedV},
                {key: "meanSpeedX", label: global.strings.meanSpeedX},
                {key: "meanSpeedY", label: global.strings.meanSpeedY},
                {key: "midOfX", label: global.strings.midOfX},
                {key: "midOfY", label: global.strings.midOfY},
                {key: "minSpeedX", label: global.strings.minSpeedX},
                {key: "minSpeedY", label: global.strings.minSpeedY},
                {key: "mvtmvyRatio", label: global.strings.mvtmvyRatio},
                {key: "numberOfStrokes", label: global.strings.numberOfStrokes},
                {key: "overMeanXRatio", label: global.strings.overMeanXRatio},
                {key: "overMeanYRatio", label: global.strings.overMeanYRatio},
                {key: "penDownTime", label: global.strings.penDownTime},
                {key: "rmsSpeedV", label: global.strings.rmsSpeedV},
                {key: "speedCorr", label: global.strings.speedCorr},
                {key: "speedSignChange", label: global.strings.speedSignChange},
                {key: "stdPressure", label: global.strings.stdPressure},
                {key: "stdSpeedX", label: global.strings.stdSpeedX},
                {key: "stdSpeedY", label: global.strings.stdSpeedY},
                {key: "stpRatio", label: global.strings.stpRatio},
                {key: "totalLength", label: global.strings.totalLength},
                {key: "totalTime", label: global.strings.totalTime},
                {key: "totalTimeUpTime", label: global.strings.totalTimeUpTime},
                {key: "startSignLine", label: global.strings.startSignLine},
                {key: "positiveXMoveLength", label: global.strings.positiveXMoveLength},
                {key: "positiveYMoveLength", label: global.strings.positiveYMoveLength},
                {key: "negativeXMoveLength", label: global.strings.negativeXMoveLength},
                {key: "negativeYMoveLength", label: global.strings.negativeYMoveLength},
                {key: "distanceOfMaxY", label: global.strings.distanceOfMaxY},
            ]
        };
        window.continueToDraw = true;
        window.playTimeout = 20;
        window.stoped = true;
        window.break = false;
        window.showHands = false;
        this.cI = 0;
        this.player = [];
        _this = this;
        this.canUpdate = true;
        this.handleClose = this.handleClose.bind(this);
        this.openParamter = this.openParamter.bind(this);
        this.getCsvData = this.getCsvData.bind(this);
        this.currentScreenWidth = 0;
        this.completedVideo = 0;

    }

    handleChange = (event) => {
        this.setState({coloredMode: event.target.checked}, () => {
            localStorage.setItem('colored', _this.state.coloredMode.toString());

            try {

                const lastObject = $('.dropdown-filters-signs').find('input:not(.top-level):checked');

                if (_this.state.compare === true && typeof lastObject !== 'undefined' && lastObject.length > 0) {

                    if (typeof lastObject !== 'undefined') {

                        lastObject.each(function (k, v) {

                            setTimeout(function () {
                                $(v).parents('label').click();
                            }, 500);


                            setTimeout(function () {
                                $(v).parents('label').click();
                            }, 1000)


                            console.log(v);
                        })

                    }
                } else {
                    _this.renewGraph();
                }

            } catch (e) {
                console.log(e);
            }

        });
    };

    clear() {
        this.setState({dataSource: [], toShowDetail: []});
    }

    addRemoveRadio(e, doc) {

        e.stopPropagation();
        let isChecked = e.target.checked;
        let currentData = this.state.checkedDocs;
        if (isChecked === true) {
            currentData.push(doc);
        } else {
            currentData = currentData.filter((v, k) => {
                return v.biometric !== doc.biometric;
            });
        }

        this.setState({checkedDocs: currentData}, () => {
            //   console.log(this.state.checkedDocs);
        });
    }

    checkForCompareState() {
        const href = window.location.href;
        var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
        const result = match && {
            pathname: match[5]
        }
        let doCompare = false;

        if (result.pathname.split('/').find(function (element) {
            return element === 'compare';
        })) {
            doCompare = true;
        }

        return doCompare;
    }

    renewGraph = async () => {
        await _this.doCommon();
    }

    componentWillReceiveProps() {

        const doCompare = this.checkForCompareState();
        let currentOptions = this.state.optionType;

        if (currentOptions.indexOf('show-animation') !== -1) {
            currentOptions.splice(currentOptions.indexOf('show-animation'), 1);
            this.setState({optionType: currentOptions}, () => {
                _this.setState({compare: doCompare}, () => {
                    try {
                        _this.renewGraph();
                        _this.getCompareSignAnimations();
                    } catch (e) {
                        console.log(e);
                    }
                });
            })
        } else {
            _this.setState({compare: doCompare}, () => {
                _this.renewGraph();
            });
        }

    }

    getBandwidthValues = async () => {

        let data = null;

        try {
            data = JSON.stringify(
                {
                    'origData': _this.state.referenceJson[0].data[_this.state.currentReferenceIndex].signatureId,
                    compareData:
                        _this.state.currentSigns.map(function (v, k) {
                            return v.signId
                        })

                }
            );
        } catch (e) {
            console.log(e);
        }


        await fetch(customConstants.apiUrl + "/signature/calculateBandwidth", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: data
        })
            .then(response => response.json())
            .then(result => {

                this.setState({lastBandwidthValues: result});

            })
            .catch(err => {
                this.setState({loading: false});
                // return NotificationManager.error(err);
            });
    }

    getSampleValues = async (group, i, force, type = 'groups') => {

        if (typeof group.data === 'undefined' || force === true) {

            let groups;
            let lastRequestJson = {};
            lastRequestJson.signatures = [];

            if (type === 'groups') {
                groups = this.state.sampleJson;
            } else {
                groups = this.state.referenceJson;
            }

            group.signatures.map(function (v, k) {
                lastRequestJson.signatures.push(v.id);
            });

            await fetch(customConstants.apiUrl + "/signature", {
                method: 'POST',
                headers: {
                    Authorization: forensicLib.getAuthHeader(),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(lastRequestJson)
            })
                .then(response => response.json())
                .then(result => {

                    groups[i].data = result;

                    if (type === 'groups') {
                        groups[i].type = 'sample';
                        sessionStorage.setItem('samples', JSON.stringify(groups));
                    } else {
                        groups[i].type = 'reference';
                        //  console.log('refere set');

                        sessionStorage.setItem('reference', JSON.stringify(groups));
                    }


                })
                .catch(err => {
                    this.setState({loading: false});
                    // return NotificationManager.error(err);
                });
        }

    }

    firstInit = async (data) => {
        await _this.getSampleValues(data[0], 0, (typeof data[0].data !== 'undefined' && data[0].data.length !== data[0].signatures.length), 'reference');
        await _this.doCommon();
    }

    updateReferenceIndex = async (index) => {

        this.setState({currentReferenceIndex: index}, async () => {
            sessionStorage.setItem('refIndex', index);
            const currentData = await _this.updateTrash();
            //   console.log(currentData);

            let currentOptions = this.state.optionType;

            if (this.state.compare === true && this.state.sampleJson !== null) {
                this.setState({
                    simpleJson: currentData,
                }, () => {
                    sessionStorage.setItem('samples', JSON.stringify(currentData));

                    if (currentOptions.indexOf('show-animation') !== -1) {
                        // currentOptions.splice(currentOptions.indexOf('show-animation'), 1);
                    }

                    _this.setState({optionType: currentOptions});
                    setTimeout(function () {
                        _this.openDetail(_this.state.dataSource);
                    }, 500);

                });
            } else {

                if (currentOptions.indexOf('show-animation') !== -1) {
                    //  currentOptions.splice(currentOptions.indexOf('show-animation'), 1);
                }

                _this.setState({optionType: currentOptions});

                setTimeout(function () {
                    _this.openDetail(_this.state.dataSource);
                }, 500);
            }

        });
    }

    componentDidMount() {

        if (sessionStorage.getItem('refIndex') !== null) {
            this.setState({currentReferenceIndex: parseInt(sessionStorage.getItem('refIndex') || 0)});
        }

        let referenceFile = sessionStorage.getItem('reference');
        const data = JSON.parse(referenceFile);

        if (data !== null && data.length > 0) {
            if (typeof data[0].data === 'undefined') {

                this.setState({referenceJson: data}, () => {
                    _this.firstInit(this.state.referenceJson);
                });

            } else {
                _this.doCommon();
            }
        } else {

            setTimeout(function () {
                _this.props.history.push('/selection');
            }, 5000)
            return NotificationManager.error(global.strings.noReferenceDefined);
        }

        const coloredMode = localStorage.getItem('colored');

        if (coloredMode !== null && coloredMode == 'true') {
            this.setState({coloredMode: true});
        }

        this.setState({
            specialityNo: sessionStorage.getItem('specialityNo'),
            caseId: sessionStorage.getItem('caseId'),
            requestAuthority: sessionStorage.getItem('requestAuthority')
        });

        this.state.features.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });


    }

    doCommon = async () => {

        const doCompare = this.checkForCompareState();
        this.setState({inputValue: window.playTimeout});
        _this.setState({compare: doCompare, currentColors: [_this.state.colors[0]]}, () => {

            let referenceJson;
            let referenceFile = sessionStorage.getItem('reference');

            if (referenceFile !== null) {
                referenceJson = JSON.parse(referenceFile)
                this.setState({referenceJson: referenceJson}, () => {
                    let currentOptions = this.state.optionType;

                    if (currentOptions.indexOf('show-view') === -1) {
                        currentOptions.push('show-view');
                    }

                    if (currentOptions.indexOf('show-animation') !== -1) {
                        //  this.playSign(this.state.referenceJson[0].data);
                    }

                    if (currentOptions.indexOf('show-parameter') === -1) {
                        currentOptions.push('show-parameter');
                    }
                    if (currentOptions.indexOf('pressure') === -1) {
                        currentOptions.push('pressure');
                    }
                    if (currentOptions.indexOf('xradio') === -1) {
                        currentOptions.push('xradio');
                    }
                    if (currentOptions.indexOf('yradio') === -1) {
                        currentOptions.push('yradio');
                    }

                    if (currentOptions.indexOf('show-value') === -1) {
                        currentOptions.push('show-value');
                    }

                    this.setState({optionType: currentOptions});
                });
            }


            if (this.state.compare === true) {

                let sampleJson;
                let sampleFile = sessionStorage.getItem('samples');

                if (sampleFile !== null) {
                    sampleJson = JSON.parse(sampleFile);
                }

                let currentGroups = this.state.currentGroups;
                this.setState({currentGroups: currentGroups});

                // console.log(sampleJson);

                if (referenceJson !== null && referenceJson.length > 0 && sampleJson !== null && sampleJson.length > 0) {

                    const dataSource = referenceJson.concat(sampleJson);
                    this.setState({dataSource: dataSource, sampleJson: sampleJson}, () => {

                        setTimeout(function () {
                            try {
                                const width = _this.xydata.clientWidth;
                                const height = (width / 5) * 3;

                                _this.setState({
                                   // currentHeight: height,
                                    loading: false,
                                   // currentScreenWidth: width
                                }, () => {
                                   // this.currentScreenWidth = width;
                                    _this.openDetail(_this.state.dataSource);

                                });
                            } catch (e) {
                                console.log(e)
                            }
                        }, 1500);

                    });

                } else {
                    this.setState({loading: false}, () => {
                        _this.props.history.push('/');
                    })
                }


            } else {
                this.setState({dataSource: referenceJson}, () => {
                    setTimeout(function () {
                        try {
                            const width = _this.xydata.clientWidth;
                            const height = (width / 5) * 3;

                            _this.setState({
                             //   currentHeight: height,
                                loading: false,
                               // currentScreenWidth: width
                                }, () => {
                                _this.openDetail(_this.state.dataSource);
                            });
                        } catch (e) {
                            console.log(e);
                        }


                    }, 1000);
                });
            }

        });

        setTimeout(function () {
            $('.flot-overlay').on('dblclick', function (e) {
                _this.openGraphInFullScreen(e);
            })
        }, 2500);

        this.setState({selectedFeaturesGraph: [_this.state.features_graph[0], _this.state.features_graph[1], _this.state.features_graph[2]]});

    }

    openGraphInFullScreen(e) {

        try {
            var optionType = $(e.target).parents('.detailGraph').attr('id').split('-');

            if (typeof optionType !== 'undefined') {
                this.setState({
                    fullScreenGraph: true,
                    fullScreenType: optionType[1]
                }, () => {
                    forensicLib.drawSignFromData(this.state.dataSource, optionType[1], this.state.colors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, '#vardata-fullscreen', this.state.currentReferenceIndex, null, _this.state);
                });
            }

        } catch (e) {
            console.log(e);
        }

    }

    saveAll() {
        let c = document.getElementsByClassName('flot-base');
        let allImages = [];
        let inputList = Array.prototype.slice.call(c);
        var zip = new JSZip();
        inputList.forEach((item, k) => {
            let l = item.toDataURL("image/png");
            zip.file("report-export-" + k + ".png", l.split(',')[1], {base64: true});
        });

        zip.generateAsync({type: "blob"})
            .then(function (content) {
                saveAs(content, "export.zip");
            });

    }

    compare(e, doc, index) {
        e.stopPropagation();
        this.setState({result: null, resultIndex: null});
        if (this.state.checkedDocs.length >= 4) {

            let bioArray = [];

            this.state.checkedDocs.forEach((item) => {
                bioArray.push(item.biometric);
            });

            const data = {
                "source": bioArray,
                "target": doc.biometric
            };

            fetch(customConstants.apiUrl + "/signature/evaluate", {
                method: 'POST',
                headers: {
                    Authorization: forensicLib.getAuthHeader(),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(result => {
                    this.setState({result: result.probability, resultIndex: index});
                })
                .catch(err => {
                    this.props.history.push('/');
                });
        } else {
            return NotificationManager.warning(global.strings.min_doc_compare);
        }


    }

    openDetail() {

        if (this.state.optionType.indexOf('show-view') !== -1) {
            forensicLib.drawSignFromData(this.state.dataSource, null, this.state.colors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, undefined, this.state.currentReferenceIndex, null, _this.state);
        }

        if (this.state.optionType.length > 0) {
            if (this.state.optionType.indexOf('show-parameter') !== -1) {
                forensicLib.drawSignFromData(this.state.dataSource, this.state.optionType, this.state.colors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, undefined, this.state.currentReferenceIndex, null, _this.state);
            }
        }

    }

    updateTimeout = (e) => {
        this.setState({inputValue: e.target.value}, () => {
            window.playTimeout = this.state.inputValue;
        });
    };

    stopAnimation() {
        cancelAnimationFrame(window.animation);
        clearTimeout(window.drawTimeout);
    }

    pauseAnimation(toWhat) {
        this.setState({isPlaying: toWhat}, () => {
            window.continueToDraw = toWhat;
        });
    }

    backwardAnimation(toBack) {
        window.toBackSecond = toBack;
    }

    handleCloseFullScreen() {
        _this.setState({fullScreenGraph: false}, () => {

        });
    }

    handleClose() {
        this.setState({show: false, stoped: true}, () => {
            window.break = true;
            clearTimeout(forensicLib.job);
            cancelAnimationFrame(window.animation);
        });
    }

    onChangeDetailType = label => {

        if (this.canUpdate === true) {
            this.canUpdate = false;
            let currentOptions = this.state.optionType;
            let selectedSignInfo = null;
            let updateView = false;
            if (currentOptions.indexOf(label) !== -1) {
                currentOptions.splice(currentOptions.indexOf(label), 1);

            } else {

                if (currentOptions.indexOf('show-view') === -1 && label === 'show-view') {
                    updateView = true;
                }

                currentOptions.push(label);


                if (label === 'show-animation') {


                    this.setState({
                        compareVideoType: 'grid', isPlayingAll: false,
                        playedAll: false
                    });

                    if (this.state.compare === true && _this.state.currentSigns.length > 0) {

                        selectedSignInfo = this.state.sampleJson.map(function (v, k) {

                            if (typeof v !== 'undefined') {

                                if (typeof v.data !== 'undefined') {

                                    return v.data.filter(function (item) {
                                        if (item.signatureId == _this.state.currentSigns[0].signId) {
                                            return item
                                        }
                                    });

                                }

                            }

                        });

                        selectedSignInfo = selectedSignInfo.filter(function (el) {
                            return el != undefined;
                        });

                    }
                    if (currentOptions.indexOf('show-parameter') !== -1) {
                        this.getCompareSignAnimations();
                        this.showReferencePlayer(false);
                    }

                }

                if (label !== 'show-animation' && label !== 'show-value' && label !== 'show-parameter' && label !== 'show-view') {
                    if (currentOptions.indexOf('show-parameter') === -1) {
                        currentOptions.push('show-parameter');
                    }
                }


            }


            this.setState({optionType: currentOptions}, () => {

                //  console.log(this.state.optionType);

                if (updateView === true) {
                    _this.openDetail();
                }

                if (this.state.optionType.length > 0) {
                    if (currentOptions.indexOf('show-parameter') !== -1) {
                        forensicLib.drawSignFromData(this.state.dataSource, this.state.optionType, this.state.colors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, undefined, this.state.currentReferenceIndex, null, _this.state);
                    }
                }

                setTimeout(function () {
                    _this.canUpdate = true;
                }, 1500);

            });

        }
    }

    updateTrash = async () => {

        let currentData = this.state.sampleJson;
        let currentReference = this.state.referenceJson;

        if (currentData !== null) {
            currentData.map(async function (item) {

                if (typeof item.data !== 'undefined' && item.data.length > 0) {

                    item.data.map(function (itemData) {

                        itemData['featureThresholds'] = [];
                        itemData['featureThresholdsMid'] = [];
                        itemData['featureThresholdsHigh'] = [];
                        itemData['featureThresholdsHighest'] = [];
                        Object.keys(itemData.features).forEach(function (features) {

                            let percent = 0;

                            percent = Math.abs(100 *
                                Math.abs((itemData.features[features] - currentReference[0].data[_this.state.currentReferenceIndex].features[features]) / ((itemData.features[features] + currentReference[0].data[_this.state.currentReferenceIndex].features[features]) / 2)))

                            if (percent < customConstants.THRESHOLD) {

                                if (percent === 0) {
                                    itemData['featureThresholds'][features] = 100;
                                } else {
                                    itemData['featureThresholds'][features] = (100 - percent);
                                }

                            } else if (percent < customConstants.THRESHOLD_MID && percent >= customConstants.THRESHOLD) {
                                itemData['featureThresholdsMid'][features] = (100 - percent);
                            } else if (percent < customConstants.THRESHOLD_HIGH && percent >= customConstants.THRESHOLD_MID) {
                                itemData['featureThresholdsHigh'][features] = (100 - percent);
                            } else if (percent < customConstants.THRESHOLD_HIGHEST && percent >= customConstants.THRESHOLD_HIGH) {
                                itemData['featureThresholdsHighest'][features] = (100 - percent);
                            }

                            if (features === 'penDownTime') {
                                try {

                                    percent = Math.abs(100 *
                                        Math.abs((itemData.features['totalTime'] - itemData.features['penDownTime']) - (currentReference[0].data[_this.state.currentReferenceIndex].features['totalTime'] - currentReference[0].data[_this.state.currentReferenceIndex].features['penDownTime'])) / (((itemData.features['totalTime'] - itemData.features['penDownTime']) + (currentReference[0].data[_this.state.currentReferenceIndex].features['totalTime'] - currentReference[0].data[_this.state.currentReferenceIndex].features['penDownTime'])) / 2))


                                    features = 'totalTimeUpTime';

                                    if (percent < customConstants.THRESHOLD) {

                                        if (percent === 0) {
                                            itemData['featureThresholds'][features] = 100;
                                        } else {
                                            itemData['featureThresholds'][features] = (100 - percent);
                                        }

                                    } else if (percent < customConstants.THRESHOLD_MID && percent >= customConstants.THRESHOLD) {
                                        itemData['featureThresholdsMid'][features] = (100 - percent);
                                    } else if (percent < customConstants.THRESHOLD_HIGH && percent >= customConstants.THRESHOLD_MID) {
                                        itemData['featureThresholdsHigh'][features] = (100 - percent);
                                    } else if (percent < customConstants.THRESHOLD_HIGHEST && percent >= customConstants.THRESHOLD_HIGH) {
                                        itemData['featureThresholdsHighest'][features] = (100 - percent);
                                    }


                                } catch (e) {
                                    console.log(e);
                                }

                            }


                        });


                    });

                    //  console.log(item);
                }


            });

        }


        return currentData;
    }

    onChangeSigns = async (label, color, i, hashValue) => {

        let currentOptions = this.state.optionType;

        this.setState({loading: true});

        let currentSigns = this.state.currentSigns;
        let currentColors = this.state.currentColors;
        let currentColorsForTable = this.state.currentColorsForTable;
        const labelArr = label.split('|');
        let hasAlready = false;
        let hasAlreadyColor = false;


        if (currentOptions.indexOf('show-animation') !== -1) {
            currentOptions.splice(currentOptions.indexOf('show-animation'), 1);
        }

        if (typeof this.state.sampleJson[i].data === 'undefined'
            || (typeof this.state.sampleJson[i].data !== 'undefined' && this.state.sampleJson[i].data.length !== this.state.sampleJson[i].signatures.length)) {
            await this.getSampleValues(this.state.sampleJson[i], i, (typeof this.state.sampleJson[i].data !== 'undefined' && this.state.sampleJson[i].data.length !== this.state.sampleJson[i].signatures.length));
            await this.doCommon();


            //console.log('girdiiii miii');
        }

        currentSigns = currentSigns.filter(function (v, k) {
            if (v.signId == labelArr[1]) {
                hasAlready = true;
            }
            return v.signId != labelArr[1];
        });
        currentColors = currentColors.filter(function (v, k) {
            if (v != color) {
                hasAlreadyColor = true;
            }
            return v != color;
        });

        if (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') {
            color = this.state.colors[0];
        }

        if (hasAlready !== true) {
            currentSigns.push({signId: labelArr[1], group: labelArr[0], color: color, hashValue: hashValue});
        }

        if (hasAlready !== true) {
            currentColors.push(color);
            currentColorsForTable[labelArr[1]] = color;
        }

        const currentData = await this.updateTrash();
        this.setState({
            currentSigns: currentSigns,
            simpleJson: currentData,
            currentColors: currentColors,
            currentColorsForTable: currentColorsForTable,
            lastVal: -1,
            loading: false
        }, () => {

            this.getBandwidthValues();

            sessionStorage.setItem('samples', JSON.stringify(currentData));

            if (this.state.optionType.indexOf('show-view') !== -1) {
                forensicLib.drawSignFromData(this.state.dataSource, null, (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? this.state.colors : this.state.currentColors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, undefined, this.state.currentReferenceIndex, null, _this.state);
            }

            if (this.state.optionType.length > 0) {
                if (this.state.optionType.indexOf('show-parameter') !== -1) {
                    forensicLib.drawSignFromData(this.state.dataSource, this.state.optionType, (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? this.state.colors : this.state.currentColors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, undefined, this.state.currentReferenceIndex, null, _this.state);
                }
            }

        });
    }

    getCurrentSignInfo(signatureId) {
        return this.state.currentSigns.filter(function (item) {
            return item.signId == signatureId
        });
    }

    onChangeGroups = label => {


        let currentOptions = this.state.optionType;

        if (currentOptions.indexOf('show-animation') !== -1) {
            // currentOptions.splice(currentOptions.indexOf('show-animation'), 1);
        }

        let currentGroups = this.state.currentGroups;

        if (currentGroups.indexOf(label) !== -1) {

            currentGroups.splice(currentGroups.indexOf(label), 1);

        } else {


            currentGroups.push(label);

        }

        this.setState({currentGroups: currentGroups}, () => {

            this.getBandwidthValues();

            if (this.state.optionType.indexOf('show-view') !== -1) {
                forensicLib.drawSignFromData(this.state.dataSource, null, this.state.colors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, undefined, this.state.currentReferenceIndex, null, _this.state);
            }

            if (this.state.optionType.length > 0) {
                if (this.state.optionType.indexOf('show-parameter') !== -1) {
                    forensicLib.drawSignFromData(this.state.dataSource, this.state.optionType, this.state.colors, this.state.currentGroups, this.state.currentSigns, true, this.state.referenceJson, undefined, this.state.currentReferenceIndex, null, _this.state);
                }
            }

        });
    }

    updateHands() {
        this.setState({showHands: !window.showHands}, () => {
            window.showHands = !window.showHands;
        });
    }

    componentWillMount() {
        this.setState({loading: true});
    }

    openParamter() {
        this.setState({parameterOpened: !this.state.parameterOpened}, () => {
            // _this.openDetail();
        });
    }

    handleCloseReferencePlayer = () => {
        this.setState({showReferencePlayer: false});
    }

    playAllVideos() {
        _this.completedVideo = 0;
        this.setState({isPlayingAll: true, playedAll: true});
        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    v.play();
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }

    stopAllVideos() {
        this.setState({isPlayingAll: false});
        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    v.pause();
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }

    loadAllVideos() {
        this.setState({isPlayingAll: false, playedAll: false});
        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    const {player} = v.getState();
                    v.seek(0);
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }

    switchVideos(type) {
        this.setState({compareVideoType: type, isPlayingAll: false, playedAll: false}, () => {
            if (this.state.compareVideoType === 'layered') {
                this.getCompareSignAnimations(true);
            } else {
                this.getCompareSignAnimations(false);
            }
        });
    }

    forwardVideo(forward) {

        this.player.map(function (v, k) {
            if (v !== null) {
                try {
                    const {player} = v.getState();
                    v.seek(player.currentTime + forward);
                } catch (e) {
                    console.log(e);
                }
            }
        })

    }

    checkIfAllVideoEnded(must) {
        //console.log(must);
        //console.log(this.completedVideo);
        if (must === this.completedVideo) {
            this.setState({isPlayingAll: false});
        }
    }

    showReferencePlayer = async (openWindow = true) => {
        let referenceVideo = this.state.currentVideo;
        const currentGroups = this.state.currentGroups;
        const currentSigns = this.state.currentSigns;

        if (typeof referenceVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] === 'undefined') {
            referenceVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] = await getVideoFromServer(this, [this.state.referenceJson[this.state.currentReferenceIndex].signatures[0].id], (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? [hexToRGB(customConstants.COLORS, true)] : [[hexToRGB(this.state.currentColors[0])]]);
            if (referenceVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]]) {
                this.setState({showReferencePlayer: openWindow, currentVideo: referenceVideo}, () => {
                    if (this.state.compare === true) {
                        setTimeout(function () {
                            $("video").bind("ended", function () {
                                _this.completedVideo++;
                                setTimeout(function () {
                                    _this.checkIfAllVideoEnded(currentSigns.length + 1);
                                }, 500);
                            });
                        }, 1000);
                    }

                });
            }
        } else {
            this.setState({showReferencePlayer: openWindow, currentVideo: referenceVideo}, () => {
                if (this.state.compare === true) {
                    setTimeout(function () {
                        $("video").bind("ended", function () {
                            _this.completedVideo++;
                            setTimeout(function () {
                                _this.checkIfAllVideoEnded(currentSigns.length + 1);
                            }, 500);
                        });
                    }, 1000);
                }
            });
        }
    }

    getCompareSignAnimations = async (asLayered = false) => {

        let currentGroupAnimations = this.state.currentVideo;
        const currentGroups = this.state.currentGroups;
        const currentSigns = this.state.currentSigns;
        let signInfo = [];
        this.completedVideo = 0;

        if (asLayered === false) {

            if (this.state.sampleJson !== null) {
                this.state.sampleJson.map((v, i) => {

                    if (typeof v.data !== 'undefined' && v.data.length > 0) {

                        v.data.map(async function (val, key) {

                                signInfo = _this.getCurrentSignInfo(val.signatureId);

                                if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {
                                    if (typeof currentGroupAnimations[val.signatureId] === 'undefined') {
                                        currentGroupAnimations[val.signatureId] = await getVideoFromServer(_this, [val.signatureId], (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? [hexToRGB(customConstants.COLORS, true)] : [[hexToRGB(_this.state.currentColorsForTable[val.signatureId])]], null, 480);
                                        _this.setState({currentVideo: currentGroupAnimations}, () => {

                                        })
                                    }
                                }

                            }
                        )
                    }

                })
            }

        } else {

            let currentGroupsSigns = [];
            let currentColors = [];
            let currentLayeredColors = [];
            currentGroupAnimations['layered'] = null;
            currentGroupsSigns.push(this.state.referenceJson[this.state.currentReferenceIndex].signatures[0].id);
            currentColors.push([hexToRGB(_this.state.colors[0])]);
            currentLayeredColors.push(hexToRGB(customConstants.COLORS, true));
            await this.state.sampleJson.map((v, i) => {


                if (typeof v.data !== 'undefined' && v.data.length > 0) {

                    v.data.map(async function (val, key) {

                        signInfo = _this.getCurrentSignInfo(val.signatureId);

                        if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {
                            currentLayeredColors.push(hexToRGB(customConstants.COLORS, true));
                            currentGroupsSigns.push(val.signatureId);
                            currentColors.push([hexToRGB(_this.state.currentColorsForTable[val.signatureId])]);
                        }

                    })

                }

            });
            currentGroupAnimations['layered'] = await getVideoFromServer(_this, currentGroupsSigns, (localStorage.getItem('colored') !== null && localStorage.getItem('colored') == 'true') ? currentLayeredColors : currentColors);

            this.setState({currentVideo: currentGroupAnimations});
        }


    }

    insertSignToPDF(k, v) {

        return new Promise(function (resolve) {
            setTimeout(function () {

                html2canvas(document.querySelector("#t-" + v.key + '')).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');


                    if ((k + 1) % 2 === 1) {
                        currentCursorX = 15;
                    } else {
                        currentCursorX = 105;
                    }

                    if (k === 4) {
                        pdf.addPage(null, 'p');
                        currentCursorY = 30;
                    } else if ((k + 1) % 2 === 1 && (k + 1) >= 3) {
                        currentCursorY = currentCursorY + graphHeight;
                    }

                    pdf.addImage(imgData, 'PNG', currentCursorX, currentCursorY, graphWidth, graphHeight);

                    currentSignIndexForReport++;
                    resolve();

                });

            }, 1000);
        });

    }

    async addSigns(isParameterGraph = false) {
        currentSignIndexForReport = 0;
        currentCursorY = currentCursorY + (graphHeight + 10);
        pdf.text(15, currentCursorY, global.strings.compare_signs);
        currentCursorY = currentCursorY + 5;


        if (this.state.selectedCompares.length > 0) {

            await Promise.all([this.state.selectedCompares.map(async function (v, k) {

                const lastSigns = _this.state.currentSigns.filter(function (vInner, k) {
                    return vInner.signId === v.key
                });

                $('#report-results-temp').append('<div style="height: 210px; width:350px" id="t-' + v.key + '"/></div>');

                forensicLib.drawSignFromData(_this.state.dataSource, null, customConstants.COLORS, _this.state.currentGroups, lastSigns, false, lastSigns, '#t-' + v.key, _this.state.currentReferenceIndex, null, _this.state);

                await _this.insertSignToPDF(k, v);

                if (_this.state.selectedFeaturesGraph.length > 0 && _this.state.currentSigns.length - 1 === k) {
                    currentCursorY = 30;
                    pdf.addPage(null, 'p');
                    pdf.text(15, currentCursorY, global.strings.features_graph);
                    currentCursorY += 10;

                    _this.state.selectedFeaturesGraph.map(function (v, k) {

                        $('#report-results-temp').append('<div style="height: 210px; width:350px" id="t-' + v.key + '"/></div>');

                        const referenceJsonIndex = _this.state.referenceJson[0].signatures.map(function (vInner, kInner) {

                            if (vInner.id === v.key) {
                                return kInner;
                            }

                        });


                        forensicLib.drawSignFromData(_this.state.dataSource, v.key, customConstants.COLORS, _this.state.currentGroups, lastSigns, true, _this.state.referenceJson, '#t-' + v.key, referenceJsonIndex[0], null, _this.state);

                        setTimeout(function () {
                            html2canvas(document.querySelector("#t-" + v.key + '')).then(canvas => {
                                const imgData = canvas.toDataURL('image/png');

                                if ((k + 1) % 2 === 1) {
                                    currentCursorX = 15;
                                } else {
                                    currentCursorX = 105;
                                }

                                if (k === 4) {
                                    pdf.addPage(null, 'p');
                                    currentCursorY = 30;
                                } else if ((k + 1) % 2 === 1 && (k + 1) >= 3) {
                                    currentCursorY = currentCursorY + graphHeight;
                                }

                                if ((k + 1) % 2 === 1) {
                                    currentCursorY += 10;
                                }

                                pdf.text(currentCursorX, currentCursorY, global.strings[v.key]);

                                pdf.addImage(imgData, 'PNG', currentCursorX, currentCursorY, graphWidth, graphHeight);


                                //currentCursorY = currentCursorY + graphHeight * (k);

                                if (k === _this.state.selectedFeaturesGraph.length - 1) {
                                    _this.addTable();
                                }

                            });
                        }, 1000);

                    });

                } else {
                    if (_this.state.selectedCompares.length === currentSignIndexForReport) {
                        _this.addTable();
                    }
                }

            })
            ]);

        } else {
            _this.addTable();
        }


    }

    getCsvData(option) {

        var xAxis, yAxis, xAxisLabel, yAxisLabel;
        let header = [];
        let top = [];

        console.log(option);

        if (option === 'velocity') {
            xAxis = 't';
            yAxis = 'v';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'Hız m/sn';
        } else if (option === 'velocityX') {
            xAxis = 't';
            yAxis = 'vx';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'HızX m/sn';
        } else if (option === 'velocityY') {
            xAxis = 't';
            yAxis = 'vy';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'HızY m/sn';
        } else if (option === 'acceleration') {
            xAxis = 't';
            yAxis = 'acc';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'İvme m/sn2';
        } else if (option === 'accelerationX') {
            xAxis = 't';
            yAxis = 'accx';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'İvmeX m/sn2';
        } else if (option === 'accelerationY') {
            xAxis = 't';
            yAxis = 'accy';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'İvmeY m/sn2';
        } else if (option === 'pressure') {
            xAxis = 't';
            yAxis = 'p';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'Basınç (N)';
        } else if (option === 'pendown') {
            xAxis = 't';
            yAxis = 'pd';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'Kalem Aşağı';
        } else if (option === 'xradio') {
            xAxis = 't';
            yAxis = 'x';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'X';
        } else if (option === 'yradio') {
            xAxis = 't';
            yAxis = 'y';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'Y';
        } else if (option === 'azimuth') {
            xAxis = 't';
            yAxis = 'az';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'Kalem Açısı Yatay (Radyan)';
        } else if (option === 'elevation') {
            xAxis = 't';
            yAxis = 'el';
            xAxisLabel = 'Zaman (t)';
            yAxisLabel = 'Kalem Açısı Dikey (Radyan)';
        } else if (option === 'sign') {
            xAxis = 'x';
            yAxis = 'y';
        } else {
            xAxis = 't';
            yAxis = 'v';
        }
        var newSimple = undefined;
        const signs = [];
        var data = [];
        const max = [];
        let tempData = [];
        var newSimple = $.extend(true, [], this.state.simpleJson);

        newSimple.push(this.state.dataSource[0]);

        this.state.currentSigns.map(function (v, k) {
            console.log(v);
            if (_this.state.currentGroups.includes(v.group)) {
                signs.push(v.signId);
            }
        });

        signs.push(_this.state.dataSource[0].data[_this.state.currentReferenceIndex].signatureId);

        console.log(signs);
        console.log(newSimple);

        try {
            newSimple.map(function (v, k) {
                v.data.map(function (ve, ke) {
                    if (signs.includes(ve.signatureId)) {
                        data.push(ve);
                        max.push(ve.detail[xAxis].length);
                    }
                });
            });
        } catch (e) {
            console.log(e);
        }

        console.log(max);
        console.log(data);


        const maxValue = Math.max.apply(Math, max);

        data.map(function (v, k) {
            for (var i = 0; i < maxValue; i++) {
                if (v.detail[xAxis][i] === undefined) {
                    v.detail[xAxis][i] = '';
                    v.detail[yAxis][i] = '';
                }
            }
        });

        data.map(function (v, k) {
            if (k === 0) {
                console.log(data);
                tempData.push([data[k].hashValue, '']);
                tempData.push([xAxisLabel, yAxisLabel]);
            }
            v.detail[xAxis].map(function (v1, k1) {

                if (k > 0 && k1 === 0) {
                    tempData[0].push(data[k].hashValue);
                    tempData[0].push('');
                    tempData[1].push(xAxisLabel);
                    tempData[1].push(yAxisLabel);
                }

                if (k === 0) {
                    tempData.push([v1, v.detail[yAxis][k1]]);
                } else {
                    tempData[(k1 + 2)].push(v1);
                    tempData[(k1 + 2)].push(v.detail[yAxis][k1]);
                }

            })
        });


        console.log(tempData);

        let csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(tempData.map(e => e.join(";")).join("\n"));

        window.open(csvContent);


    }

    addTable() {

        if (this.state.selectedFeatures.length > 0) {
            $('#all-parameters tbody tr:not(#f-signatureId)').hide();

            this.state.selectedFeatures.map(function (v, k) {
                $('#f-' + v.key).show();
            });

        }

        pdf.addPage(null, 'l');

        try {
            pdf.autoTable({
                html: '#compare-legend', styles: {font: "RobotoMedium", fontSize: 6}, willDrawCell: function (data) {
                },
                didParseCell: function (cell, opts) {

                    let m = cell.cell.raw.style.backgroundColor.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
                    if (m) {
                        cell.cell.styles.fillColor = [parseInt(m[1]), parseInt(m[2]), parseInt(m[3])];
                    }

                }
            });
            pdf.autoTable({
                html: '#all-parameters', styles: {font: "RobotoMedium", fontSize: 6}, willDrawCell: function (data) {

                    if (data && typeof data.cell !== 'undefined' && typeof data.cell.styles !== 'undefined' && data.cell.styles.fillColor.length === 3) {
                        // console.log(data.cell.styles.fillColor);
                        // pdf.setFillColor(data.cell.styles.fillColor);
                    }


                },
                didParseCell: function (cell, opts) {

                    let m = cell.cell.raw.style.backgroundColor.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
                    if (m) {
                        cell.cell.styles.fillColor = [parseInt(m[1]), parseInt(m[2]), parseInt(m[3])];
                    }

                }
            });
        } catch (e) {
            console.log(e);
        }

        setTimeout(function () {
            $('#all-parameters tbody tr').show();
            pdf.save(global.strings.reportResult + ' ' + sessionStorage.getItem('caseId') + ".pdf");
            _this.setState({loading: false, open: false});
        }, 3000);

    }

    getReport = async () => {

        if (this.state.selectedReferences.length === 0 || this.state.selectedCompares.length === 0) {
            return NotificationManager.error(global.strings.select_sign_at_least_for_report);
        }

        function getBase64FromImageUrl(url) {

            var img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');

            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = this.width;
                canvas.height = this.height;

                var ctx = canvas.getContext("2d");
                ctx.drawImage(this, 0, 0);

                var dataURL = canvas.toDataURL("image/png");
                pdf.addImage(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""), "png", 15, 15, this.width/10, this.height/10);

            };

            img.src = url;

        }

        pdf = new jsPDF();
        currentCursorY = 75;
        currentCursorX = 15;
        currentReferenceIndexForReport = 0;
        currentSignIndexForReport = 0;
        $('#report-results-temp').html('');
        //  this.props.history.push('/dashboard/compare');
        this.setState({loading: true});
        pdf.setFontSize(12);
        pdf.addFileToVFS('RobotoMedium-normal.ttf', font);
        pdf.addFont('RobotoMedium-normal.ttf', 'RobotoMedium', 'normal');
        pdf.setFontSize(18);
        pdf.setFont('RobotoMedium','normal');
        pdf.text(50, 20, customConstants.appTitle);
        pdf.setFontSize(12);
        pdf.text(15, 50, global.strings.expertIdWithoutPlaceholder + ': ' + this.state.specialityNo);
        pdf.text(15, 55, global.strings.caseMargin + ': ' + this.state.requestAuthority);
        pdf.text(15, 60, global.strings.caseNo + ': ' + this.state.caseId);
        pdf.text(123, 50, global.strings.investigationDate +':');
        pdf.text(160, 50, moment(new Date(sessionStorage.getItem('date'))).format('DD-MM-YYYY HH:mm'));
        pdf.text(125, 55, global.strings.exportDate +':');
        pdf.text(160, 55, moment(new Date()).format('DD-MM-YYYY HH:mm'));
        getBase64FromImageUrl("/assets/images/logo-" + customConstants.appName + "-black.png");

        pdf.text(15, currentCursorY, global.strings.reference);

        currentCursorY += 10;

        this.state.selectedReferences.map(function (v, k) {

            $('#report-results-temp').append('<div style="height: 210px; width:350px" id="t-' + v.key + '"/></div>');

            const referenceJsonIndex = _this.state.referenceJson[0].signatures.map(function (vInner, kInner) {


                if (vInner.id === v.key) {
                    return kInner;
                }

            });

            forensicLib.drawSignFromData(_this.state.dataSource, null, customConstants.COLORS, _this.state.currentGroups, _this.state.referenceJson, true, _this.state.referenceJson, '#t-' + v.key, referenceJsonIndex[0], null, _this.state);

            setTimeout(function () {
                html2canvas(document.querySelector("#t-" + v.key + '')).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');

                    if ((k + 1) % 2 === 1) {
                        currentCursorX = 15;
                    } else {
                        currentCursorX = 105;
                    }

                    if ((k + 1) % 2 === 1 && (k + 1) >= 3) {
                        currentCursorY = currentCursorY + graphHeight;
                    }

                    pdf.addImage(imgData, 'PNG', currentCursorX, currentCursorY, graphWidth, graphHeight);
                    //currentCursorY = currentCursorY + graphHeight * (k);
                    currentReferenceIndexForReport++;
                    if (_this.state.selectedReferences.length === currentReferenceIndexForReport) {
                        _this.addSigns();
                    }
                });
            }, 1000);

        });


    }

    openReport() {

        const checkedItems = $('.dropdown-filters-signs').find('input:checked:not(.top-level-checkebox)');
        let lastSelectedCompares = [];

        try {
            checkedItems.each(function (k, v) {
                lastSelectedCompares.push({
                    key: $(v).attr('id').replace('s-', ''),
                    label: $(v).attr('id').replace('s-', ''),
                    groupName: "",
                    groupId: ""
                });
            });
        } catch (e) {
            console.log(e);
        }

        let referenceFile = sessionStorage.getItem('reference');
        let referenceJson = JSON.parse(referenceFile);
        let toInsertReferences = [];
        let toInsertCompares = [];

        let sampleJson;
        let sampleFile = sessionStorage.getItem('samples');

        if (sampleFile !== null) {
            sampleJson = JSON.parse(sampleFile);
        }

        sampleJson.map(function (v, k) {
            v.signatures.map(function (vSign, kSign) {
                toInsertCompares.push({key: vSign.id, label: vSign.hashValue, groupId: v.id, groupName: v.groupName});
            });
        });


        referenceJson.map(function (v, k) {
            v.signatures.map(function (vSign, kSign) {
                toInsertReferences.push({key: vSign.id, label: vSign.hashValue});
            })
        });


        this.setState({
            open: true,
            currentReferences: toInsertReferences,
            currentCompares: toInsertCompares,
            selectedCompares: lastSelectedCompares
        }, () => {

        });
    }

    handleClose = () => {
        this.setState({open: false}, () => {

        });
    }

    setReferences(signs) {

        if (this.state.selectedReferences.length === 1) {
            return NotificationManager.error(global.strings.cannot_add_more_reference);
        } else {
            this.setState({selectedReferences: signs});
        }

    }

    setCompares(signs, event, reason) {

        if (reason === 'remove-option') {
            const diff = _.difference(_this.state.selectedCompares, signs);
            //   console.log(diff[0]);
            // console.log($('.dropdown-filters-signs').find('input#s-' + diff[0].id + ':not(.top-level)'));
            $('.dropdown-filters-signs').find('input#s-' + diff[0].key + ':not(.top-level)').parents('label').click();
        }

        if (this.state.selectedCompares.length === 6) {
            return NotificationManager.error(global.strings.cannot_add_more_sign);
        } else {
            this.setState({selectedCompares: signs}, () => {
                _this.state.selectedCompares.map(function (v, k) {

                    try {

                        const lastObject = $('.dropdown-filters-signs').find('input#s-' + v.key + ':not(.top-level):not(:checked)');

                        if (lastObject.parents('.parameters').parent().find('h6').find('.top-level input').is(':checked') === false) {
                            lastObject.parents('.parameters').parent().find('h6').find('label.top-level').click();
                        }

                        if (_this.state.compare === true && typeof lastObject !== 'undefined' && lastObject.length > 0) {

                            if (typeof lastObject !== 'undefined') {

                                lastObject.each(function (k, v) {

                                    setTimeout(function () {
                                        $(v).parents('label').click();
                                    }, 500);

                                })

                            }
                        } else {
                            _this.renewGraph();
                        }

                    } catch (e) {
                        console.log(e);
                    }

                })
            });
        }

    }

    setFeatures(signs) {
        this.setState({selectedFeatures: signs});
    }

    setFeaturesGraph(graphs) {

        console.log(graphs)
        console.log(this.state.optionType)

        var optionType = this.state.optionType;

        graphs.map(function (v, k) {

            if (!optionType.includes(v.key)) {
                _this.onChangeDetailType(v.key)
            }

        });

        this.setState({selectedFeaturesGraph: graphs}, () => {

        });
    }

    handleTypeChange = (event) => {
        this.setState({selectedType: event.target.value});
    }

    render() {
        const currentOptions = this.state.optionType;
        const currentGroups = this.state.currentGroups;
        const currentSigns = this.state.currentSigns;
        let signInfo = [];
        let currentIndex = 0;


        return (
            <div>
                <Loading style={{zIndex: 9999}} loading={this.state.loading} background="rgba(255,255,255,.7)"
                         loaderColor="#0D386B"/>
                <Dialog
                    fullWidth={'md'}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{global.strings.report_title}</DialogTitle>
                    <DialogContent>

                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6} md={6}>
                                <Autocomplete
                                    noOptionsText={global.strings.no_sign_left}
                                    multiple
                                    blurOnSelect={true}
                                    limitTags={1}
                                    disabled={(this.state.loading)}
                                    filterSelectedOptions
                                    id="checkboxes-references"
                                    options={this.state.currentReferences}
                                    groupBy={(option) => option.groupId}
                                    disableCloseOnSelect
                                    value={this.state.selectedReferences}
                                    onChange={(event, value) => this.setReferences(value)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(option, {inputValue, selected}) => {

                                        const matches = match(option.label, inputValue);
                                        const parts = parse(option.label, matches);

                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {parts.map((part, index) => (
                                                    <span key={index}
                                                          style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                                                ))}
                                            </React.Fragment>);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                onDelete={this.handleDelete}
                                                variant="outlined"
                                                label={option.label}
                                                size="medium"
                                                avatar={<Gesture/>}
                                                {...getTagProps({index})}
                                            />
                                        ))
                                    }
                                    style={{width: '%100'}}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined"
                                                   label={global.strings.referenceSign}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} md={6}>
                                <Autocomplete
                                    noOptionsText={global.strings.no_sign_left}
                                    multiple
                                    blurOnSelect={true}
                                    limitTags={4}
                                    filterSelectedOptions
                                    id="checkboxes-compares"
                                    disabled={(this.state.loading)}
                                    options={this.state.currentCompares}
                                    groupBy={(option) => option.groupName}
                                    disableCloseOnSelect
                                    getOptionSelected={(option, value) => option.key === value.key}
                                    value={this.state.selectedCompares}
                                    onChange={(event, value, reason) => this.setCompares(value, event, reason)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(option, {inputValue, selected}) => {

                                        const matches = match(option.label, inputValue);
                                        const parts = parse(option.label, matches);

                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {parts.map((part, index) => (
                                                    <span key={index}
                                                          style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                                                ))}
                                            </React.Fragment>);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                onDelete={this.handleDelete}
                                                variant="outlined"
                                                label={option.label}
                                                size="medium"
                                                avatar={<Gesture/>}
                                                {...getTagProps({index})}
                                            />
                                        ))
                                    }
                                    style={{width: '%100'}}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined"
                                                   label={global.strings.compare_signs}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                                <Autocomplete
                                    noOptionsText={global.strings.no_parameter_left}
                                    multiple
                                    filterSelectedOptions
                                    id="checkboxes-features"
                                    options={this.state.features}
                                    disableCloseOnSelect
                                    limitTags={4}
                                    value={this.state.selectedFeatures}
                                    onChange={(event, value) => this.setFeatures(value)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(option, {inputValue, selected}) => {

                                        const matches = match(option.label, inputValue);
                                        const parts = parse(option.label, matches);

                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {parts.map((part, index) => (
                                                    <span key={index}
                                                          style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                                                ))}
                                            </React.Fragment>);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                onDelete={this.handleDelete}
                                                variant="outlined"
                                                label={option.label}
                                                size="medium"
                                                avatar={<Compare/>}
                                                {...getTagProps({index})}
                                            />
                                        ))
                                    }
                                    style={{width: '%100'}}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined"
                                                   label={global.strings.features}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                                <Autocomplete
                                    noOptionsText={global.strings.no_parameter_left}
                                    multiple
                                    filterSelectedOptions
                                    id="checkboxes-features"
                                    options={this.state.features_graph}
                                    disableCloseOnSelect
                                    limitTags={4}
                                    value={this.state.selectedFeaturesGraph}
                                    onChange={(event, value) => this.setFeaturesGraph(value)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(option, {inputValue, selected}) => {

                                        const matches = match(option.label, inputValue);
                                        const parts = parse(option.label, matches);

                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {parts.map((part, index) => (
                                                    <span key={index}
                                                          style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                                                ))}
                                            </React.Fragment>);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                onDelete={this.handleDelete}
                                                variant="outlined"
                                                label={option.label}
                                                size="medium"
                                                avatar={<Compare/>}
                                                {...getTagProps({index})}
                                            />
                                        ))
                                    }
                                    style={{width: '%100'}}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined"
                                                   label={global.strings.features_graph}
                                        />
                                    )}
                                />
                                <Alert severity="info" style={{marginTop: 10}}>{global.strings.if_blank_all}</Alert>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                                <div id={'report-results-temp'} style={{maxHeight: 0, height: 0}}>

                                </div>
                            </Grid>
                            {/*<Grid item xs={12} lg={12} md={12}>
                                <FormControl>
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                        <FormControlLabel value="pdf" control={<Radio onChange={this.handleTypeChange}
                                                                                      checked={this.state.selectedType === 'pdf'}
                                                                                      color="primary"/>} label="PDF"/>
                                        <FormControlLabel value="word" control={<Radio onChange={this.handleTypeChange}
                                                                                       checked={this.state.selectedType === 'word'}
                                                                                       color="primary"/>} label="Word"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>*/}
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        < Button
                            onClick={this.handleClose}
                            color="primary">
                            {global.strings.cancel}
                        </Button>
                        <
                            Button
                            onClick={this.getReport}
                            disabled={(this.state.loading) ? 'disabled' : null}
                            color="primary">
                            {(this.state.loading) ?
                                <CircularProgress size={24} color={'#ffffff'}/> : global.strings.create}
                        </Button>
                    </DialogActions>
                </Dialog>

                <div className="navbar navbar-expand navbar-light bg-white topbar  static-top shadow" id={'topBarMenu'}>

                    <div onClick={() => logout(this)} lang="en" className="logout-form-btn">
                        {global.strings.logout}
                    </div>

                </div>
                <div id="wrapper">

                    <LeftMenu enableAdminMenu={true}/>

                    <Modal className="modal-container custom-map-modal" show={this.state.fullScreenGraph}
                           onHide={this.handleCloseFullScreen}>
                        <Modal.Header closeButton>
                            <Modal.Title class={'sign-animation-modal-header'}>

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div key={'par-fullscreen'} className={'standard-box-full'}>
                                <div className={'results-detail'} key={'fullscreen-graph'}>

                                    <div className="lbTime"
                                         id="yTime"> {global.strings.time}</div>
                                    <div id="xChange"> {global.strings[this.state.fullScreenType]}</div>
                                    <div className="detailGraph"
                                         id={'vardata-' + 'fullscreen'}></div>
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>

                    <Modal size={'lg'} show={this.state.showReferencePlayer} onHide={this.handleCloseReferencePlayer}>
                        <Modal.Header closeButton>
                            <Modal.Title class={'sign-animation-modal-header'}>
                                <strong>{global.strings.signatureId}:</strong> {(typeof this.state.referenceJson !== 'undefined' && this.state.referenceJson !== null && typeof this.state.referenceJson[0] !== 'undefined' && typeof this.state.referenceJson[0].signatures !== 'undefined') ? this.state.referenceJson[0].signatures[0].hashValue : ''}<br/>
                                <strong>{global.strings.signGroup}:</strong> {global.strings.referenceSign}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className={'analyze'}>
                                <div className="row">
                                    {(typeof this.state.referenceJson !== 'undefined') ?
                                        <Player
                                            autoPlay={true}
                                            playsInline
                                        >
                                            <source
                                                src={(typeof this.state.referenceJson !== 'undefined' && this.state.referenceJson !== null && typeof this.state.referenceJson[0] !== 'undefined' && typeof this.state.currentVideo[this.state.referenceJson[0].signatures[0]] !== 'undefined') ? this.state.currentVideo[this.state.referenceJson[0].signatures[0]] : null}
                                                type={'video/mp4'}/>
                                            <BigPlayButton position="center"/>
                                            <LoadingSpinner/>
                                            <ControlBar autoHide={true} autoHideTime={3}>
                                                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]}/>
                                            </ControlBar>
                                            <Shortcut clickable={false} shortcuts={shortCuts}/>
                                        </Player> : null}
                                </div>
                            </div>

                        </Modal.Body>

                    </Modal>

                    <div className={'main-container'}>
                        <div className={'row'}>

                            <div style={{'backgroundColor': '#fff', 'margin': 0, 'width': '100%'}}>

                                <div className={'col-12'} style={{'display': 'flow-root'}}>
                                <span
                                    className={'dashboard-header'}>{(this.state.compare === true) ? global.strings.compareSigns.toUpperCase() : global.strings.subject + ' ' + global.strings.signExamination}</span>
                                    {/*<span className={'print-graph'}>
                                            <img width={16} height={16}
                                                 src={'/assets/images/print.svg'}/> {global.strings.printGraph}</span>*/}
                                    {(this.state.compare === true) ?
                                        <div style={{textAlign: 'right', float: 'right', margin: 10}}>
                                            <Button onClick={() => this.openReport()} size={'medium'}
                                                    variant="contained" style={{}} color="primary">
                                                {global.strings.createReport}
                                            </Button>
                                        </div> : null}
                                </div>

                            </div>


                        </div>

                        <div className={'row'}>
                            <div className={'col-12'}>

                                <div className={'standard-box'}>
                                    <FormControl component="fieldset" style={{marginTop: 6}}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch color="primary"
                                                                 checked={this.state.coloredMode}
                                                                 onChange={this.handleChange} name="colored"/>}
                                                label={<span
                                                    className={'with-colors'}>{global.strings.enable_colored_mode}</span>}
                                            />
                                        </FormGroup>
                                    </FormControl>

                                    <div className={'print-graph'}>

                                        <label>{global.strings.reviewScreens}</label>
                                        <div
                                            className={'graph-selection'}>
                                            {global.strings.options}
                                            <div className={'dropdown-filters-options'}>
                                                <div
                                                    className={'results-graph'}>
                                                    <div className={'row'}>
                                                        <h6 className={'box-header'}>
                                                            <label htmlFor={'show-view'}>
                                                                <input type="checkbox" id="show-view"
                                                                       onChange={(value) => this.onChangeDetailType('show-view')}
                                                                       checked={(currentOptions.indexOf('show-view') !== -1) ? true : false}
                                                                       name="show-view"/>
                                                                {global.strings.view}</label></h6>
                                                        <h6 className={'box-header'}>
                                                            <label htmlFor={'show-animation'}>
                                                                <input type="checkbox" id="show-animation"
                                                                       onChange={(value) => this.onChangeDetailType('show-animation')}
                                                                       checked={(currentOptions.indexOf('show-animation') !== -1) ? true : false}
                                                                       name="show-animation"/>
                                                                {global.strings.animation}</label></h6>
                                                        <h6 className={'box-header'}>
                                                            <label htmlFor={'show-parameter'}>
                                                                <input type="checkbox" id="show-parameter"
                                                                       onChange={(value) => this.onChangeDetailType('show-parameter')}
                                                                       checked={(currentOptions.indexOf('show-parameter') !== -1) ? true : false}
                                                                       name="show-parameter"/>
                                                                {global.strings.parameter}</label></h6>
                                                        <div className="col-6 parameters">
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="checkbox" id="xradio"
                                                                           onChange={(value) => this.onChangeDetailType('xradio')}
                                                                           checked={(currentOptions.indexOf('xradio') !== -1) ? true : false}

                                                                           value="xradio"
                                                                           name="variables"/>
                                                                    X</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 parameters">
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="checkbox" id="yradio"
                                                                           onChange={(value) => this.onChangeDetailType('yradio')}
                                                                           checked={(currentOptions.indexOf('yradio') !== -1) ? true : false}

                                                                           value="yradio"
                                                                           name="variables"/>
                                                                    Y</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 parameters">
                                                            <div className="radio">

                                                                <label>
                                                                    <input type="checkbox" id="pressure"
                                                                           onChange={(value) => this.onChangeDetailType('pressure')}
                                                                           checked={(currentOptions.indexOf('pressure') !== -1) ? true : false}

                                                                           value="pressure"
                                                                           name="variables"/>
                                                                    {global.strings.pressure_label}</label>
                                                            </div>

                                                        </div>

                                                        <div className="col-12 parameters">
                                                            <div className="radio">

                                                                <label>
                                                                    <input type="checkbox" id="azimuth"
                                                                           onChange={(value) => this.onChangeDetailType('azimuth')}
                                                                           checked={(currentOptions.indexOf('azimuth') !== -1) ? true : false}

                                                                           value="azimuth"
                                                                           name="variables"/>
                                                                    {global.strings.azimuth_label}</label>
                                                            </div>
                                                            <div className="radio">

                                                                <label>
                                                                    <input type="checkbox" id="elevation"
                                                                           onChange={(value) => this.onChangeDetailType('elevation')}
                                                                           checked={(currentOptions.indexOf('elevation') !== -1) ? true : false}

                                                                           value="elevation"
                                                                           name="variables"/>
                                                                    {global.strings.elevation_label}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 parameters">

                                                            <div className="radio">

                                                                <label>
                                                                    <input type="checkbox" id="pendown"
                                                                           onChange={(value) => this.onChangeDetailType('pendown')}
                                                                           checked={(currentOptions.indexOf('pendown') !== -1) ? true : false}

                                                                           value="pendown"
                                                                           name="variables"/>
                                                                    {global.strings.pendown_label}</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 parameters">
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="checkbox" id="velocity"
                                                                           onChange={(value) => this.onChangeDetailType('velocity')}
                                                                           checked={(currentOptions.indexOf('velocity') !== -1) ? true : false}
                                                                           name="variables"/>
                                                                    {global.strings.velocity_label}</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-6 parameters">
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="checkbox" id="velocityX"
                                                                           onChange={(value) => this.onChangeDetailType('velocityX')}
                                                                           checked={(currentOptions.indexOf('velocityX') !== -1) ? true : false}
                                                                           name="variables"/>
                                                                    {global.strings.velocityX_label}</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-6 parameters">
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="checkbox" id="velocityY"
                                                                           onChange={(value) => this.onChangeDetailType('velocityY')}
                                                                           checked={(currentOptions.indexOf('velocityY') !== -1) ? true : false}

                                                                           value="velocityY"
                                                                           name="variables"/>
                                                                    {global.strings.velocityY_label}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 parameters">
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="checkbox" id="acceleration"
                                                                           onChange={(value) => this.onChangeDetailType('acceleration')}
                                                                           checked={(currentOptions.indexOf('acceleration') !== -1) ? true : false}

                                                                           value="acceleration"
                                                                           name="variables"/>
                                                                    {global.strings.acceleration_label}</label>
                                                            </div>


                                                        </div>
                                                        <div className="col-6 parameters">

                                                            <div className="radio">

                                                                <label>
                                                                    <input type="checkbox"
                                                                           id="accelerationX"
                                                                           onChange={(value) => this.onChangeDetailType('accelerationX')}
                                                                           checked={(currentOptions.indexOf('accelerationX') !== -1) ? true : false}

                                                                           value="accelerationX"
                                                                           name="variables"/>
                                                                    {global.strings.accelerationX_label}</label>
                                                            </div>


                                                        </div>
                                                        <div className="col-6 parameters">
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="checkbox"
                                                                           id="accelerationY"
                                                                           onChange={(value) => this.onChangeDetailType('accelerationY')}
                                                                           checked={(currentOptions.indexOf('accelerationY') !== -1) ? true : false}

                                                                           value="accelerationY"
                                                                           name="variables"/>
                                                                    {global.strings.accelerationY_label}</label>
                                                            </div>
                                                        </div>


                                                        <h6 className={'box-header'}>
                                                            <label htmlFor={'show-value'}>
                                                                <input type="checkbox" id="show-value"
                                                                       onChange={(value) => this.onChangeDetailType('show-value')}
                                                                       checked={(currentOptions.indexOf('show-value') !== -1) ? true : false}
                                                                       name="show-value"/>
                                                                {global.strings.values}</label></h6>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    {(this.state.compare === true) ?
                                        <div className={'print-graph'}>
                                            {/*<div className={'ai-score'}>*/}
                                            {/*{global.strings.aiScore} : {this.state.score}%<br/>*/}
                                            {/*<Progress percent={this.state.score} status="success"/>*/}
                                            {/*</div>*/}

                                            <label>{global.strings.selectCompareSigns}</label>
                                            <div className={'graph-selection'}
                                                 style={{'marginRight': '15px'}}>{global.strings.signs}
                                                <div className={'dropdown-filters-signs'}>
                                                    <div
                                                        className={'results-graph'}>
                                                        <div className={'row'}>

                                                            {(this.state.sampleJson !== null && this.state.sampleJson.length > 0 && this.state.sampleJson[0].id !== null && this.state.sampleJson[0].id !== '') ?

                                                                this.state.sampleJson.map((v, i) => {

                                                                    return (
                                                                        <div key={'outer-groups-' + i}>

                                                                            <div key={'groups-' + i}>
                                                                                <h6 className={'box-header'}>
                                                                                    <label
                                                                                        className={'top-level'}
                                                                                        htmlFor={'g-' + v.groupName}>
                                                                                        <input type="checkbox"
                                                                                               className={'top-level-checkebox'}
                                                                                               id={"g-" + v.groupName}
                                                                                               onChange={(value) => this.onChangeGroups(v.groupName)}
                                                                                               checked={(currentGroups.indexOf(v.groupName) !== -1) ? true : false}
                                                                                               name={'g-' + v.groupName}/>
                                                                                        {v.groupName}</label>
                                                                                </h6>

                                                                                {(typeof v.signatures !== 'undefined' && v.signatures.length > 0) ?

                                                                                    v.signatures.map((s, inner) => {
                                                                                        return (<div
                                                                                            key={'signs-' + inner}
                                                                                            className="col-12 parameters">
                                                                                            <div className="radio">
                                                                                                <label
                                                                                                    style={{'color': this.state.colors[i + inner + 1]}}>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id={'s-' + s.id}
                                                                                                        onChange={(value) => this.onChangeSigns(v.groupName + '|' + s.id, this.state.colors[i + inner + 1], i, s.hashValue)}
                                                                                                        checked={
                                                                                                            _isContains(currentSigns, s.id)
                                                                                                                ? true : false}
                                                                                                        name={'s-' + v.groupName}/>
                                                                                                    {s.hashValue}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>)
                                                                                    }) : null}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }) : null}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        : null}
                                    <div className={'print-graph'}>

                                        <label style={{color: '#f00'}}>{global.strings.referenceSigns} </label>
                                        <div className={'graph-selection'}
                                             style={{'marginRight': '15px'}}>{global.strings.signs}
                                            <div className={'dropdown-filters-references'}>
                                                <div
                                                    className={'results-graph'}>
                                                    <div className={'row'}>

                                                        {(this.state.referenceJson !== null) ?

                                                            this.state.referenceJson[0].signatures.map((v, i) => {

                                                                return (
                                                                    <div key={'outer-groups-' + i}>

                                                                        <div key={'groups-' + i}>
                                                                            <h6 className={'box-header'}>
                                                                                <label
                                                                                    htmlFor={'g-' + v.id}>
                                                                                    <input type="radio"
                                                                                           id={"g-" + v.id}
                                                                                           onChange={(value) => this.updateReferenceIndex(i)}
                                                                                           checked={(this.state.currentReferenceIndex === i) ? true : false}
                                                                                           name={'g-' + v.id}/>
                                                                                    {v.hashValue}</label>
                                                                            </h6>

                                                                        </div>
                                                                    </div>
                                                                );
                                                            }) : null}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>

                                {(currentOptions.indexOf('show-view') !== -1) ?
                                    <div className={'standard-box'}>

                                        {(this.state.dataSource !== null) ?
                                            <div className={'analyze'}>
                                                <div className="row">

                                                    <div className="col-12">
                                                        <h6 className={'graph-title'}>{global.strings.dashboard.toUpperCase()} | <a
                                                            className={'playButton'}
                                                            onClick={() => this.showReferencePlayer()}><FontAwesomeIcon
                                                            icon={faPlayCircle}/> &nbsp;{global.strings.playAnimation}
                                                        </a>
                                                        </h6>
                                                        {(this.state.dataSource.length > 0) ?
                                                            <div>
                                                                <div className={'results-graph'}>

                                                                    <div ref={(divElement) => this.xydata = divElement}
                                                                         id="xydata"
                                                                         style={{height: this.state.currentHeight}}></div>
                                                                    <div id="xPos">X (cm)</div>
                                                                    <div id="yPos">Y (cm)</div>
                                                                </div>

                                                            </div>
                                                            : null}
                                                    </div>

                                                </div>

                                            </div>
                                            : null}

                                    </div> : null}

                                {(currentOptions.indexOf('show-animation') !== -1) ?
                                    <div className={'standard-box'}>
                                        <div className={'analyze'}>
                                            <div className="row">

                                                <div className="col-12">
                                                    <h6 className={'graph-title'}>{global.strings.animation.toUpperCase()}
                                                        {(this.state.compare === true && this.state.sampleJson !== null && currentSigns !== null && currentSigns.length > 0) ?
                                                            <div className={'switch-videos'}>
                                                                {(this.state.currentGroups.length > 0) ?
                                                                    <FontAwesomeIcon
                                                                        size={'1x'}
                                                                        title={global.strings.layeredMode}
                                                                        color={(this.state.compareVideoType === 'layered') ? '#0d386b' : '#9c9c9c'}
                                                                        onClick={() => this.switchVideos('layered')}
                                                                        icon={faLayerGroup}/> : null}
                                                                <FontAwesomeIcon
                                                                    size={'1x'}
                                                                    color={(this.state.compareVideoType === 'grid') ? '#0d386b' : '#9c9c9c'}
                                                                    title={global.strings.gridMode}
                                                                    onClick={() => this.switchVideos('grid')}
                                                                    icon={faThLarge}/>
                                                            </div> : null}
                                                    </h6>
                                                    <div ref={this.videoOuter} className={'video-outer'}
                                                    >
                                                        {(this.state.compare === true && this.state.sampleJson !== null && ((currentSigns !== null && currentSigns.length) || (this.state.referenceJson !== null && typeof this.state.currentVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] !== 'undefined')) > 0 && this.state.compareVideoType === 'grid') ?

                                                            <div className={'row'}>

                                                                <div className={'col-6'} style={{
                                                                    marginBottom: 20
                                                                }}
                                                                     key={'animation-video-reference'}>
                                                                    <div
                                                                        className={'multi-view-video-title'}>
                                                                        <FontAwesomeIcon
                                                                            size={'1x'}
                                                                            title={global.strings.layeredMode}
                                                                            color={_this.state.currentColors[0]}
                                                                            icon={faSquareFull}/> {global.strings.referenceSign}
                                                                    </div>
                                                                    <div style={{
                                                                        border: '3px solid ' + this.state.currentColors[0]
                                                                    }}>
                                                                        {(this.state.referenceJson !== null && typeof this.state.currentVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] !== 'undefined') ?
                                                                            <Player
                                                                                ref={player => {
                                                                                    this.player[0] = player;
                                                                                }}
                                                                                autoPlay={false}
                                                                                playsInline
                                                                                muted={true}

                                                                            >
                                                                                <source
                                                                                    src={(this.state.referenceJson !== null && typeof this.state.currentVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] !== 'undefined') ? this.state.currentVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] : null}
                                                                                    type={'video/mp4'}/>
                                                                                <BigPlayButton
                                                                                    position="center"/>
                                                                                <LoadingSpinner/>
                                                                                <ControlBar autoHide={true}
                                                                                            autoHideTime={3}>
                                                                                    <PlaybackRateMenuButton
                                                                                        rates={[5, 2, 1, 0.5, 0.1]}/>
                                                                                </ControlBar>
                                                                                <Shortcut clickable={false}
                                                                                          shortcuts={shortCuts}/>
                                                                            </Player> : null}
                                                                    </div>
                                                                </div>

                                                                {this.state.sampleJson.map(function (v, k) {

                                                                    if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                        return v.data.map((val, key) => {

                                                                            signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                            if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                if ((typeof _this.state.currentVideo[val.signatureId] !== 'undefined')) {

                                                                                    return <div className={'col-6'}
                                                                                                style={{paddingBottom: 15}}
                                                                                                key={'animation-video-' + key}>
                                                                                        <div
                                                                                            className={'multi-view-video-title'}>
                                                                                            <FontAwesomeIcon
                                                                                                size={'1x'}
                                                                                                title={global.strings.layeredMode}
                                                                                                color={_this.state.currentColorsForTable[val.signatureId]}
                                                                                                icon={faSquareFull}/> {val.signatureId}
                                                                                        </div>
                                                                                        <div style={{
                                                                                            border: '3px solid ' + _this.state.currentColorsForTable[val.signatureId]
                                                                                        }}>
                                                                                            <Player
                                                                                                ref={player => {
                                                                                                    _this.player[key + 1] = player;
                                                                                                }}
                                                                                                autoPlay={false}
                                                                                                playsInline
                                                                                                muted={true}
                                                                                            >
                                                                                                <source
                                                                                                    src={(typeof _this.state.currentVideo[val.signatureId] !== 'undefined') ? _this.state.currentVideo[val.signatureId] : null}
                                                                                                    type={'video/mp4'}/>
                                                                                                <BigPlayButton
                                                                                                    position="center"/>
                                                                                                <LoadingSpinner/>
                                                                                                <ControlBar
                                                                                                    autoHide={true}
                                                                                                    autoHideTime={3}>
                                                                                                    <PlaybackRateMenuButton
                                                                                                        rates={[5, 2, 1, 0.5, 0.1]}/>
                                                                                                </ControlBar>
                                                                                                <Shortcut
                                                                                                    clickable={false}
                                                                                                    shortcuts={shortCuts}/>
                                                                                            </Player>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            }
                                                                        });
                                                                    }


                                                                })}

                                                            </div>
                                                            : (this.state.compareVideoType === 'layered' && typeof this.state.currentVideo['layered'] !== 'undefined' && this.state.currentVideo['layered'] !== null) ?

                                                                <Player
                                                                    autoPlay={true}
                                                                    playsInline
                                                                    muted={true}
                                                                >
                                                                    <source
                                                                        src={(typeof this.state.currentVideo['layered'] !== 'undefined') ? this.state.currentVideo['layered'] : ''}
                                                                        type={'video/mp4'}/>
                                                                    <div
                                                                        className={'multi-view-video-title'}>{global.strings.layeredModeTitle}</div>
                                                                    <div className={'multi-view-video-labels'}>

                                                                        <div>
                                                                            <FontAwesomeIcon
                                                                                size={'1x'}
                                                                                color={_this.state.currentColors[0]}
                                                                                icon={faSquareFull}/> {global.strings.referenceSign}
                                                                        </div>

                                                                        {this.state.sampleJson.map(function (v, k) {


                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {
                                                                                        return <div>
                                                                                            <FontAwesomeIcon
                                                                                                size={'1x'}
                                                                                                color={_this.state.currentColorsForTable[val.signatureId]}
                                                                                                icon={faSquareFull}/> {val.signatureId}
                                                                                        </div>
                                                                                    }
                                                                                });
                                                                            }

                                                                        })}

                                                                    </div>
                                                                    <BigPlayButton position="center"/>
                                                                    <LoadingSpinner/>
                                                                    <ControlBar autoHide={true} autoHideTime={1}>
                                                                        <PlaybackRateMenuButton
                                                                            rates={[5, 2, 1, 0.5, 0.1]}/>
                                                                    </ControlBar>
                                                                    <Shortcut clickable={false}
                                                                              shortcuts={shortCuts}/>
                                                                </Player>
                                                                : (this.state.compare === false && this.state.referenceJson !== null && typeof this.state.currentVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] !== 'undefined') ?
                                                                    <Player
                                                                        autoPlay={true}
                                                                        playsInline
                                                                        muted={true}
                                                                    >
                                                                        <source
                                                                            src={(this.state.referenceJson !== null && typeof this.state.currentVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] !== 'undefined') ? this.state.currentVideo[this.state.referenceJson[this.state.currentReferenceIndex].signatures[0]] : null}
                                                                            type={'video/mp4'}/>
                                                                        <div
                                                                            className={'multi-view-video-title'}>{global.strings.layeredModeTitle}</div>
                                                                        <div className={'multi-view-video-labels'}>

                                                                            <div>
                                                                                <FontAwesomeIcon
                                                                                    size={'1x'}
                                                                                    color={_this.state.currentColors[0]}
                                                                                    icon={faSquareFull}/> {global.strings.referenceSign}
                                                                            </div>

                                                                        </div>
                                                                        <BigPlayButton position="center"/>
                                                                        <LoadingSpinner/>
                                                                        <ControlBar autoHide={true} autoHideTime={3}>
                                                                            <PlaybackRateMenuButton
                                                                                rates={[5, 2, 1, 0.5, 0.1]}/>
                                                                        </ControlBar>
                                                                        <Shortcut clickable={false}
                                                                                  shortcuts={shortCuts}/>
                                                                    </Player>
                                                                    : null}

                                                    </div>
                                                    <div div className={'control-all-outer'}>
                                                        {(this.state.compare === true && this.state.sampleJson !== null && currentSigns !== null && currentSigns.length > 0 && this.state.compareVideoType === 'grid') ?
                                                            <div className={'control-all'}>
                                                                {(this.state.isPlayingAll === false) ?
                                                                    <FontAwesomeIcon
                                                                        size={'2x'}
                                                                        onClick={() => this.playAllVideos()}
                                                                        icon={faPlayCircle}/> :
                                                                    <FontAwesomeIcon
                                                                        size={'2x'}
                                                                        onClick={() => this.stopAllVideos()}
                                                                        icon={faPauseCircle}/>
                                                                }
                                                                {(this.state.playedAll === true) ?
                                                                    <FontAwesomeIcon
                                                                        size={'2x'}
                                                                        onClick={() => this.loadAllVideos()}
                                                                        icon={faStepBackward}/> :
                                                                    null
                                                                }
                                                                {(this.state.playedAll === true) ?
                                                                    <div style={{
                                                                        display: 'inline-table',
                                                                        position: 'relative'
                                                                    }}>
                                                                        <div
                                                                            className={'control-all-button-badge'}>5sn
                                                                        </div>
                                                                        <FontAwesomeIcon
                                                                            size={'2x'}
                                                                            style={{transform: 'scale(-1,1)'}}
                                                                            onClick={() => this.forwardVideo(-5)}
                                                                            icon={faRedo}/></div> :
                                                                    null
                                                                }
                                                                {(this.state.playedAll === true) ?
                                                                    <div style={{
                                                                        display: 'inline-table',
                                                                        position: 'relative'
                                                                    }}>
                                                                        <div
                                                                            className={'control-all-button-badge'}>5sn
                                                                        </div>
                                                                        <FontAwesomeIcon
                                                                            size={'2x'}
                                                                            onClick={() => this.forwardVideo(5)}
                                                                            icon={faRedo}/></div> :
                                                                    null
                                                                }
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    : null}


                                <div className={'parameter-container'} style={{
                                    position: 'absolute',
                                    width: 500,
                                    right: (this.state.parameterOpened && (currentOptions.indexOf('show-parameter') !== -1)) ? 0 : -500,
                                    backgroundColor: '#474747',
                                    top: 50,
                                    padding: 10,
                                    zIndex: 9
                                }}>
                                    <div className={'parameter-button'}
                                         onClick={this.openParamter}>{global.strings.parameter}</div>
                                    {(currentOptions.indexOf('show-parameter') !== -1) ?

                                        this.state.optionType.map((type, i) => {

                                            // console.log(type);

                                            if (type !== 'show-parameter' && type !== 'show-value' && type !== 'show-view' && type !== 'show-animation') {
                                                return (
                                                    <div key={'par-' + i} className={'standard-box'}>
                                                        <div className={'results-detail'} key={i}>

                                                            <div className="lbTime"
                                                                 id="yTime"> {global.strings.time}</div>
                                                            <div id="xChange"> {global.strings[type]}</div>
                                                            <div className="detailGraph"
                                                                 id={'vardata-' + type}></div>
                                                            <div onClick={() => this.getCsvData(type)}
                                                                 style={{marginTop: 10}}>
                                                                <button>{global.strings.export_data}</button>
                                                            </div>
                                                        </div>
                                                    </div>)
                                            }
                                        }) : null}

                                </div>

                                {(currentOptions.indexOf('show-value') !== -1) ?
                                    <div className={'standard-box'}>
                                        <div className={'analyze'}>
                                            <div className="row">
                                                <div className={'col-12'} style={{'display': 'flow-root'}}>

                                                    {(typeof this.state.referenceJson[0].data !== undefined) ?
                                                        <div>
                                                            <h6 className={'graph-title'}>{global.strings.subject} {global.strings.signvValues.toUpperCase()}</h6>
                                                            {(this.state.compare === true) ?
                                                                <table id={'compare-legend'} style={{margin: 10}}
                                                                       width={'100%'}>
                                                                    <tr>
                                                                        <td style={{
                                                                            backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                            width: 24,
                                                                        }}></td>
                                                                        <td>(%100-%97) {global.strings.similarity}
                                                                        </td>
                                                                        <td style={{
                                                                            backgroundColor: customConstants.THRESHOLD_MID_COLOR,
                                                                            width: 24,
                                                                        }}></td>
                                                                        <td> (%97-%79) {global.strings.similarity}
                                                                        </td>
                                                                        <td style={{
                                                                            backgroundColor: customConstants.THRESHOLD_HIGH_COLOR,
                                                                            width: 24,
                                                                        }}></td>
                                                                        <td> (%79-%60) {global.strings.similarity}
                                                                        </td>
                                                                        <td style={{
                                                                            backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR,
                                                                            width: 24,
                                                                        }}></td>
                                                                        <td> (%60-%0) {global.strings.similarity}
                                                                        </td>
                                                                    </tr>
                                                                </table> : null}
                                                            <table id={'all-parameters'} className="table-single table">
                                                                <thead>
                                                                <tr>

                                                                    <th scope="col"
                                                                        style={{width: 300}}> {global.strings.parameter}</th>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <th scope="col"
                                                                            style={{
                                                                                'backgroundColor': this.state.currentColors[0],
                                                                                'color': '#fff'
                                                                            }}>  {global.strings.values} ({global.strings.reference})
                                                                        </th> : null}

                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {


                                                                                return (v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {
                                                                                        currentIndex++;
                                                                                        return (
                                                                                            <th key={'h' + v.groupName + '-' + val.signatureId}
                                                                                                scope="col"
                                                                                                style={{
                                                                                                    'backgroundColor': this.state.currentColorsForTable[val.signatureId],
                                                                                                    'color': '#fff'
                                                                                                }}>{v.groupName}</th>)
                                                                                    }


                                                                                }))
                                                                            }

                                                                        })
                                                                        : null
                                                                    }

                                                                    <th scope="col"
                                                                        style={{width: 110}}>{global.strings.unit}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr className={'primary-values'} id={'f-signatureI'}>

                                                                    <td scope="col"> {global.strings.signatureId}</td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col"> {this.state.referenceJson[0].data[this.state.currentReferenceIndex].hashValue}<div style={{fontWeight:'bold'}}>{(this.state.referenceJson[0].names) ? this.state.referenceJson[0].names[0].filename : null}</div></td>
                                                                        : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (
                                                                                            <td key={'sign-' + val.signatureId}
                                                                                                scope="col">{val.hashValue}<div style={{fontWeight:'bold'}}>{(v.names && v.names[i].filename) ? v.names[i].filename : val.hashValue}</div></td>)
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-totalTimeUp'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.total_time_up}><span>{global.strings.totalTimeUp}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col"> {(this.state.referenceJson[0].data[this.state.currentReferenceIndex].detail.pd.filter(x => x == 0).length - 1 === -1) ? 0 : this.state.referenceJson[0].data[this.state.currentReferenceIndex].detail.pd.filter(x => x == 0).length}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {
                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);
                                                                                    let hasTresh = false;
                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {


                                                                                        let penUp = val.detail.pd.filter(x => x == 0).length;

                                                                                        if (penUp === -1) {
                                                                                            penUp = 1;
                                                                                        }

                                                                                        return (
                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['penUp'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['penUp'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['penUp'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['penUp'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {penUp.toFixed(0)} {
                                                                                                (typeof val.featureThresholds['penUp'] !== 'undefined') ? '(% ' + val.featureThresholds['penUp'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['penUp'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['penUp'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['penUp'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['penUp'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['penUp'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['penUp'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>)
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.count}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-totalTime'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.total_time_desc}><span>{global.strings.totalTime}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col"> {this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.totalTime.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);
                                                                                    let hasTresh = false;
                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (
                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['totalTime'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['totalTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['totalTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['totalTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.totalTime.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['totalTime'] !== 'undefined') ? '(% ' + val.featureThresholds['totalTime'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['totalTime'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['totalTime'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['totalTime'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['totalTime'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['totalTime'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['totalTime'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>)
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitSecond}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-penDownTime'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.pen_down_time}><span>{global.strings.penDownTime}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.penDownTime.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {


                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['penDownTime'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['penDownTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['penDownTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['penDownTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.penDownTime.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['penDownTime'] !== 'undefined') ? '(% ' + val.featureThresholds['penDownTime'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['penDownTime'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['penDownTime'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['penDownTime'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['penDownTime'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['penDownTime'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['penDownTime'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })

                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitSecond}</td>
                                                                </tr>
                                                                <tr className={'primary-values'}
                                                                    id={'f-totalTimeUpTime'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.total_time_up_time}><span>{global.strings.totalTimeUpTime}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col"> {
                                                                            ((this.state.referenceJson[0].data[this.state.currentReferenceIndex].detail.pd.filter(x => x == 0).length - 1) > -1) ?
                                                                                ((this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.totalTime) - (this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.penDownTime)).toFixed(4) : 0}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {
                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);
                                                                                    let hasTresh = false;
                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['totalTimeUpTime'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['totalTimeUpTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['totalTimeUpTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['totalTimeUpTime'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {((val.detail.pd.filter(x => x == 0).length - 1) > -1) ?
                                                                                                    ((val.features.totalTime) - (val.features.penDownTime)).toFixed(4) : 0} {
                                                                                                (typeof val.featureThresholds['totalTimeUpTime'] !== 'undefined') ? '(% ' + val.featureThresholds['totalTimeUpTime'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['totalTimeUpTime'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['totalTimeUpTime'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['totalTimeUpTime'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['totalTimeUpTime'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['totalTimeUpTime'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['totalTimeUpTime'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitSecond}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-meanPressure'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_pressure}><span>{global.strings.meanPressure}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanPressure.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanPressure'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanPressure'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanPressure'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanPressure'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.meanPressure.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['meanPressure'] !== 'undefined') ? '(% ' + val.featureThresholds['meanPressure'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanPressure'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanPressure'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanPressure'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanPressure'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanPressure'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanPressure'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.newton}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-stdPressure'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.std_pressure}><span>{global.strings.stdPressure}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.stdPressure.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['stdPressure'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['stdPressure'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['stdPressure'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['stdPressure'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.stdPressure.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['stdPressure'] !== 'undefined') ? '(% ' + val.featureThresholds['stdPressure'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['stdPressure'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['stdPressure'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['stdPressure'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['stdPressure'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['stdPressure'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['stdPressure'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.newton}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-totalLength'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.total_len}><span>{global.strings.totalLength}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.totalLength.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['totalLength'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['totalLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['totalLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['totalLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.totalLength.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['totalLength'] !== 'undefined') ? '(% ' + val.featureThresholds['totalLength'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['totalLength'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['totalLength'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['totalLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['totalLength'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['totalLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['totalLength'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-meanSpeedV'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_speed_v}><span>{global.strings.meanSpeedV}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedV.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanSpeedV'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanSpeedV'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanSpeedV'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanSpeedV'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.meanSpeedV.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['meanSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholds['meanSpeedV'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanSpeedV'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanSpeedV'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanSpeedV'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })

                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-avgSpeed'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.avgSpeed_desc}><span>{global.strings.avgSpeed}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.avgSpeed !== 'undefined') ? ((this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.avgSpeed * 1000000).toFixed(4)) : '---'}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['avgSpeed'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['avgSpeed'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['avgSpeed'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['avgSpeed'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {((typeof val.features.avgSpeed !== 'undefined') ? (val.features.avgSpeed * 1000000).toFixed(4) : '---')} {
                                                                                                (typeof val.featureThresholds['avgSpeed'] !== 'undefined') ? '(% ' + (val.featureThresholds['avgSpeed']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['avgSpeed'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['avgSpeed'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['avgSpeed'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['avgSpeed'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['avgSpeed'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['avgSpeed'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.microsecond}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-rectArea'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.rectArea_desc}><span>{global.strings.rectArea}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.rectArea * 100).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['rectArea'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['rectArea'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['rectArea'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['rectArea'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.rectArea * 100).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['rectArea'] !== 'undefined') ? '(% ' + (val.featureThresholds['rectArea']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['rectArea'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['rectArea'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['rectArea'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['rectArea'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['rectArea'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['rectArea'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.mmeter2}</td>
                                                                </tr>
                                                                <tr className={'primary-values'}
                                                                    id={'f-positiveNegativeArea'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.positiveNegativeArea_desc}><span>{global.strings.positiveNegativeArea}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.positiveNegativeArea).join(', ')}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['positiveNegativeArea'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['positiveNegativeArea'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['positiveNegativeArea'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['positiveNegativeArea'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.positiveNegativeArea.length > 0) ? val.features.positiveNegativeArea.join(', ') : null} {
                                                                                                (typeof val.featureThresholds['positiveNegativeArea'] !== 'undefined') ? '(% ' + (val.featureThresholds['endPenUpDistance']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['positiveNegativeArea'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['positiveNegativeArea'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['positiveNegativeArea'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['positiveNegativeArea'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['positiveNegativeArea'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['positiveNegativeArea'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.dig4}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-ltwRatio'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.ltw_ratio}><span>{global.strings.ltwRatio}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.ltwRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['ltwRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['ltwRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['ltwRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['ltwRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.ltwRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['ltwRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['ltwRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['ltwRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['ltwRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['ltwRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['ltwRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['ltwRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['ltwRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )
                                                                                    }
                                                                                })

                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-ltaRatio'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.lta_ratio}><span>{global.strings.ltaRatio}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.ltaRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['ltaRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['ltaRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['ltaRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['ltaRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.ltaRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['ltaRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['ltaRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['ltaRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['ltaRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['ltaRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['ltaRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['ltaRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['ltaRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-wthRatio'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.wth_ratio}><span>{global.strings.wthRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.wthRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['wthRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['wthRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['wthRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['wthRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.wthRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['wthRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['wthRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['wthRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['wthRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-hmsRatio'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.hms_ratio}><span>{global.strings.hmsRatio}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.hmsRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['hmsRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['hmsRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['hmsRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['hmsRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.hmsRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['hmsRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['hmsRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['hmsRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['hmsRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['hmsRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['hmsRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['hmsRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['hmsRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })

                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr className={'primary-values'}
                                                                    id={'f-lengthXWithoutPenLifts'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.lengthXWithoutPenLifts_desc}><span>{global.strings.lengthXWithoutPenLifts}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.lengthXWithoutPenLifts * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['lengthXWithoutPenLifts'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['lengthXWithoutPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['lengthXWithoutPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['lengthXWithoutPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.lengthXWithoutPenLifts * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['lengthXWithoutPenLifts'] !== 'undefined') ? '(% ' + (val.featureThresholds['lengthXWithoutPenLifts']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['lengthXWithoutPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['lengthXWithoutPenLifts'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['lengthXWithoutPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['lengthXWithoutPenLifts'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['lengthXWithoutPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['lengthXWithoutPenLifts'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr className={'primary-values'}
                                                                    id={'f-lengthYWithoutPenLifts'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.lengthYWithoutPenLifts_desc}><span>{global.strings.lengthYWithoutPenLifts}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.lengthYWithoutPenLifts * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['lengthYWithoutPenLifts'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['lengthYWithoutPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['lengthYWithoutPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['lengthYWithoutPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.lengthYWithoutPenLifts * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['lengthYWithoutPenLifts'] !== 'undefined') ? '(% ' + (val.featureThresholds['lengthYWithoutPenLifts']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['lengthYWithoutPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['lengthYWithoutPenLifts'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['lengthYWithoutPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['lengthYWithoutPenLifts'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['lengthYWithoutPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['lengthYWithoutPenLifts'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-rmsSpeedV'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.rms_speed}><span>{global.strings.rmsSpeedV}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col"> {this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.rmsSpeedV.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {
                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['rmsSpeedV'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['rmsSpeedV'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['rmsSpeedV'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['rmsSpeedV'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.rmsSpeedV.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['rmsSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholds['rmsSpeedV'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['rmsSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['rmsSpeedV'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['rmsSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['rmsSpeedV'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['rmsSpeedV'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['rmsSpeedV'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr className={'primary-values'} id={'f-avgSpeedVx'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.avg_speed_vx}><span>{global.strings.avgSpeedVx}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col"> {this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.avgSpeedVx.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {


                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['avgSpeedVx'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['avgSpeedVx'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['avgSpeedVx'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['avgSpeedVx'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.avgSpeedVx.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['avgSpeedVx'] !== 'undefined') ? '(% ' + val.featureThresholds['avgSpeedVx'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['avgSpeedVx'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['avgSpeedVx'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['avgSpeedVx'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['avgSpeedVx'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['avgSpeedVx'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['avgSpeedVx'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr className={'primary-values'}
                                                                    id={'f-startEndLength'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.startEndLength_desc}><span>{global.strings.startEndLength}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.startEndLength * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['startEndLength'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['startEndLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['startEndLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['startEndLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.startEndLength * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['startEndLength'] !== 'undefined') ? '(% ' + (val.featureThresholds['startEndLength']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['startEndLength'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['startEndLength'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['startEndLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['startEndLength'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['startEndLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['startEndLength'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>


                                                                <tr id={'f-speedCorr'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.speed_coor}><span>{global.strings.speedCorr}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.speedCorr.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (
                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['speedCorr'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['speedCorr'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['speedCorr'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['speedCorr'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.speedCorr.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['speedCorr'] !== 'undefined') ? '(% ' + val.featureThresholds['speedCorr'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['speedCorr'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['speedCorr'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['speedCorr'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['speedCorr'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['speedCorr'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['speedCorr'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}<sup>2</sup> /
                                                                        s<sup>2</sup>
                                                                    </td>
                                                                </tr>
                                                                <tr id={'f-speedSignChange'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.speed_sign_change}><span>{global.strings.speedSignChange}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.speedSignChange.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['speedSignChange'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['speedSignChange'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['speedSignChange'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['speedSignChange'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.speedSignChange.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['speedSignChange'] !== 'undefined') ? '(% ' + val.featureThresholds['speedSignChange'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['speedSignChange'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['speedSignChange'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['speedSignChange'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['speedSignChange'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['speedSignChange'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['speedSignChange'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.count}</td>
                                                                </tr>
                                                                <tr id={'f-stdSpeedY'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.std_speed_y}><span>{global.strings.stdSpeedY}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.stdSpeedY.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['stdSpeedY'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['stdSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['stdSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['stdSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.stdSpeedY.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['stdSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholds['stdSpeedY'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['stdSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['stdSpeedY'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['stdSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['stdSpeedY'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['stdSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['stdSpeedY'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-stdSpeedX'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.std_speed_x}><span>{global.strings.stdSpeedX}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.stdSpeedX.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['stdSpeedX'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['stdSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['stdSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['stdSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.stdSpeedX.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['stdSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholds['stdSpeedX'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['stdSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['stdSpeedX'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['stdSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['stdSpeedX'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['stdSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['stdSpeedX'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-minSpeedX'}>

                                                                    <td scope="col">{global.strings.minSpeedX}</td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.minSpeedX.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['minSpeedX'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['minSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['minSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['minSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.minSpeedX.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['minSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholds['minSpeedX'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['minSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['minSpeedX'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['minSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['minSpeedX'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['minSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['minSpeedX'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-minSpeedY'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.min_speed_y}><span>{global.strings.minSpeedY}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.minSpeedY.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['minSpeedY'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['minSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['minSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['minSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.minSpeedY.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['minSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholds['minSpeedY'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['minSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['minSpeedY'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['minSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['minSpeedY'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['minSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['minSpeedY'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-maxSpeedYToMean'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.max_speed_y_to_mean}><span>{global.strings.maxSpeedYToMean}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.maxSpeedYToMean.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['maxSpeedYToMean'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['maxSpeedYToMean'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['maxSpeedYToMean'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['maxSpeedYToMean'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.maxSpeedYToMean.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['maxSpeedYToMean'] !== 'undefined') ? '(% ' + val.featureThresholds['maxSpeedYToMean'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['maxSpeedYToMean'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['maxSpeedYToMean'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['maxSpeedYToMean'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['maxSpeedYToMean'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['maxSpeedYToMean'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['maxSpeedYToMean'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-maxSpeedYToMin'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.max_speed_y_to_min}><span>{global.strings.maxSpeedYToMin}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.maxSpeedYToMin.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['maxSpeedYToMin'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['maxSpeedYToMin'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['maxSpeedYToMin'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['maxSpeedYToMin'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.maxSpeedYToMin.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['maxSpeedYToMin'] !== 'undefined') ? '(% ' + val.featureThresholds['maxSpeedYToMin'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['maxSpeedYToMin'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['maxSpeedYToMin'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['maxSpeedYToMin'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['maxSpeedYToMin'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['maxSpeedYToMin'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['maxSpeedYToMin'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-maxSpeedXToMean'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.max_speed_x_to_mean}><span>{global.strings.maxSpeedXToMean}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.maxSpeedXToMean.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['maxSpeedXToMean'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['maxSpeedXToMean'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['maxSpeedXToMean'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['maxSpeedXToMean'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.maxSpeedXToMean.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['maxSpeedXToMean'] !== 'undefined') ? '(% ' + val.featureThresholds['maxSpeedXToMean'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['maxSpeedXToMean'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['maxSpeedXToMean'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['maxSpeedXToMean'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['maxSpeedXToMean'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['maxSpeedXToMean'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['maxSpeedXToMean'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-maxSpeedXToMin'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.max_speed_x_to_min}><span>{global.strings.maxSpeedXToMin}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.maxSpeedXToMin.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['maxSpeedXToMin'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['maxSpeedXToMin'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['maxSpeedXToMin'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['maxSpeedXToMin'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.maxSpeedXToMin.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['maxSpeedXToMin'] !== 'undefined') ? '(% ' + val.featureThresholds['maxSpeedXToMin'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['maxSpeedXToMin'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['maxSpeedXToMin'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['maxSpeedXToMin'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['maxSpeedXToMin'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['maxSpeedXToMin'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['maxSpeedXToMin'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-mvtmvyRatio'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mvtmvy_ratio}><span>{global.strings.mvtmvyRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.mvtmvyRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['mvtmvyRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['mvtmvyRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['mvtmvyRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['mvtmvyRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.mvtmvyRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['mvtmvyRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['mvtmvyRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['mvtmvyRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['mvtmvyRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['mvtmvyRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['mvtmvyRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['mvtmvyRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['mvtmvyRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-meanSpeedX'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_speed_x}><span>{global.strings.meanSpeedX}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedX.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanSpeedX'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanSpeedX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.meanSpeedX.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['meanSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholds['meanSpeedX'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanSpeedX'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanSpeedX'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanSpeedX'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanSpeedX'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-meanSpeedNegX'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_speed_neg_x}><span>{global.strings.meanSpeedNegX}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined' && this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedNegX !== null) ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedNegX !== null) ? this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedNegX.toFixed(4) : ''}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanSpeedNegX'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanSpeedNegX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanSpeedNegX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanSpeedNegX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.meanSpeedNegX !== null) ? val.features.meanSpeedNegX.toFixed(4) : ''} {
                                                                                                (typeof val.featureThresholds['meanSpeedNegX'] !== 'undefined') ? '(% ' + val.featureThresholds['meanSpeedNegX'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanSpeedNegX'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanSpeedNegX'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanSpeedNegX'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanSpeedNegX'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanSpeedNegX'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanSpeedNegX'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })

                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-meanSpeedY'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_speed_y}><span>{global.strings.meanSpeedY}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedY.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanSpeedY'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanSpeedY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.meanSpeedY.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['meanSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholds['meanSpeedY'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanSpeedY'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanSpeedY'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanSpeedY'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanSpeedY'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-meanSpeedNegY'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_speed_neg_y}><span>{global.strings.meanSpeedNegY}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined' && this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedNegY !== null) ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedNegY) ? this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanSpeedNegY.toFixed(4) : ''}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanSpeedNegY'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanSpeedNegY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanSpeedNegY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanSpeedNegY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.meanSpeedNegY) ? val.features.meanSpeedNegY.toFixed(4) : ''} {
                                                                                                (typeof val.featureThresholds['meanSpeedNegY'] !== 'undefined') ? '(% ' + val.featureThresholds['meanSpeedNegY'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanSpeedNegY'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanSpeedNegY'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanSpeedNegY'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanSpeedNegY'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanSpeedNegY'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanSpeedNegY'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}</td>
                                                                </tr>
                                                                <tr id={'f-avgAccCent'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.avg_acc_cent}><span>{global.strings.avgAccCent}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.avgAccCent.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                    return v.data.map((val, key) => {

                                                                                        signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                        if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {


                                                                                            return (

                                                                                                <td key={'a-' + val.signatureId}
                                                                                                    style={(typeof val.featureThresholds['avgAccCent'] !== 'undefined') ? {
                                                                                                        backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                        color: '#000'
                                                                                                    } : null
                                                                                                    || (typeof val.featureThresholdsMid['avgAccCent'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                        (typeof val.featureThresholdsHigh['avgAccCent'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                            (typeof val.featureThresholdsHighest['avgAccCent'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                                null
                                                                                                    }
                                                                                                    scope="col">
                                                                                                    {val.features.avgAccCent.toFixed(4)} {
                                                                                                    (typeof val.featureThresholds['avgAccCent'] !== 'undefined') ? '(% ' + val.featureThresholds['avgAccCent'].toFixed(2) + ')' :
                                                                                                        '' || (typeof val.featureThresholdsMid['avgAccCent'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['avgAccCent'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHigh['avgAccCent'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['avgAccCent'].toFixed(2) + ')' :
                                                                                                                (typeof val.featureThresholdsHighest['avgAccCent'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['avgAccCent'].toFixed(2) + ')' :
                                                                                                                    ''
                                                                                                }
                                                                                                </td>

                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}<sup>2</sup>
                                                                    </td>
                                                                </tr>
                                                                <tr id={'f-avgAccTang'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.avg_acc_tang}><span>{global.strings.avgAccTang}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.avgAccTang.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['avgAccTang'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['avgAccTang'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['avgAccTang'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['avgAccTang'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.avgAccTang.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['avgAccTang'] !== 'undefined') ? '(% ' + val.featureThresholds['avgAccTang'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['avgAccTang'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['avgAccTang'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['avgAccTang'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['avgAccTang'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['avgAccTang'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['avgAccTang'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.unitMeterSecond}<sup>2</sup>
                                                                    </td>
                                                                </tr>
                                                                <tr id={'f-HSpanRatio'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.hsspan_ratio}><span>{global.strings.HSpanRatio}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.HSpanRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {


                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {


                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['HSpanRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['HSpanRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['HSpanRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['HSpanRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.HSpanRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['HSpanRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['HSpanRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['HSpanRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['HSpanRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['HSpanRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['HSpanRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['HSpanRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['HSpanRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })

                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-midOfX'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mid_of_x}><span>{global.strings.midOfX}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.midOfX.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {


                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['midOfX'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['midOfX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['midOfX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['midOfX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.midOfX.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['midOfX'] !== 'undefined') ? '(% ' + val.featureThresholds['midOfX'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['midOfX'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['midOfX'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['midOfX'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['midOfX'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['midOfX'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['midOfX'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })

                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr id={'f-midOfY'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mid_of_y}><span>{global.strings.midOfY}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.midOfY.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['midOfY'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['midOfY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['midOfY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['midOfY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.midOfY.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['midOfY'] !== 'undefined') ? '(% ' + val.featureThresholds['midOfY'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['midOfY'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['midOfY'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['midOfY'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['midOfY'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['midOfY'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['midOfY'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>


                                                                                        )

                                                                                    }
                                                                                })

                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr id={'f-numberOfStrokes'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.number_of_strokes}><span>{global.strings.numberOfStrokes}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.numberOfStrokes.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['numberOfStrokes'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['numberOfStrokes'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['numberOfStrokes'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['numberOfStrokes'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.numberOfStrokes.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['numberOfStrokes'] !== 'undefined') ? '(% ' + val.featureThresholds['numberOfStrokes'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['numberOfStrokes'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['numberOfStrokes'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['numberOfStrokes'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['numberOfStrokes'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['numberOfStrokes'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['numberOfStrokes'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.count}</td>
                                                                </tr>
                                                                <tr id={'f-meanAscenderHeight'}>
                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_asc_height}><span>{global.strings.meanAscenderHeight}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanAscenderHeight.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanAscenderHeight'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanAscenderHeight'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanAscenderHeight'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanAscenderHeight'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.meanAscenderHeight.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['meanAscenderHeight'] !== 'undefined') ? '(% ' + val.featureThresholds['meanAscenderHeight'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanAscenderHeight'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanAscenderHeight'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanAscenderHeight'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanAscenderHeight'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanAscenderHeight'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanAscenderHeight'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr id={'f-meanDescenderDepth'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.mean_desc_depth}><span>{global.strings.meanDescenderDepth}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.meanDescenderDepth.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['meanDescenderDepth'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['meanDescenderDepth'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['meanDescenderDepth'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['meanDescenderDepth'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.meanDescenderDepth.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['meanDescenderDepth'] !== 'undefined') ? '(% ' + val.featureThresholds['meanDescenderDepth'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['meanDescenderDepth'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['meanDescenderDepth'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['meanDescenderDepth'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['meanDescenderDepth'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['meanDescenderDepth'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['meanDescenderDepth'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr id={'f-drsRatio'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.drs_ratio}><span>{global.strings.drsRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.drsRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['drsRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['drsRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['drsRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['drsRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.drsRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['drsRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['drsRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['drsRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['drsRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['drsRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['drsRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['drsRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['drsRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-maxXtoMinX'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.max_x_to_min_x}><span>{global.strings.maxXtoMinX}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.maxXtoMinX.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['maxXtoMinX'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['maxXtoMinX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['maxXtoMinX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['maxXtoMinX'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.maxXtoMinX.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['maxXtoMinX'] !== 'undefined') ? '(% ' + val.featureThresholds['maxXtoMinX'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['maxXtoMinX'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['maxXtoMinX'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['maxXtoMinX'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['maxXtoMinX'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['maxXtoMinX'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['maxXtoMinX'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr id={'f-stpRatio'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.stp_ratio}><span>{global.strings.stpRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.stpRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['stpRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['stpRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['stpRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['stpRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.stpRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['stpRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['stpRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['stpRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['stpRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['stpRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['stpRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['stpRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['stpRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-wtpRatio'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.wtp_ratio}><span>{global.strings.wtpRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.wtpRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['wtpRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['wtpRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['wtpRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['wtpRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.wtpRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['wtpRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['wtpRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['wtpRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['wtpRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['wtpRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['wtpRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['wtpRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['wtpRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-overMeanXRatio'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.over_mean_x_ratio}><span>{global.strings.overMeanXRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.overMeanXRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['overMeanXRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['overMeanXRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['overMeanXRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['overMeanXRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.overMeanXRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['overMeanXRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['overMeanXRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['overMeanXRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['overMeanXRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['overMeanXRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['overMeanXRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['overMeanXRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['overMeanXRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-overMeanYRatio'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.over_mean_y_ratio}><span>{global.strings.overMeanYRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.overMeanYRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (


                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['overMeanYRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['overMeanYRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['overMeanYRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['overMeanYRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.overMeanYRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['overMeanYRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['overMeanYRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['overMeanYRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['overMeanYRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['overMeanYRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['overMeanYRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['overMeanYRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['overMeanYRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-wltplRatio'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.wltp_ratio}><span>{global.strings.wltplRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.wltplRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['wltplRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['wltplRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['wltplRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['wltplRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.wltplRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['wltplRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['wltplRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['wltplRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['wltplRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['wltplRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['wltplRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['wltplRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['wltplRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-ascDescDiff'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.asc_desc_diff}><span>{global.strings.ascDescDiff}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.ascDescDiff.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['ascDescDiff'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['ascDescDiff'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['ascDescDiff'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['ascDescDiff'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.ascDescDiff.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['ascDescDiff'] !== 'undefined') ? '(% ' + val.featureThresholds['ascDescDiff'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['ascDescDiff'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['ascDescDiff'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['ascDescDiff'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['ascDescDiff'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['ascDescDiff'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['ascDescDiff'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr id={'f-WTTRatio'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.wtt_ratio}><span>{global.strings.WTTRatio}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.WTTRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['WTTRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['WTTRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['WTTRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['WTTRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.WTTRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['WTTRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['WTTRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['WTTRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['WTTRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['WTTRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['WTTRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['WTTRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['WTTRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.meter}</td>
                                                                </tr>
                                                                <tr id={'f-startSignLine'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.startSignLine_desc}><span>{global.strings.startSignLine}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.startSignLine * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['startSignLine'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['startSignLine'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['startSignLine'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['startSignLine'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.startSignLine * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['startSignLine'] !== 'undefined') ? '(% ' + (val.featureThresholds['startSignLine']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['startSignLine'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['startSignLine'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['startSignLine'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['startSignLine'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['startSignLine'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['startSignLine'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-positiveXMoveLength'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.positiveXMoveLength_desc}><span>{global.strings.positiveXMoveLength}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.positiveXMoveLength * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['positiveXMoveLength'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['positiveXMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['positiveXMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['positiveXMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.positiveXMoveLength * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['positiveXMoveLength'] !== 'undefined') ? '(% ' + (val.featureThresholds['positiveXMoveLength']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['positiveXMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['positiveXMoveLength'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['positiveXMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['positiveXMoveLength'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['positiveXMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['positiveXMoveLength'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-positiveYMoveLength'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.positiveYMoveLength_desc}><span>{global.strings.positiveYMoveLength}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.positiveYMoveLength * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['positiveYMoveLength'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['positiveYMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['positiveYMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['positiveYMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.positiveYMoveLength * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['positiveYMoveLength'] !== 'undefined') ? '(% ' + (val.featureThresholds['positiveYMoveLength']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['positiveYMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['positiveYMoveLength'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['positiveYMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['positiveYMoveLength'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['positiveYMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['positiveYMoveLength'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-negativeXMoveLength'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.negativeXMoveLength_desc}><span>{global.strings.negativeXMoveLength}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.negativeXMoveLength * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['negativeXMoveLength'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['negativeXMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['negativeXMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['negativeXMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.negativeXMoveLength * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['negativeXMoveLength'] !== 'undefined') ? '(% ' + (val.featureThresholds['negativeXMoveLength']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['negativeXMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['negativeXMoveLength'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['negativeXMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['negativeXMoveLength'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['negativeXMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['negativeXMoveLength'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-negativeYMoveLength'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.negativeYMoveLength_desc}><span>{global.strings.negativeYMoveLength}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.negativeYMoveLength * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['negativeYMoveLength'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['negativeYMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['negativeYMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['negativeYMoveLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.negativeYMoveLength * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['negativeYMoveLength'] !== 'undefined') ? '(% ' + (val.featureThresholds['negativeYMoveLength']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['negativeYMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['negativeYMoveLength'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['negativeYMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['negativeYMoveLength'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['negativeYMoveLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['negativeYMoveLength'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-numberOfXTurns'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.numberOfXTurns_desc}><span>{global.strings.numberOfXTurns}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.numberOfXTurns)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['numberOfXTurns'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['numberOfXTurns'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['numberOfXTurns'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['numberOfXTurns'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.numberOfXTurns)} {
                                                                                                (typeof val.featureThresholds['numberOfXTurns'] !== 'undefined') ? '(% ' + (val.featureThresholds['numberOfXTurns']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['numberOfXTurns'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['numberOfXTurns'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['numberOfXTurns'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['numberOfXTurns'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['numberOfXTurns'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['numberOfXTurns'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.count}</td>
                                                                </tr>
                                                                <tr id={'f-numberOfYTurns'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.numberOfYTurns_desc}><span>{global.strings.numberOfYTurns}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.numberOfYTurns)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['numberOfYTurns'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['numberOfYTurns'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['numberOfYTurns'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['numberOfYTurns'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.numberOfYTurns)} {
                                                                                                (typeof val.featureThresholds['numberOfYTurns'] !== 'undefined') ? '(% ' + (val.featureThresholds['numberOfYTurns']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['numberOfYTurns'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['numberOfYTurns'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['numberOfYTurns'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['numberOfYTurns'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['numberOfYTurns'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['numberOfYTurns'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.count}</td>
                                                                </tr>
                                                                <tr id={'f-lengthXFeature'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.lengthXFeature_desc}><span>{global.strings.lengthXFeature}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.lengthXFeature * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['lengthXFeature'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['lengthXFeature'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['lengthXFeature'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['lengthXFeature'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.lengthXFeature * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['lengthXFeature'] !== 'undefined') ? '(% ' + (val.featureThresholds['lengthXFeature']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['lengthXFeature'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['lengthXFeature'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['lengthXFeature'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['lengthXFeature'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['lengthXFeature'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['lengthXFeature'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-lengthYFeature'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.lengthYFeature_desc}><span>{global.strings.lengthYFeature}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.lengthYFeature * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['lengthYFeature'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['lengthYFeature'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['lengthYFeature'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['lengthYFeature'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.lengthYFeature * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['lengthYFeature'] !== 'undefined') ? '(% ' + (val.featureThresholds['lengthYFeature']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['lengthYFeature'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['lengthYFeature'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['lengthYFeature'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['lengthYFeature'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['lengthYFeature'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['lengthYFeature'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-lengthOfPenLifts'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.lengthOfPenLifts_desc}><span>{global.strings.lengthOfPenLifts}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.lengthOfPenLifts * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['lengthOfPenLifts'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['lengthOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['lengthOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['lengthOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.lengthOfPenLifts * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['lengthOfPenLifts'] !== 'undefined') ? '(% ' + (val.featureThresholds['lengthOfPenLifts']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['lengthOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['lengthOfPenLifts'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['lengthOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['lengthOfPenLifts'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['lengthOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['lengthOfPenLifts'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-startPenUpDistance'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.startPenUpDistance_desc}><span>{global.strings.startPenUpDistance}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{
                                                                            (this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.startPenUpDistance.length > 0) ? this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.startPenUpDistance[0]._1 + ' / ' + this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.startPenUpDistance[0]._2 : '0.0000'
                                                                        }</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['startPenUpDistance'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['startPenUpDistance'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['startPenUpDistance'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['startPenUpDistance'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.startPenUpDistance.length > 0) ? val.features.startPenUpDistance[0]._1 + ' / ' + val.features.startPenUpDistance[0]._2 : '0.0000'} {
                                                                                                (typeof val.featureThresholds['startPenUpDistance'] !== 'undefined') ? '(% ' + (val.featureThresholds['startPenUpDistance']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['startPenUpDistance'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['startPenUpDistance'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['startPenUpDistance'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['startPenUpDistance'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['startPenUpDistance'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['startPenUpDistance'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.mmeter}</td>
                                                                </tr>
                                                                <tr id={'f-endPenUpDistance'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.endPenUpDistance_desc}><span>{global.strings.endPenUpDistance}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{
                                                                            (this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.endPenUpDistance.length > 0) ? this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.endPenUpDistance[0]._1 + ' / ' + this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.endPenUpDistance[0]._2 : '0.0000'
                                                                        }</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['endPenUpDistance'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['endPenUpDistance'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['endPenUpDistance'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['endPenUpDistance'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.endPenUpDistance.length > 0) ? val.features.endPenUpDistance[0]._1 + ' / ' + val.features.endPenUpDistance[0]._2 : '0.0000'} {
                                                                                                (typeof val.featureThresholds['endPenUpDistance'] !== 'undefined') ? '(% ' + (val.featureThresholds['endPenUpDistance']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['endPenUpDistance'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['endPenUpDistance'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['endPenUpDistance'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['endPenUpDistance'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['endPenUpDistance'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['endPenUpDistance'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.mmeter}</td>
                                                                </tr>
                                                                <tr id={'f-reverseTimeOfPenLifts'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.reverseTimeOfPenLifts_desc}><span>{global.strings.reverseTimeOfPenLifts}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.reverseTimeOfPenLifts * 100).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['reverseTimeOfPenLifts'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['reverseTimeOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['reverseTimeOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['reverseTimeOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.reverseTimeOfPenLifts * 100).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['reverseTimeOfPenLifts'] !== 'undefined') ? '(% ' + (val.featureThresholds['reverseTimeOfPenLifts']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['reverseTimeOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['reverseTimeOfPenLifts'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['reverseTimeOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['reverseTimeOfPenLifts'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['reverseTimeOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['reverseTimeOfPenLifts'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.ms}</td>
                                                                </tr>
                                                                <tr id={'f-timeOfPenLifts'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.timeOfPenLifts_desc}><span>{global.strings.timeOfPenLifts}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.timeOfPenLifts * 100).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['timeOfPenLifts'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['timeOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['timeOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['timeOfPenLifts'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.timeOfPenLifts * 100).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['timeOfPenLifts'] !== 'undefined') ? '(% ' + (val.featureThresholds['timeOfPenLifts']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['timeOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['timeOfPenLifts'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['timeOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['timeOfPenLifts'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['timeOfPenLifts'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['timeOfPenLifts'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.ms}</td>
                                                                </tr>
                                                                <tr id={'f-baseCenterOfGravity'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.baseCenterOfGravity_desc}><span>{global.strings.baseCenterOfGravity}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.baseCenterOfGravity.join(', '))}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['baseCenterOfGravity'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['baseCenterOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['baseCenterOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['baseCenterOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.baseCenterOfGravity.join(', '))} {
                                                                                                (typeof val.featureThresholds['baseCenterOfGravity'] !== 'undefined') ? '(% ' + (val.featureThresholds['baseCenterOfGravity']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['baseCenterOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['baseCenterOfGravity'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['baseCenterOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['baseCenterOfGravity'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['baseCenterOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['baseCenterOfGravity'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.ms}</td>
                                                                </tr>
                                                                <tr id={'f-centerXOfGravity'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.centerXOfGravity_desc}><span>{global.strings.centerXOfGravity}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.centerXOfGravity * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['centerXOfGravity'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['centerXOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['centerXOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['centerXOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.centerXOfGravity * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['centerXOfGravity'] !== 'undefined') ? '(% ' + (val.featureThresholds['centerXOfGravity']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['centerXOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['centerXOfGravity'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['centerXOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['centerXOfGravity'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['centerXOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['centerXOfGravity'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-centerYOfGravity'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.centerYOfGravity_desc}><span>{global.strings.centerYOfGravity}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.centerYOfGravity * 1000000).toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['centerYOfGravity'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['centerYOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['centerYOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['centerYOfGravity'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.centerYOfGravity * 1000000).toFixed(4)} {
                                                                                                (typeof val.featureThresholds['centerYOfGravity'] !== 'undefined') ? '(% ' + (val.featureThresholds['centerYOfGravity']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['centerYOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['centerYOfGravity'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['centerYOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['centerYOfGravity'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['centerYOfGravity'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['centerYOfGravity'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-downLineLength'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.downLineLength_desc}><span>{global.strings.downLineLength}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{((this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.downLineLength * 1000000).toFixed(4))}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['downLineLength'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['downLineLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['downLineLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['downLineLength'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {((val.features.downLineLength * 1000000).toFixed(4))} {
                                                                                                (typeof val.featureThresholds['downLineLength'] !== 'undefined') ? '(% ' + (val.featureThresholds['downLineLength']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['downLineLength'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['downLineLength'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['downLineLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['downLineLength'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['downLineLength'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['downLineLength'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-distanceOfMaxY'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.distanceOfMaxY_desc}><span>{global.strings.distanceOfMaxY}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.distanceOfMaxY !== 'undefined') ? ((this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.distanceOfMaxY * 1000000).toFixed(4)) : '---'}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['distanceOfMaxY'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['distanceOfMaxY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['distanceOfMaxY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['distanceOfMaxY'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {((typeof val.features.distanceOfMaxY !== 'undefined') ? (val.features.distanceOfMaxY * 1000000).toFixed(4) : '---')} {
                                                                                                (typeof val.featureThresholds['distanceOfMaxY'] !== 'undefined') ? '(% ' + (val.featureThresholds['distanceOfMaxY']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['distanceOfMaxY'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['distanceOfMaxY'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['distanceOfMaxY'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['distanceOfMaxY'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['distanceOfMaxY'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['distanceOfMaxY'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.micrometer}</td>
                                                                </tr>
                                                                <tr id={'f-angleVerticalTip'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.angleVerticalTip_desc}><span>{global.strings.angleVerticalTip}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.angleVerticalTip !== 'undefined') ? ((this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.angleVerticalTip * 1000000).toFixed(4)) : '---'}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['angleVerticalTip'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['angleVerticalTip'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['angleVerticalTip'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['angleVerticalTip'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {((typeof val.features.angleVerticalTip !== 'undefined') ? (val.features.angleVerticalTip * 1000000).toFixed(4) : '---')} {
                                                                                                (typeof val.featureThresholds['angleVerticalTip'] !== 'undefined') ? '(% ' + (val.featureThresholds['angleVerticalTip']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['angleVerticalTip'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['angleVerticalTip'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['angleVerticalTip'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['angleVerticalTip'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['angleVerticalTip'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['angleVerticalTip'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.microangle}</td>
                                                                </tr>
                                                                <tr id={'f-baTurnAngle'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.baTurnAngle_desc}><span>{global.strings.baTurnAngle}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{((this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.baTurnAngle).map((v, k) => v = v * 1000000, 1)).join(', ')}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['baTurnAngle'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['baTurnAngle'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['baTurnAngle'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['baTurnAngle'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.baTurnAngle.length > 0) ? (val.features.baTurnAngle.map((v, k) => v = v * 1000000, 1)).join(', ') : null} {
                                                                                                (typeof val.featureThresholds['baTurnAngle'] !== 'undefined') ? '(% ' + (val.featureThresholds['baTurnAngle']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['baTurnAngle'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['baTurnAngle'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['baTurnAngle'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['baTurnAngle'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['baTurnAngle'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['baTurnAngle'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.microangle}</td>
                                                                </tr>
                                                                <tr id={'f-totalAngleTurn'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.totalAngleTurn_desc}><span>{global.strings.totalAngleTurn}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{(this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.totalAngleTurn * 1000000)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {
                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['totalAngleTurn'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['totalAngleTurn'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['totalAngleTurn'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['totalAngleTurn'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {(val.features.totalAngleTurn * 1000000)} {
                                                                                                (typeof val.featureThresholds['totalAngleTurn'] !== 'undefined') ? '(% ' + (val.featureThresholds['totalAngleTurn']).toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['totalAngleTurn'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['totalAngleTurn'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['totalAngleTurn'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['totalAngleTurn'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['totalAngleTurn'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['totalAngleTurn'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.microangle}</td>
                                                                </tr>
                                                                <tr id={'f-wthRatio'}>


                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.wthRatio_1_desc}><span>{global.strings.wthRatio_1}</span></Tooltip>
                                                                    </td>


                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{1 / this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.wthRatio.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['wthRatio'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['wthRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['wthRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['wthRatio'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {1 / val.features.wthRatio.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholds['wthRatio'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['wthRatio'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['wthRatio'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['wthRatio'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['wthRatio'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-bandwidth'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.bandWidth_desc}><span>{global.strings.bandWidth}</span></Tooltip>
                                                                    </td>


                                                                    <td scope="col">---</td>
                                                                    {(this.state.compare === true && typeof this.state.lastBandwidthValues !== 'undefined' && this.state.lastBandwidthValues !== null && this.state.lastBandwidthValues.length > 0) ?
                                                                        this.state.lastBandwidthValues.map((v, i) => {
                                                                            return (

                                                                                <td key={'a-bandwidth' + i}
                                                                                    scope="col">
                                                                                    {v}
                                                                                </td>

                                                                            )
                                                                        })

                                                                        : null}
                                                                    <td>{global.strings.mmeter}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist0'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist0}><span>{global.strings.arcTanHist0}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist0.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist0'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist0'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist0'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist0'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist0.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist0'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist0'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist0'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist0'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist0'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist0'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist0'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist0'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist1'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist1}><span>{global.strings.arcTanHist1}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist1.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist1'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist1'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist1'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist1'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist1.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist1'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist1'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist1'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist1'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist1'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist1'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist1'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist1'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist2'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist2}><span>{global.strings.arcTanHist2}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist2.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist2'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist2'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist2'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist2'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist2.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist2'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist2'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist2'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist2'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist2'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist2'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist2'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist2'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist3'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist3}><span>{global.strings.arcTanHist3}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist3.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist3'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist3'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist3'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist3'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist3.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist3'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist3'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist3'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist3'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist3'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist3'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist3'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist3'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist4'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist4}><span>{global.strings.arcTanHist4}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist4.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist4'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist4'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist4'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist4'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist4.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist4'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist4'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist4'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist4'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist4'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist4'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist4'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist4'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist5'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist5}><span>{global.strings.arcTanHist5}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist5.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist5'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist5'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist5'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist5'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist5.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist5'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist5'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist5'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist5'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist5'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist5'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist5'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist5'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist6'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist6}><span>{global.strings.arcTanHist6}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist6.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist6'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist6'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist6'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist6'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist6.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist6'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist6'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist6'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist6'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist6'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist6'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist6'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist6'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanHist7'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_hist7}><span>{global.strings.arcTanHist7}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanHist7.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanHist7'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanHist7'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanHist7'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanHist7'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanHist7.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanHist7'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanHist7'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanHist7'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanHist7'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanHist7'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanHist7'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanHist7'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanHist7'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>
                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanDiffHist0'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_diff_hist0}><span>{global.strings.arcTanDiffHist0}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanDiffHist0.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanDiffHist0'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanDiffHist0'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanDiffHist0'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanDiffHist0'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanDiffHist0.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanDiffHist0'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanDiffHist0'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanDiffHist0'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanDiffHist0'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanDiffHist0'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanDiffHist0'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanDiffHist0'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanDiffHist0'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanDiffHist1'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_diff_hist1}><span>{global.strings.arcTanDiffHist1}</span></Tooltip>
                                                                    </td>
                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanDiffHist1.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanDiffHist1'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanDiffHist1'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanDiffHist1'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanDiffHist1'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanDiffHist1.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanDiffHist1'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanDiffHist1'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanDiffHist1'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanDiffHist1'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanDiffHist1'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanDiffHist1'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanDiffHist1'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanDiffHist1'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanDiffHist2'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_diff_hist2}><span>{global.strings.arcTanDiffHist2}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanDiffHist2.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanDiffHist2'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanDiffHist2'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanDiffHist2'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanDiffHist2'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanDiffHist2.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanDiffHist2'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanDiffHist2'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanDiffHist2'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanDiffHist2'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanDiffHist2'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanDiffHist2'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanDiffHist2'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanDiffHist2'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>
                                                                <tr id={'f-arcTanDiffHist3'}>

                                                                    <td scope="col"><Tooltip
                                                                        title={global.strings.arc_tan_diff_hist3}><span>{global.strings.arcTanDiffHist3}</span></Tooltip>
                                                                    </td>

                                                                    {(typeof this.state.referenceJson[0].data !== undefined && typeof this.state.referenceJson[0].data[this.state.currentReferenceIndex] !== 'undefined') ?
                                                                        <td scope="col">{this.state.referenceJson[0].data[this.state.currentReferenceIndex].features.arcTanDiffHist3.toFixed(4)}</td> : null}
                                                                    {(this.state.compare === true && this.state.sampleJson !== null) ?

                                                                        this.state.sampleJson.map((v, i) => {

                                                                            if (typeof v.data !== 'undefined' && v.data.length > 0) {

                                                                                return v.data.map((val, key) => {

                                                                                    signInfo = _this.getCurrentSignInfo(val.signatureId);

                                                                                    if ((_isContains(currentSigns, val.signatureId) === true && currentGroups.includes(signInfo[0].group))) {

                                                                                        return (

                                                                                            <td key={'a-' + val.signatureId}
                                                                                                style={(typeof val.featureThresholds['arcTanDiffHist3'] !== 'undefined') ? {
                                                                                                    backgroundColor: customConstants.THRESHOLD_COLOR,
                                                                                                    color: '#000'
                                                                                                } : null
                                                                                                || (typeof val.featureThresholdsMid['arcTanDiffHist3'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_MID_COLOR} :
                                                                                                    (typeof val.featureThresholdsHigh['arcTanDiffHist3'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGH_COLOR} :
                                                                                                        (typeof val.featureThresholdsHighest['arcTanDiffHist3'] !== 'undefined') ? {backgroundColor: customConstants.THRESHOLD_HIGHEST_COLOR} :
                                                                                                            null
                                                                                                }
                                                                                                scope="col">
                                                                                                {val.features.arcTanDiffHist3.toFixed(4)} {
                                                                                                (typeof val.featureThresholds['arcTanDiffHist3'] !== 'undefined') ? '(% ' + val.featureThresholds['arcTanDiffHist3'].toFixed(2) + ')' :
                                                                                                    '' || (typeof val.featureThresholdsMid['arcTanDiffHist3'] !== 'undefined') ? '(% ' + val.featureThresholdsMid['arcTanDiffHist3'].toFixed(2) + ')' :
                                                                                                        (typeof val.featureThresholdsHigh['arcTanDiffHist3'] !== 'undefined') ? '(% ' + val.featureThresholdsHigh['arcTanDiffHist3'].toFixed(2) + ')' :
                                                                                                            (typeof val.featureThresholdsHighest['arcTanDiffHist3'] !== 'undefined') ? '(% ' + val.featureThresholdsHighest['arcTanDiffHist3'].toFixed(2) + ')' :
                                                                                                                ''
                                                                                            }
                                                                                            </td>

                                                                                        )

                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                    <td>{global.strings.supRatio}</td>
                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                        </div> : null}


                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        );
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
    }

}

export default DashBoard;



