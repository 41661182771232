import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";
import {NotificationManager} from "react-notifications";
import forensicLib from "../assets/js/forensicLib";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import locale from "../Date";

export class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tckn: '',
            caseId: '',
            fullname: '',
            date: '',
            responseCaseId: null,
            caseMargin: '',
            expertId: '',
        };
        this.updateExpertId = this.updateExpertId.bind(this);
        this.updateCaseMargin = this.updateCaseMargin.bind(this);
        this.updateCaseNo = this.updateCaseNo.bind(this);
        this.updateDateVal = this.updateDateVal.bind(this);
        this.updateTCKN = this.updateTCKN.bind(this);

    }

    componentDidMount() {

    }

    updateExpertId(v) {
        const expertValue = v.target.value;
        this.setState({
            expertId: expertValue
        });
    }

    updateTCKN(v) {
        const tckn = v.target.value;
        this.setState({
            tckn: tckn
        });
    }

    updateCaseMargin(v) {
        const caseMargin = v.target.value;
        this.setState({
            caseMargin: caseMargin
        }, () => {
        });
    }

    updateCaseNo(v) {
        const caseNo = v.target.value;
        this.setState({
            caseNo: caseNo
        }, () => {
        });
    }

    updateDateVal(v) {
        this.setState({
            date: v
        }, () => {
            // console.log(v);
        });
    }

    createCase(e, caseObj) {

        e.stopPropagation();
        // caseObj.date = "2019-07-03T21:00:11Z";

        if (typeof this.state.requestDate !== 'undefined' || this.state.requestDate !== null || this.state.requestDate !== '') {

            this.state.date = new Date();
        }

        const data = {
            "caseId": this.state.caseNo,
            "specialityNo": this.state.expertId,
            "nameSurname": "",
            "requestAuthority": this.state.caseMargin,
            "date": moment(this.state.requestDate).toISOString().split('.')[0] + "Z",
            "tckn": this.state.tckn,

        };

        if ((this.state.caseNo === null || this.state.expertId === null || this.state.expertId === null)
            || (this.state.caseNo === '' || this.state.expertId === '')) {
            return NotificationManager.warning(global.strings.requiredFields);
        } else {
            this.setState({loading: true});
            fetch(customConstants.apiUrl + "/cases", {
                method: 'POST',
                headers: {
                    Authorization: forensicLib.getAuthHeader(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(result => {
                    this.setState({loading: false});
                    this.continueToUpload(result);

                })
                .catch(err => {
                    this.setState({loading: false});
                    //return NotificationManager.error(err);
                });
        }


    }

    continueToUpload(result ) {
        sessionStorage.setItem('responseCaseId', result);
        sessionStorage.setItem('caseId', this.state.caseNo);
        sessionStorage.setItem('specialityNo', this.state.expertId);
        sessionStorage.setItem('requestAuthority', this.state.caseMargin);
        sessionStorage.setItem('date', moment(this.state.requestDate).toISOString().split('.')[0] + "Z");
        this.props.history.push('/upload');
    }

    render() {
        return (


            <div className="limiter">
                <Loading loading={this.state.loading} background="rgba(255,255,255,.7)" loaderColor="#0D386B"/>
                <div className="container-login100">
                    <div className="wrap-login100">

                        <form className="login100-form validate-form">
					<span className="login100-form-title">
                        {global.strings.appTitle.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
					</span>
                            <span className="login100-form-desc">{global.strings.loginDesc}</span>

                            <div className="flex-row wrap-input100 validate-input">
                                <label className="lf--label-form" htmlFor="username">
                                    <svg x="0px" y="0px" width="12px" height="13px">
                                        <path fill="#B1B7C4"
                                              d="M8.9,7.2C9,6.9,9,6.7,9,6.5v-4C9,1.1,7.9,0,6.5,0h-1C4.1,0,3,1.1,3,2.5v4c0,0.2,0,0.4,0.1,0.7 C1.3,7.8,0,9.5,0,11.5V13h12v-1.5C12,9.5,10.7,7.8,8.9,7.2z M4,2.5C4,1.7,4.7,1,5.5,1h1C7.3,1,8,1.7,8,2.5v4c0,0.2,0,0.4-0.1,0.6 l0.1,0L7.9,7.3C7.6,7.8,7.1,8.2,6.5,8.2h-1c-0.6,0-1.1-0.4-1.4-0.9L4.1,7.1l0.1,0C4,6.9,4,6.7,4,6.5V2.5z M11,12H1v-0.5 c0-1.6,1-2.9,2.4-3.4c0.5,0.7,1.2,1.1,2.1,1.1h1c0.8,0,1.6-0.4,2.1-1.1C10,8.5,11,9.9,11,11.5V12z"></path>
                                    </svg>
                                </label>
                                <input id="tckn" className="lf--input input100"
                                       value={this.state.expertId}
                                       onChange={this.updateExpertId}
                                       placeholder={global.strings.expertId + ' *'}
                                       type="text"/>
                                <span className="focus-input100"></span>
                            </div>


                                <div className={'create-case-steps'} style={{display : (this.state.expertId !== '' && this.state.expertId.length >= 3) ? 'block' : 'none'}}>
                                    <div className="flex-row wrap-input100 validate-input">
                                        <label className="lf--label-form" htmlFor="username">
                                            <svg x="0px" y="0px" width="12px" height="13px">
                                                <path fill="#B1B7C4"
                                                      d="M8.9,7.2C9,6.9,9,6.7,9,6.5v-4C9,1.1,7.9,0,6.5,0h-1C4.1,0,3,1.1,3,2.5v4c0,0.2,0,0.4,0.1,0.7 C1.3,7.8,0,9.5,0,11.5V13h12v-1.5C12,9.5,10.7,7.8,8.9,7.2z M4,2.5C4,1.7,4.7,1,5.5,1h1C7.3,1,8,1.7,8,2.5v4c0,0.2,0,0.4-0.1,0.6 l0.1,0L7.9,7.3C7.6,7.8,7.1,8.2,6.5,8.2h-1c-0.6,0-1.1-0.4-1.4-0.9L4.1,7.1l0.1,0C4,6.9,4,6.7,4,6.5V2.5z M11,12H1v-0.5 c0-1.6,1-2.9,2.4-3.4c0.5,0.7,1.2,1.1,2.1,1.1h1c0.8,0,1.6-0.4,2.1-1.1C10,8.5,11,9.9,11,11.5V12z"></path>
                                            </svg>
                                        </label>
                                        <input id="caseId" className="lf--input input100"
                                               value={this.state.caseMargin}
                                               onChange={this.updateCaseMargin}
                                               placeholder={global.strings.caseMargin + ' *'}
                                               type="text"/>
                                        <span className="focus-input100"></span>
                                    </div>

                                    {/*<div className="flex-row wrap-input100 validate-input">
                                        <label className="lf--label-form" htmlFor="username">
                                            <svg x="0px" y="0px" width="12px" height="13px">
                                                <path fill="#B1B7C4"
                                                      d="M8.9,7.2C9,6.9,9,6.7,9,6.5v-4C9,1.1,7.9,0,6.5,0h-1C4.1,0,3,1.1,3,2.5v4c0,0.2,0,0.4,0.1,0.7 C1.3,7.8,0,9.5,0,11.5V13h12v-1.5C12,9.5,10.7,7.8,8.9,7.2z M4,2.5C4,1.7,4.7,1,5.5,1h1C7.3,1,8,1.7,8,2.5v4c0,0.2,0,0.4-0.1,0.6 l0.1,0L7.9,7.3C7.6,7.8,7.1,8.2,6.5,8.2h-1c-0.6,0-1.1-0.4-1.4-0.9L4.1,7.1l0.1,0C4,6.9,4,6.7,4,6.5V2.5z M11,12H1v-0.5 c0-1.6,1-2.9,2.4-3.4c0.5,0.7,1.2,1.1,2.1,1.1h1c0.8,0,1.6-0.4,2.1-1.1C10,8.5,11,9.9,11,11.5V12z"></path>
                                            </svg>
                                        </label>
                                        <input id="caseId" className="lf--input input100"
                                               value={this.state.tckn}
                                               onChange={this.updateTCKN}
                                               placeholder={global.strings.tckn + ' *'}
                                               type="text"/>
                                        <span className="focus-input100"></span>
                                    </div>*/}

                                    <div className="flex-row wrap-input100 validate-input">
                                        <label className="lf--label-form" htmlFor="username">
                                            <svg x="0px" y="0px" width="12px" height="13px">
                                                <path fill="#B1B7C4"
                                                      d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"></path>
                                            </svg>
                                        </label>
                                        <DatePicker
                                            popperPlacement="top-end"
                                            locale={localStorage.getItem('_lang') === 'tr' ?  locale : null}
                                            placeholderText={global.strings.requestDate}
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.date}
                                            onChange={this.updateDateVal}
                                        />
                                        <span className="focus-input100"></span>
                                    </div>

                                    <div className="flex-row wrap-input100 validate-input">
                                        <label className="lf--label-form" htmlFor="username">
                                            <svg x="0px" y="0px" width="12px" height="13px">
                                                <path fill="#B1B7C4"
                                                      d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"></path>
                                            </svg>
                                        </label>
                                        <input id="fullname" className="lf--input input100"
                                               value={this.state.caseNo}
                                               onChange={this.updateCaseNo}
                                               placeholder={global.strings.caseNo + ' *'}
                                               type="text"/>
                                        <span className="focus-input100"></span>
                                    </div>

                                    <div className="container-login100-form-btn">
                                        <div onClick={(e) => this.createCase(e)} lang="en"
                                             className="login100-form-btn">
                                            <svg x="0px" y="0px" width="25px" height="23px"
                                                 viewBox="0 0 31.49 31.49"
                                            >
                                                <path fill="#ffffff"
                                                      d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                        </form>
                        <div className="login100-pic js-tilt" data-tilt>
                            <img src={'./assets/images/logo-' + customConstants.appName + '.png'} alt="IMG"/>
                        </div>

                    </div>
                </div>
            </div>

        );
    }

}

export default withRouter(Selection);



