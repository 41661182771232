import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";
import {NotificationManager} from "react-notifications";

export class Selection extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (


            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">

                        <form className="login100-form validate-form">
					<span className="login100-form-title">
                        {global.strings.appTitle.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
					</span>
                            <span className="login100-form-desc">{global.strings.loginDesc}</span>

                            <button onClick={() => this.props.history.push('/register')}
                                    className={'btn btn-primary selection-btn'}>{global.strings.newRecord}</button>
                            <button onClick={() => this.props.history.push('/search')} className={'btn btn-primary selection-btn'}>{global.strings.search}</button>
                        </form>
                        <div className="login100-pic js-tilt" data-tilt>
                            <img src={'./assets/images/logo-' + customConstants.appName + '.png'} alt="IMG"/>
                        </div>

                    </div>
                </div>
            </div>

        );
    }

}

export default withRouter(Selection);



