import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";
import {NotificationManager} from "react-notifications";
import forensicLib from "../assets/js/forensicLib";
import Loading from 'react-fullscreen-loading';
import Moment from 'react-moment';
import {confirmAlert} from "react-confirm-alert";
import {logout} from "../Helpers";
import LeftMenu from "../components/LeftMenu";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {FormControl, InputLabel} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {DataGrid} from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Edit from '@material-ui/icons/Edit';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Dropzone from "react-dropzone";
import { saveAs } from 'file-saver';
var JSZip = require("jszip");
let _this;

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


export class ExtraEvidence extends Component {

    constructor(props) {
        super(props);
        this.state = {
            key: '',
            file: null
        };
        _this = this;
        this.uploadPdfFile = this.uploadPdfFile.bind(this);
    }

    getBase64FromFile(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            _this.setState({file: reader.result.replace('data:application/pdf;base64,', ''), loading: false});
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    uploadPdfFile = (file) => {

        let files = Array.from(file);
        _this.setState({loading: true});


        files.map(function (f) {

            _this.setState({fileName: f.name});
            _this.getBase64FromFile(f);

        })


    }

    updateKey(v) {
        const key = v.target.value;
        this.setState({
            key: key
        }, () => {

        });
    }

    importNow() {

        if (this.state.file === null) {
            return NotificationManager.warning(global.strings.missingFile);
        } else if (this.state.key === '') {
            return NotificationManager.warning(global.strings.missingKey);
        } else {
            this.setState({loading: true});
            fetch(customConstants.apiUrl + "/signature/extractSignatures", {
                method: 'POST',
                headers: {
                    Authorization: forensicLib.getAuthHeader(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "pdfSource": _this.state.file,
                    "privateKey": _this.state.key
                })
            })
                .then(response => response.json())
                .then(result => {
                    this.setState({loading: false});
                    _this.downloadZip(result.signatureList);
                })
                .catch(err => {

                    //return NotificationManager.error(err);
                });
        }

    }

    _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }


    async downloadZip(files) {
        var zip = new JSZip();

        this.setState({loading: false});

        if (typeof files !== 'undefined') {
            files.map(function(v,k) {
                let arrayBuffer = _this._base64ToArrayBuffer(v.signatureBase64);
                zip.file(v.reason.replace('/','|') + '.sig', arrayBuffer);
            });

            zip.generateAsync({type:"blob"}).then(function(content) {
                saveAs(content, _this.state.fileName + ".zip");
            })
        }

    }

    removeFile() {
        this.setState({file: null});
    }

    render() {
        return (


            <div>

                <Loading style={{zIndex: 99999}} loading={this.state.loading} background="rgba(255,255,255,.7)"
                         loaderColor="#0D386B"/>
                <div className="navbar navbar-expand navbar-light bg-white topbar  static-top shadow"
                     id={'topBarMenu'}>

                    <div onClick={() => logout(this)} lang="en" className="logout-form-btn">
                        {global.strings.logout}
                    </div>

                </div>
                <div id="wrapper">

                    <LeftMenu enableAdminMenu={true}/>

                    <div className={'main-container'}>


                        <div style={{backgroundColor: '#fff', 'margin': 0, 'width': '100%', display: 'inline-block'}}>

                                <span
                                    className={'dashboard-header'}>{global.strings.extraEvidence.toUpperCase()}</span>


                        </div>
                        <div style={{textAlign: 'right', margin: 16}}>
                            <Button onClick={() => this.importNow()} size={'medium'}
                                    variant="contained" style={{}} color="primary">
                                {global.strings.submit}
                            </Button>
                        </div>
                        <div className="upload-container-pdf">
                            <label>{global.strings.file}</label>
                            {(this.state.file === null) ?
                                <>
                                    <Dropzone accept=".pdf" multiple={false}
                                              onDropAccepted={acceptedFiles => this.uploadPdfFile(acceptedFiles, true)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()}  />
                                                    <p>
                                                        <svg
                                                            width="100"
                                                            height="60"
                                                            viewBox="0 0 50 43">
                                                            <path fill="#ccc" d="M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266
			C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h6v-2H5.997
			C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7
			c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03
			c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4H20v2h6c3.312,0,6-2.693,6-6C32,15.735,30.13,13.407,27.586,12.712z"/>
                                                            <polygon fill="#ccc"
                                                                     points="16,13.5 11,19.5 14,19.5 14,27.5 18,27.5 18,19.5 21,19.5 		"/>
                                                        </svg>
                                                        <br/><br/>
                                                        {global.strings.uploadContainerPDF}
                                                    </p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone></> : <div>
                                    <div className={'uploaded-file'}>{this.state.fileName} <span
                                        style={{cursor: 'pointer', marginLeft: 15}}
                                        onClick={() => this.removeFile()}>X</span></div>
                                </div>}

                            <FormControl fullWidth
                                         size="small"
                                         variant="outlined">

                                <InputLabel
                                    htmlFor="tckn" className={'search-label'}>{global.strings.key}</InputLabel>
                                <OutlinedInput
                                    size="small"
                                    id="tckn"
                                    onChange={(key) => this.updateKey(key)}
                                    value={this.state.key}
                                    labelWidth={60}
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>)
    }

}

export default withRouter(ExtraEvidence);



