import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";
import {NotificationManager} from "react-notifications";
import forensicLib from "../assets/js/forensicLib";
import Loading from 'react-fullscreen-loading';
import Moment from 'react-moment';
import {confirmAlert} from "react-confirm-alert";
import {logout} from "../Helpers";
import LeftMenu from "../components/LeftMenu";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {FormControl, InputLabel} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {DataGrid} from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Edit from '@material-ui/icons/Edit';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

let _this;

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            searchResult: [],
            delete: false,
            add: false,
            role: ''

        };
        _this = this;

        this.columns = [
            {field: 'uuid', headerName: 'ID', flex: 1, sortable: false, filterable: false, hide: true},
            {
                field: 'username',
                headerName: global.strings.username,
                flex: 1,
                sortable: false,
                filterable: false,
                hidable: false
            },
            {
                field: 'role',
                headerName: global.strings.role,
                flex: 1,
                sortable: false,
                filterable: false,
                hidable: false,
                renderCell: (params) => (
                   <>{(params.row.role === 'admin') ? global.strings.admin : global.strings.viewer }</>
                )
            },
            {
                field: 'createdAt',
                headerName: global.strings.created_at,
                flex: 1,
                sortable: false,
                filterable: false,
                hidable: false
            },
            {
                field: 'action', headerName: ' ', sortable: false, filterable: false, hidable: false, width: 120,
                renderCell: (params) => (
                    <>
                        <Edit style={{cursor: 'pointer'}} onClick={(item) => this.editUser(params, item)}
                                        title={global.strings.getFile}/>
                        <DeleteIcon style={{cursor: 'pointer', marginLeft: 10}}
                                    onClick={(item) => this.askToRemove(params)} title={global.strings.delete}/>
                    </>
                )
            }
        ];

        this.continueToAdd = this.continueToAdd.bind(this);

    }

    updateUsername(v) {
        this.setState({
            username: v.target.value
        });
    }

    updatePassword(v) {
        this.setState({
            password: v.target.value
        });
    }

    updateRole(v) {
        this.setState({
            role: v.target.value
        });
    }

    add(params) {
        this.setState({add: true, params: params,isEdit:false});
    }

    editUser(params) {
        this.setState({
            add: true,
            params: params,
            isEdit:true,
            username:params.row.username,
            role:params.row.role,
        });
    }

    askToRemove(params) {
        this.setState({delete: true, params: params});
    }

    handleClose = () => {
        this.setState({delete: false});
    }

    handleCloseAdd = () => {
        this.setState({add: false});
    }

    componentDidMount() {
        this.getUser(true);
    }

    getUser(force = false) {

        this.setState({loading: true});
        fetch(customConstants.apiUrl + "/user", {
            method: 'GET',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(result => {


                if (result.length === 0) {
                    this.setState({loading: false});
                    return NotificationManager.info(global.strings.noRecord);
                } else {

                    var lastResult = [];
                    result.map(function (v, k) {
                        lastResult.push({
                            id: k,
                            username: v.username,
                            role: v.role,
                            createdAt: v.createdAt
                        });
                    });

                    this.setState({loading: false, searchResult: lastResult});
                }
                // this.continueToReport(result);
            })
            .catch(err => {
                // console.log(err);
                this.setState({loading: false});
                // return NotificationManager.error(err);
            });

    }

    removeUser(params) {
        this.setState({loading: true});
        fetch(customConstants.apiUrl + "/user/" + params.row.username, {
            method: 'DELETE',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(result => {

                let newSearchResult = this.state.searchResult.filter(function (v, j) {
                    if (v.id !== params.row.id) {
                        return v;
                    }
                });

                this.setState({loading: false, searchResult: newSearchResult,delete:false}, () => {
                    return NotificationManager.success(global.strings.user_removed);
                });

            })
            .catch(err => {
                //console.log(err);
                this.setState({loading: false});
                // return NotificationManager.error(err);
            });
    }

    onOpenCase = (params) => {
        this.getCase(params.row);
    }

    continueToRemove = () => {
        this.removeUser(this.state.params);
    }

    continueToAdd() {

        if (this.state.isEdit === false) {
            const data = {
                "username": this.state.username,
                "password": this.state.password,
                "role": this.state.role,
                "language": "tr"
            };
        } else {
            const data = {
                "password": this.state.password,
                "role": this.state.role,
                "language": "tr"
            };
        }
        const data = {
            "username": this.state.username,
            "password": this.state.password,
            "role": this.state.role,
            "language": "tr"
        };

        if ((this.state.username === '' || (this.state.password === '' && this.state.isEdit === false))) {
            return NotificationManager.warning(global.strings.requiredFields);
        } else {
            this.setState({loading: true});
            fetch((this.state.isEdit === false) ? customConstants.apiUrl + "/user" : customConstants.apiUrl + "/user/" + this.state.params.row.username, {
                method: 'POST',
                headers: {
                    Authorization: forensicLib.getAuthHeader(),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(result => {

                    this.getUser();
                    this.setState({loading: false, add: false, username: '', password: '', role: ''});

                })
                .catch(err => {
                    // console.log(err);
                    this.setState({loading: false});
                    return NotificationManager.error(err);
                });
        }
    }

    setUser = (user) => {
        this.setState({sharedUsers: user});
    }

    render() {
        return (


            <div>
                <Dialog
                    open={this.state.delete}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{global.strings.want_to_delete_user}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {global.strings.want_to_delete_user_body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" className={'cancel'}>
                            {global.strings.cancel}
                        </Button>
                        <Button onClick={this.continueToRemove} color="primary" autoFocus>
                            {global.strings.continue}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.add}
                    onClose={this.handleCloseAdd}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{(this.state.isEdit === false) ? global.strings.add_new_user_title : global.strings.edit_user_title_user}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container spacing={3}>

                                <Grid item xs={12} lg={6} md={6}>

                                    <FormControl fullWidth
                                                 size="small"
                                                 variant="outlined">
                                        <InputLabel
                                            htmlFor="tckn">{global.strings.username + ' *'}</InputLabel>
                                        <OutlinedInput
                                            size="small"
                                            id="tckn"
                                            disabled={(this.state.isEdit === true)}
                                            onChange={(username) => this.updateUsername(username)}
                                            value={this.state.username}
                                            labelWidth={110}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>

                                    <FormControl fullWidth
                                                 size="small"
                                                 variant="outlined">
                                        <InputLabel
                                            htmlFor="caseId">{global.strings.user_password + ' *'}</InputLabel>
                                        <OutlinedInput
                                            size="small"
                                            id="caseId"
                                            onChange={(password) => this.updatePassword(password)}
                                            value={this.state.password}
                                            labelWidth={70}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>


                                    <FormControl fullWidth
                                                 size="small"
                                                 variant="outlined">
                                        <InputLabel id="demo-simple-select-outlined-label">{global.strings.role + ' *'}</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.role}
                                            onChange={(role) => this.updateRole(role)}
                                            label={global.strings.role}
                                        >

                                            <MenuItem value={'admin'}>{global.strings.admin}</MenuItem>
                                            <MenuItem value={'viewer'}>{global.strings.viewer}</MenuItem>

                                        </Select>
                                    </FormControl>

                                </Grid>

                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseAdd} color="primary" className={'cancel'}>
                            {global.strings.cancel}
                        </Button>
                        <Button onClick={this.continueToAdd} color="primary" autoFocus>
                            {(this.state.isEdit === false) ? global.strings.add : global.strings.edit}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Loading style={{zIndex: 99999}} loading={this.state.loading} background="rgba(255,255,255,.7)"
                         loaderColor="#0D386B"/>
                <div className="navbar navbar-expand navbar-light bg-white topbar  static-top shadow"
                     id={'topBarMenu'}>

                    <div onClick={() => logout(this)} lang="en" className="logout-form-btn">
                        {global.strings.logout}
                    </div>

                </div>
                <div id="wrapper">

                    <LeftMenu enableAdminMenu={true}/>

                    <div className={'main-container'}>

                        <div style={{backgroundColor: '#fff', 'margin': 0, 'width': '100%', display: 'inline-block'}}>

                                <span
                                    className={'dashboard-header'}>{global.strings.users.toUpperCase()}</span>


                        </div>

                        <div style={{margin: 6, marginTop: 16,marginRight:14, textAlign: 'right'}}>
                            <Button onClick={() => this.add(this)} size={'medium'}
                                    variant="contained" style={{}} color="primary">
                                {global.strings.add}
                            </Button>
                        </div>


                        {(this.state.searchResult.length > 0) ?

                            <div style={{padding: 15}}>
                                <DataGrid className={'data-grid'} rows={this.state.searchResult} autoHeight={true}
                                          columns={this.columns}
                                          pageSize={5} showToolbar={false}/>

                            </div>
                            : null}
                    </div>
                </div>
            </div>)
    }

}

export default withRouter(Users);



