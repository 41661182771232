import React, {Component} from 'react';
import customConstants from "../Globals";

export class UnderConstructionPage extends Component {


    render() {
        return ( <div style={{backgroundImage: "url('../assets/images/uc.jpeg')",width:'100%',height:'100%',position:'fixed',backgroundPosition:'bottom'}}>

            <div className={'under-const'}>
                <img src={'/assets/images/logo-' + customConstants.appName + '.png'} width={200}/>
                <div style={{width:'100%'}}> {global.strings.underCons}</div>
            </div>

        </div>);
    }

}

export default UnderConstructionPage;