const customConstants = {
    apiUrl: 'http://104.199.28.84:8070',
    apiForensicUrl: 'https://testapi.biyometrikimza.com',
    apiForensicUrlOpen: 'http://104.199.28.84:8070',
    baseUrl: 'http://localhost:3000',
    appName : 'techsign',
    //appTitle : "T.C\nİÇİŞLERİ BAKANLIĞI\nJANDARMA GENEL KOMUTANLIĞI",
    appTitle : "Techsign Biyometrik İmza Analiz Yazılımı",
    showTypes : {
        VIEW : 1,
        ANIMATION : 2,
        PARAMETER : 3,
        VALUE : 4,
        COMPARE : 5,
    },
    THRESHOLD : 3, // Yüzde
    THRESHOLD_COLOR : '#2991bd', // Yüzde
    THRESHOLD_MID : 21, // Yüzde
    THRESHOLD_HIGH : 40, // Yüzde
    THRESHOLD_HIGHEST : 60, // Yüzde
    THRESHOLD_MID_COLOR : '#36a83d', // Yüzde
    THRESHOLD_HIGH_COLOR : '#e8832a', // Yüzde
    THRESHOLD_HIGHEST_COLOR : '#ff4130', // Yüzde
    COLORS : ['#c7012b', '#829150', '#D1603D', '#4843ee', '#4C5B5C', '#ff18c3', '#CCBE80', '#f3cbf9', '#205B66', '#CCBE80']
};
export default customConstants;
