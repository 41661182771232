import React, {Component} from 'react';
import LeftMenu from "../components/LeftMenu";
import 'react-notifications/lib/notifications.css';
import {saveAs} from 'file-saver';
import {Progress} from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import {logout} from "../Helpers";
import 'react-confirm-alert/src/react-confirm-alert.css';
import html2canvas from 'html2canvas';
import Loading from 'react-fullscreen-loading';
import jsPDF from 'jspdf';
import customConstants from "../Globals";

let _this;


export class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {showReportPreview: false};
        this.updateReportFirst = this.updateReportFirst.bind(this);
        this.updateResultReport = this.updateResultReport.bind(this);
        this.caseUUID = sessionStorage.getItem('responseCaseId');
    }

    componentDidMount() {
        // this.doCommon();
    }

    updateReportFirst(event) {
        this.setState({
            reportFirst: event.target.value
        });
    }

    updateResultReport(event) {
        this.setState({
            resultReport: event.target.value
        });
    }

    continueToReport = async () => {
        this.setState({showReportPreview: true}, async () => {

            const lastFirst = this.state.reportFirst.split("\n").map(function (item, idx) {
                return (
                    <span key={idx}>
                {item}
                        <br/>
            </span>
                )
            });

            const lastResult = this.state.resultReport.split("\n").map(function (item, idx) {
                return (
                    <span key={idx}>
                {item}
                        <br/>
            </span>
                )
            });

            this.setState({reportFirst: lastFirst, resultReport: lastResult});

            const input = document.getElementById('capture');

            const caseId = await sessionStorage.getItem('responseCaseId');
            html2canvas(input).then(canvas => {
                this.setState({showReportPreview: false}, () => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'px', 'a4');
                    const width = pdf.internal.pageSize.getWidth();
                    const height = pdf.internal.pageSize.getHeight();

                    pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                    pdf.save("report-" + caseId + ".pdf");
                });
            });

        });

    }

    render() {

        return (
            <div>
                <Loading loading={this.state.loading} background="rgba(255,255,255,.7)" loaderColor="#0D386B"/>
                <div className="navbar navbar-expand navbar-light bg-white topbar  static-top shadow" id={'topBarMenu'}>

                    <div onClick={() => logout(this)} lang="en" className="logout-form-btn">
                        {global.strings.logout}
                    </div>

                </div>
                <div id="wrapper">

                    <LeftMenu enableAdminMenu={true}/>

                    <div className={'main-container'} >
                        <div className={'row'}>

                            <div style={{'backgroundColor': '#fff', 'margin': 0, 'width': '100%'}}>
                                <div className={'col-12'} style={{'display': 'flow-root'}}>
                                <span
                                    className={'dashboard-header'}>{global.strings.createReport.toUpperCase()}</span>
                                </div>
                            </div>
                        </div>

                        <div className={'container'} style={{'padding': 0, 'maxWidth': '100%'}}>
                            <div className={''}>
                                <div className={'col-12'}>

                                    <div className={'standard-box'}>
                                        <div className={'col-12'} style={{'display': 'flow-root'}}>
                                        <span
                                            className={'dashboard-header'}>{global.strings.reportFirst}</span>

                                        </div>
                                        <div className={'col-12'} style={{'display': 'flow-root'}}>
                                            <textarea className={'reportTextArea'}
                                                      onChange={this.updateReportFirst}
                                                      placeholder={global.strings.afterReport}/>
                                        </div>
                                        <div className={'col-12'} style={{'display': 'flow-root'}}>
                                        <span
                                            className={'dashboard-header'}>{global.strings.report}</span>

                                        </div>
                                        <div className={'col-12'} style={{'display': 'flow-root'}}>
                                            <textarea className={'reportTextArea'}
                                                      onChange={this.updateResultReport}
                                                      placeholder={global.strings.resultReport}/>
                                        </div>
                                        <div className="container-login100-form-btn">
                                            <div onClick={() => this.continueToReport()} lang="en"
                                                 className="login100-form-btn" style={{margin: 10}}>

                                                {global.strings.submitReport}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.showReportPreview === true) ?
                    <div id={'capture'} style={{
                        padding: 30, width: '210mm',
                        minHeight: '297mm',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: '#000'
                    }}>
                        <div style={{textAlign: 'center', fontWeight: 'bold'}}>{customConstants.appTitle}</div>
                        <div style={{textAlign: 'center', marginBottom: '50px', marginTop: 30}}>
                            <img src={'./assets/images/logo-' + customConstants.appName + '.png'} width={100}
                                 alt="IMG"/>
                        </div>
                        <div><span
                            style={{fontWeight: 'bold'}}>{global.strings.tckn}</span> : {sessionStorage.getItem('tckn')}
                        </div>
                        <div
                            style={{marginBottom: 50}}><span
                            style={{fontWeight: 'bold'}}>{global.strings.caseNo}</span> : {sessionStorage.getItem('caseId')}
                        </div>
                        <h6 style={{marginBottom: '20px', fontWeight: 'bold'}}>{global.strings.reportFirst}</h6>
                        <div style={{marginBottom: '20px'}}>{this.state.reportFirst}</div>
                        <h6 style={{marginBottom: '20px', fontWeight: 'bold'}}>{global.strings.afterReport}</h6>
                        <div style={{marginBottom: '20px'}}>{this.state.resultReport}</div>
                    </div> : null}
            </div>

        );
    }

}

export default Reports;



