import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import customConstants from "../Globals";
import {NotificationManager} from "react-notifications";
import forensicLib from "../assets/js/forensicLib";
import Loading from 'react-fullscreen-loading';
import {confirmAlert} from "react-confirm-alert";
import {logout} from "../Helpers";
import LeftMenu from "../components/LeftMenu";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {FormControl, InputLabel} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {DataGrid} from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Share from '@material-ui/icons/Share';
import {Alert} from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import uuid from 'react-uuid';
import moment from 'moment';
import tr from 'moment/locale/tr';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

let _this;

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tckn: '',
            page: 0,
            caseId: '',
            searchResult: [],
            delete: false,
            share: false,
            sharedUsers: [],
            currentUsers: [],
            users: [],
            end_date: null,
            start_date: null,
            startDate: +new Date()
        };
        _this = this;
        this.updateTCKN = this.updateTCKN.bind(this);
        this.shareWithUsers = this.shareWithUsers.bind(this);
        this.updateCaseId = this.updateCaseId.bind(this);

        this.columns = [
            {field: 'uuid', headerName: 'ID', flex: 1, sortable: false, filterable: false, hidable: false},
            {
                field: 'specialityNo',
                headerName: global.strings.speciality_no,
                flex: 1,
                sortable: false,
                filterable: false,
                hidable: false
            }, {
                field: 'requestAuthority',
                headerName: global.strings.requestAuthority,
                hide: true,
                flex: 1,
            },
            {
                field: 'caseId',
                headerName: global.strings.caseNo,
                flex: 1,
                sortable: false,
                filterable: false,
                hidable: false
            },
            {
                field: 'caseDate',
                headerName: global.strings.caseDate,
                flex: 1,
                sortable: false,
                filterable: false,
                hidable: false,
                renderCell: (params) => (
                    <>
                        {moment(new Date(params.row.caseDate)).format('DD-MM-YYYY HH:mm')}
                    </>
                )
            },
            {
                field: 'tokens',
                headerName: global.strings.shared_users,
                flex: 1,
                sortable: false,
                filterable: false,
                hidable: false,
                renderCell: (params) => (
                    <>
                        {params.row.tokens.map(function (v, k) {
                            return v.user + ((params.row.tokens.length > k + 1) ? ' | ' : '');
                        })}
                    </>
                )
            },
            {
                field: 'action', headerName: ' ', sortable: false, filterable: false, hidable: false, width: 120,
                renderCell: (params) => (
                    <>
                        <VisibilityIcon style={{cursor: 'pointer'}} onClick={(item) => this.onOpenCase(params, item)}
                                        title={global.strings.getFile}/>
                        <Share style={{cursor: 'pointer', marginLeft: 10}} onClick={(item) => this.share(params)}
                               title={global.strings.delete}/>
                        <DeleteIcon style={{cursor: 'pointer', marginLeft: 10}}
                                    onClick={(item) => this.askToRemove(params)} title={global.strings.delete}/>
                    </>
                )
            }
        ];


        this.tokenType = {
            CASEMNGMENT: 'CASEMNGMENT'
        }


    }

    share(params) {
        this.getUsers();
        let sharedUsers = [];


        params.row.tokens.map(function (v, k) {
            sharedUsers.push({label: v.user, key: v.user});
        });


        this.setState({share: true, params: params, sharedUsers: sharedUsers}, () => {

        });
    }

    shareWithUsers() {

        var data = {
            "uuid": this.state.params.row.uuid,
            "tokens": []

        }

        this.state.sharedUsers.map(function (v, k) {

            data.tokens.push({
                "tokenID": uuid(),
                "user": v.label,
                "tokenType": _this.tokenType.CASEMNGMENT
            });

        });

        fetch(customConstants.apiUrl + "/cases/update-tokens", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {

                if (result == true) {
                    this.setState({loading: false, share: false});

                    this.searchNow();

                    return NotificationManager.success(global.strings.shared_with_users);
                }

            })
            .catch(err => {
                // console.log(err);
                this.setState({loading: false});
                // return NotificationManager.error(err);
            });
    }

    askToRemove(params) {
        this.setState({delete: true, params: params});
    }

    handleClose = () => {
        this.setState({delete: false});
    }

    handleCloseShare = () => {
        this.setState({share: false});
    }

    componentDidMount() {
        this.updateStartDate(moment(+new Date()).subtract({days: 30}));
        this.updateEndDate(moment(+new Date()));

        setTimeout(function () {
            _this.searchCase();
        }, 1000)
    }

    updateTCKN(v) {
        const tcknValue = v.target.value;
        this.setState({
            tckn: tcknValue
        }, () => {
        });
    }

    updateCaseId(v) {
        const caseIdValue = v.target.value;
        this.setState({
            caseId: caseIdValue
        }, () => {
        });
    }

    updateRequestAuthority(v) {
        const requestAuthority = v.target.value;
        this.setState({
            requestAuthority: requestAuthority
        }, () => {
        });
    }

    updateSpecialityNo(v) {

        const specialityNo = v.target.value;
        this.setState({
            specialityNo: specialityNo
        }, () => {
        });

    }

    updateEndDate = (date) => {

        this.setState({
            endDate: date,
            endInputValue: moment(date).format("DD-MM-YYYY")
        });

    }

    updateStartDate = (date) => {

        this.setState({
            startDate: date,
            startInputValue: moment(date).format("DD-MM-YYYY")
        });

    }

    updateCaseMargin(v) {
        const requestAuthority = v.target.value;
        this.setState({
            requestAuthority: requestAuthority
        }, () => {
        });
    }

    getCase = async (item) => {

        let samples = [];
        let reference = [];

        console.log(item);

        sessionStorage.setItem('responseCaseId', item.uuid);
        sessionStorage.setItem('caseId', item.caseId);
        sessionStorage.setItem('tckn', item.tckn);
        sessionStorage.setItem('specialityNo', item.specialityNo);
        sessionStorage.setItem('requestAuthority', item.requestAuthority);
        sessionStorage.setItem('date', item.caseDate);


        // console.log(item);

        try {
            item.groups.map(function (item) {
                if (item.status === 'reference') {
                    reference.push(item);
                } else {
                    samples.push(item);
                }
            });
        } catch (e) {
            console.log(e);
        }


        sessionStorage.setItem('reference', JSON.stringify(reference));
        sessionStorage.setItem('samples', JSON.stringify(samples));

        this.props.history.push('/dashboard');

    }

    searchCase() {

        if (moment(this.state.endDate).diff(moment(this.state.startDate)) < 0) {
            return NotificationManager.error(global.strings.date_between_validation);
            return;
        }

        const data = {
            "tckn": this.state.tckn,
            "caseId": this.state.caseId,
            "requestAuthority": this.state.requestAuthority,
            "specialityNo": this.state.specialityNo,
            "pageNumber": this.state.page
        };


        if (this.state.startDate !== null && this.state.startInputValue !== null && this.state.startInputValue !== '') {
            data.startDate = moment(this.state.startDate).toISOString().slice(0, -5) + "Z";
        }

        if (this.state.endDate !== null && this.state.endInputValue !== null && this.state.endInputValue !== '') {
            data.endDate = moment(this.state.endDate).toISOString().slice(0, -5) + "Z";
        }

        this.setState({loading: true});
        fetch(customConstants.apiUrl + "/cases/search", {
            method: 'POST',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {


                if (result.length === 0) {
                    this.setState({loading: false});
                    return NotificationManager.info(global.strings.noRecord);
                } else {

                    var lastResult = [];
                    result.cases.map(function (v, k) {
                        lastResult.push({
                            id: k,
                            caseId: v.caseId,
                            requestAuthority: v.requestAuthority,
                            uuid: v.uuid,
                            caseDate: v.caseDate,
                            specialityNo: v.specialityNo,
                            groups: v.groups,
                            tokens: v.tokens
                        });
                    });

                    this.setState({loading: false, searchResult: lastResult, numberOfCases: result.numberOfCases});
                }
                // this.continueToReport(result);
            })
            .catch(err => {
                // console.log(err);
                this.setState({loading: false});
                // return NotificationManager.error(err);
            });
    }

    getUsers() {

        if (this.state.users.length === 0) {
            fetch(customConstants.apiUrl + "/user", {
                method: 'GET',
                headers: {
                    Authorization: forensicLib.getAuthHeader(),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(response => response.json())
                .then(result => {


                    if (result.length === 0) {
                        this.setState({loading: false});
                        return NotificationManager.info(global.strings.noRecord);
                    } else {

                        let toInsertUsers = [];

                        result.map(function (v, k) {
                            toInsertUsers.push({key: v.username, label: v.username});
                        })

                        this.setState({loading: false, currentUsers: toInsertUsers});
                    }
                    // this.continueToReport(result);
                })
                .catch(err => {
                    // console.log(err);
                    this.setState({loading: false});
                    // return NotificationManager.error(err);
                });
        }

    }

    continueToReport(result) {
        sessionStorage.setItem('searchResult', JSON.stringify(result));
        this.props.history.push('/dashboard');
    }

    removeCase(params) {
        this.setState({loading: true});
        fetch(customConstants.apiUrl + "/cases/" + params.row.uuid, {
            method: 'DELETE',
            headers: {
                Authorization: forensicLib.getAuthHeader(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(result => {

                let newSearchResult = this.state.searchResult.filter(function (v, j) {
                    if (v.uuid !== params.row.uuid) {
                        return v;
                    }
                });

                this.setState({loading: false, searchResult: newSearchResult, delete: false}, () => {
                    return NotificationManager.success(global.strings.caseDeleted);
                });

            })
            .catch(err => {
                //console.log(err);
                this.setState({loading: false});
                // return NotificationManager.error(err);
            });
    }

    onOpenCase = (params) => {
        this.getCase(params.row);
    }

    continueToRemove = () => {
        this.removeCase(this.state.params);
    }

    setUser = (user) => {

        console.log(user);

        this.setState({sharedUsers: user});
    }

    searchNow() {
        this.setState({page: 0}, () => {
            _this.searchCase();
        });
    }

    dateFormatter = str => {
        return str;
    };

    render() {
        return (


            <div>
                <Dialog
                    open={this.state.delete}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{global.strings.want_to_delete_file}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {global.strings.want_to_delete_file_body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" className={'cancel'}>
                            {global.strings.cancel}
                        </Button>
                        <Button onClick={this.continueToRemove} color="primary" autoFocus>
                            {global.strings.continue}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.share}
                    onClose={this.handleCloseShare}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{global.strings.share_title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Alert severity="info">{global.strings.share_body}</Alert>
                            <Autocomplete
                                noOptionsText={global.strings.no_option_text}
                                multiple
                                filterSelectedOptions
                                id="checkboxes-tags-demo"
                                options={this.state.currentUsers}
                                disableCloseOnSelect
                                limitTags={5}
                                value={this.state.sharedUsers}
                                onChange={(event, value) => this.setUser(value)}
                                getOptionLabel={(option) => option.label}
                                renderOption={(option, {inputValue, selected}) => {

                                    const matches = match(option.label, inputValue);
                                    const parts = parse(option.label, matches);

                                    return (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{marginRight: 8}}
                                                checked={selected}
                                            />
                                            {parts.map((part, index) => (
                                                <span key={index}
                                                      style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                                            ))}
                                        </React.Fragment>);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            onDelete={this.handleDelete}
                                            variant="outlined"
                                            label={option.label}
                                            size="medium"
                                            avatar={<AccountCircle/>}
                                            {...getTagProps({index})}
                                        />
                                    ))
                                }
                                style={{width: '%100', marginTop: 40}}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={global.strings.search_expert}
                                               placeholder={global.strings.user}/>
                                )}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseShare} color="primary" className={'cancel'}>
                            {global.strings.cancel}
                        </Button>
                        <Button onClick={this.shareWithUsers} color="primary" autoFocus>
                            {global.strings.share}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Loading style={{zIndex: 99999}} loading={this.state.loading} background="rgba(255,255,255,.7)"
                         loaderColor="#0D386B"/>
                <div className="navbar navbar-expand navbar-light bg-white topbar  static-top shadow"
                     id={'topBarMenu'}>

                    <div onClick={() => logout(this)} lang="en" className="logout-form-btn">
                        {global.strings.logout}
                    </div>

                </div>
                <div id="wrapper">

                    <LeftMenu enableAdminMenu={true}/>

                    <div className={'main-container'}>

                        <div style={{backgroundColor: '#fff', 'margin': 0, 'width': '100%', display: 'inline-block'}}>

                                <span
                                    className={'dashboard-header'}>{global.strings.search}</span>


                        </div>

                        <div style={{padding: 16}}>

                            <Grid container spacing={3}>

                                <Grid item xs={12} lg={2} md={2}>

                                    <FormControl fullWidth
                                                 size="small"
                                                 variant="outlined">
                                        <InputLabel
                                            htmlFor="caseId"
                                            className={'search-label'}>{global.strings.expertId}</InputLabel>
                                        <OutlinedInput
                                            size="small"
                                            id="caseMargen"
                                            onChange={(updateSpecialityNo) => this.updateSpecialityNo(updateSpecialityNo)}
                                            value={this.state.specialityNo}
                                            labelWidth={180}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} lg={1} md={1}>

                                    <FormControl fullWidth
                                                 size="small"
                                                 variant="outlined">
                                        <InputLabel
                                            htmlFor="caseId"
                                            className={'search-label'}>{global.strings.caseNo}</InputLabel>
                                        <OutlinedInput
                                            size="small"
                                            id="caseId"
                                            onChange={(caseId) => this.updateCaseId(caseId)}
                                            value={this.state.caseId}
                                            labelWidth={180}
                                        />
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={2} md={2}>

                                    <FormControl fullWidth
                                                 size="small"
                                                 variant="outlined">
                                        <InputLabel
                                            htmlFor="caseId"
                                            className={'search-label'}>{global.strings.caseMargin}</InputLabel>
                                        <OutlinedInput
                                            size="small"
                                            id="caseMargen"
                                            onChange={(caseMargin) => this.updateCaseMargin(caseMargin)}
                                            value={this.state.caseMargin}
                                            labelWidth={180}
                                        />
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={4} md={4}>
                                    <MuiPickersUtilsProvider locale={localStorage.getItem('_lang')} libInstance={moment} utils={MomentUtils}>

                                        <div>
                                            <FormControl
                                                style={{
                                                    flexDirection: 'row',
                                                    width: 'calc(50% - 5px)',
                                                    display: 'inline-block',
                                                    marginRight: 5
                                                }}
                                                size="small"
                                                variant="outlined">
                                                <KeyboardDatePicker
                                                    inputVariant="outlined"
                                                    format="DD-MM-YYYY"
                                                    id="startDate"
                                                    size={"small"}
                                                    okLabel={React.createElement('span', null, global.strings.ok)}
                                                    cancelLabel={React.createElement('span', null, global.strings.cancel)}
                                                    label={<span
                                                        className={'search-label'}>{global.strings.start_date}</span>}
                                                    value={this.state.start_date}
                                                    inputValue={this.state.startInputValue}
                                                    onChange={this.updateStartDate}
                                                    rifmFormatter={this.dateFormatter}
                                                />

                                            </FormControl>
                                            <FormControl
                                                style={{
                                                    flexDirection: 'row',
                                                    width: 'calc(50% - 5px)',
                                                    display: 'inline-block',
                                                    marginRight: 5
                                                }}
                                                size="small"
                                                variant="outlined">


                                                <KeyboardDatePicker
                                                    inputVariant="outlined"
                                                    format="DD-MM-YYYY"
                                                    size={"small"}
                                                    id="endDate"
                                                    okLabel={React.createElement('span', null, global.strings.ok)}
                                                    cancelLabel={React.createElement('span', null, global.strings.cancel)}
                                                    label={<span
                                                        className={'search-label'}>{global.strings.end_date}</span>}
                                                    value={this.state.end_date}
                                                    inputValue={this.state.endInputValue}
                                                    onChange={this.updateEndDate}
                                                    rifmFormatter={this.dateFormatter}
                                                />
                                            </FormControl>
                                        </div>

                                    </MuiPickersUtilsProvider>

                                </Grid>

                                <Grid item xs={12} lg={3} md={3}>
                                    <div style={{textAlign: 'right'}}>
                                        <Button onClick={() => this.searchNow()} size={'medium'}
                                                variant="contained" style={{}} color="primary">
                                            {global.strings.search}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>

                        {(this.state.searchResult.length > 0) ?

                            <div style={{padding: 15}}>
                                <DataGrid className={'data-grid'}
                                          onPageChange={(params) => {

                                              if (params.page !== (this.state.page + 1)) {

                                                  _this.setState({page: params.page - 1}, () => {
                                                      _this.searchCase();
                                                  });

                                              }

                                          }}
                                          rowsPerPageOptions={[50]}
                                          rows={this.state.searchResult} autoHeight={true}
                                          columns={this.columns}
                                          pageSize={50} showToolbar={false}/>

                            </div>
                            : null}
                    </div>
                </div>
            </div>)
    }


}

export default withRouter(Selection);



