import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import customConstants from "../Globals";
import { setLanguage } from "../Helpers";
import { NotificationManager } from "react-notifications";
import Loading from 'react-fullscreen-loading';
import globals from "../Globals";

export class Login extends Component {

    constructor(props) {
        super(props);
        setLanguage();
        this.state = {
            email: '',
            password: '',
            show: false,
            loading: false,
            lang: 'tr'
        };
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    updateLang(lang) {
        setLanguage(lang)
        this.setState({lang: lang});
    }

    componentDidMount() {
        if (localStorage.getItem('_lang') === null) {
            this.setState({lang: 'tr'});
            global.strings.setLanguage('tr');
            localStorage.setItem('_lang','tr')
        } else {
            this.setState({lang: localStorage.getItem('_lang')});
            global.strings.setLanguage(localStorage.getItem('_lang'));
        }
    }

    async loginUser() {

        this.setState({loading: true});

        const data = new FormData();
        data.append('client_id', this.state.email);
        data.append('client_secret', this.state.password);
        data.append('grant_type', "client_credentials");

        fetch(customConstants.apiForensicUrlOpen + "/oauth/access_token", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: data
        })
            .then(response => response.json())
            .then(result => {

                // console.log(result);
                sessionStorage.clear();
                this.setState({loading: false});
                if (typeof result.access_token !== 'undefined') {
                    localStorage.setItem('_atoken', result.access_token);
                    localStorage.setItem('_rtoken', result.refresh_token);
                    localStorage.setItem('_token_type', result.token_type);
                    this.props.history.push('/selection');
                } else {
                    return NotificationManager.error(global.strings.login_error);
                }
            })
            .catch(err => {
                this.setState({loading: false});
                return NotificationManager.error(global.strings.login_error);
            });

    }

    updateEmail(event) {
        this.setState({email: event.target.value});
    }

    updatePassword(event) {
        this.setState({password: event.target.value});
    }

    updatePasswordType() {
        this.setState({show: !this.state.show})
    }

    render() {
        return (

            <div>
                <Loading loading={this.state.loading} background="rgba(255,255,255,.7)" loaderColor="#0D386B"/>
                <div className="limiter">

                    <div className="container-login100">
                        <div className="wrap-login100 position-relative">

                            <form className="login100-form validate-form">
					<span className="login100-form-title">
                        {global.strings.appTitle.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
					</span>
                                <span className="login100-form-desc">{global.strings.loginDesc}</span>


                                <div className="flex-row wrap-input100 validate-input">
                                    <label className="lf--label-form" htmlFor="username">
                                        <svg x="0px" y="0px" width="12px" height="13px">
                                            <path fill="#B1B7C4"
                                                  d="M8.9,7.2C9,6.9,9,6.7,9,6.5v-4C9,1.1,7.9,0,6.5,0h-1C4.1,0,3,1.1,3,2.5v4c0,0.2,0,0.4,0.1,0.7 C1.3,7.8,0,9.5,0,11.5V13h12v-1.5C12,9.5,10.7,7.8,8.9,7.2z M4,2.5C4,1.7,4.7,1,5.5,1h1C7.3,1,8,1.7,8,2.5v4c0,0.2,0,0.4-0.1,0.6 l0.1,0L7.9,7.3C7.6,7.8,7.1,8.2,6.5,8.2h-1c-0.6,0-1.1-0.4-1.4-0.9L4.1,7.1l0.1,0C4,6.9,4,6.7,4,6.5V2.5z M11,12H1v-0.5 c0-1.6,1-2.9,2.4-3.4c0.5,0.7,1.2,1.1,2.1,1.1h1c0.8,0,1.6-0.4,2.1-1.1C10,8.5,11,9.9,11,11.5V12z"></path>
                                        </svg>
                                    </label>
                                    <input id="username" className="lf--input input100"
                                           value={this.state.email}
                                           onChange={this.updateEmail}
                                           placeholder={'PBİK'}
                                           type="text"/>
                                    <span className="focus-input100"></span>
                                </div>
                                <div className="flex-row wrap-input100 validate-input">
                                    <label className="lf--label-form" htmlFor="username">
                                        <svg x="0px" y="0px" width="12px" height="13px">
                                            <path fill="#B1B7C4"
                                                  d="M6,2L6,2c0-1.1-1-2-2.1-2H2.1C1,0,0,0.9,0,2.1v0.8C0,4.1,1,5,2.1,5h1.7C5,5,6,4.1,6,2.9V3h5v1h1V3h1v2h1V3h1 V2H6z M5.1,2.9c0,0.7-0.6,1.2-1.3,1.2H2.1c-0.7,0-1.3-0.6-1.3-1.2V2.1c0-0.7,0.6-1.2,1.3-1.2h1.7c0.7,0,1.3,0.6,1.3,1.2V2.9z"></path>
                                        </svg>
                                    </label>
                                    <input id="password" className="lf--input input100"
                                           type={(this.state.show === true) ? 'text' : 'password'}
                                           value={this.state.password}
                                           onChange={this.updatePassword}
                                           placeholder={global.strings.password}
                                    />
                                    <img onClick={() => this.updatePasswordType()} className={'hidePassword'}
                                         src={(this.state.show === true) ? './assets/images/eye.png' : './assets/images/eye-not.png'}/>
                                    <span className="focus-input100"></span>
                                </div>


                                <div className="container-login100-form-btn">
                                    <div onClick={() => this.loginUser()} lang="en" className="login100-form-btn">
                                        <svg x="0px" y="0px" width="25px" height="23px"
                                             viewBox="0 0 31.49 31.49"
                                        >
                                            <path fill="#ffffff"
                                                  d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"></path>
                                        </svg>
                                    </div>
                                </div>

                                <div className="text-center p-t-12">
                                    {/*<span className="txt1">*/}
                                    {/*Forgot*/}
                                    {/*</span>*/}
                                    {/* <a className="txt2" href="#">
                                        Username / Password?
                                    </a>*/}
                                </div>

                                <div className="text-center p-t-136">
                                    {/* <a className="txt2" href="#">
                                        Create your Account
                                        <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"/>
                                    </a>*/}
                                </div>
                            </form>
                            <div className="login100-pic js-tilt" data-tilt>
                                <img src={'./assets/images/logo-' + customConstants.appName + '.png'} alt="IMG"/>
                            </div>
                            <div style={{position: 'absolute', right: 10, bottom: 10}}><select
                                onChange={(lang) => this.updateLang(lang)}
                                className={'form-control'}>
                                <option selected={this.state.lang === 'tr'} value={'tr'}>{global.strings.turkish}</option>
                                <option selected={this.state.lang === 'en'} value={'en'}>{global.strings.english}</option>
                            </select></div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }

}

export default withRouter(Login);



